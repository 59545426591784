import { useState } from 'react';

import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import './Contact.css';

export const Contact = props => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccessContactComplete, setIsSuccessContactComplete] = useState(false);
  const [isFailContactComplete, setIsFailContactComplete] = useState(false);
  const [isFetchContact, setIsFetchContact] = useState(false);

  const {
    fetchContact,
  } = props;

  const conditionedContactContent = () => {
    if (isFetchContact) {
      return (
        <div className="FetchIconArea">
          <CircularProgress />
        </div>
      );
    } else if (isSuccessContactComplete) {
      return (
        <div className="ContactSuccess">
          Thank you for your message.
          If you provide an email address, we will get back to you shortly.
        </div>
      );
    } else if (isFailContactComplete) {
      return (
        <div className="ContactFail">
          An error has occurred.
          Please contact us again.
        </div>
      );
    } else {
      return (
        <>
          <div className="ContactNotice">
            Please fill item marked with * symbol.
          </div>
          <div className="ContactItem">
            <div className="ContactLabel">
              <label htmlFor="username">1. Username</label>
            </div>
            <div className="ContactInputForm">
              <input
                className="UsernameInputForm CustomInputForm"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </div>
          <div className="ContactItem">
            <div className="ContactLabel">
              <label htmlFor="email">2. Email</label>
            </div>
            <div className="ContactInputForm">
              <input
                className="EmailInputForm CustomInputForm"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="ContactItem">
            <div className="ContactLabel">
              <label htmlFor="subject">3. Subject *</label>
            </div>
            <div className="ContactInputForm">
              <select
                name="pets" id="subject"
                className="SubjectInputForm CustomInputForm"
                onChange={(e) => setSubject(e.target.value)}
              >
                <option value="" hidden>Select</option>
                <option value="Inquiry">Inquiry</option>
                <option value="Feature Requests">Feature Requests</option>
                <option value="Bug Reports">Bug Reports</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>
          <div className="ContactItem">
            <div className="ContactLabel">
              <label htmlFor="message">4. Message *</label>
            </div>
            <div className="ContactInputForm">
              <textarea
                className="MessageInputForm CustomInputForm"
                id="message"
                rows="10"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
          <div className="ContactItem">
            <div className="ContactSubmitButtonArea">
              <button
                className="ContactSubmitButton CustomButton"
                onClick={() => {
                  setIsFetchContact(true);
                  fetchContact({
                    username: username,
                    email: email,
                    subject: subject,
                    message: message,
                    setIsSuccessContactComplete: setIsSuccessContactComplete,
                    setIsFailContactComplete: setIsFailContactComplete,
                    setIsFetchContact: setIsFetchContact,
                  });
                }}
                disabled={!message || !subject}
              >
                <span className="ContactSubmitButtonText">Submit</span>
              </button>
            </div>
          </div>
        </>
      );
    }
  }

  return (
    <div className="Contact">
      <div className='ContactColumn'>
        <div className="ContactTitle CustomHeader1">
          Contact us
        </div>
        <div className="BackToHomeArea">
          <Link to="/" className="BackToHomeLink">
            Back to Home
          </Link>
        </div>
        {conditionedContactContent()}
      </div>
    </div>
  );
}
