import React from 'react';
import Plot from 'react-plotly.js';
import './StandardHPLC.css';
import './PlotlyStyle.css';
import { PURPLE, SKY_BLUE, SCARLET, FONT_FAMILY } from './PlotlyStyle.js';
import { MIN_HPLC_HEIGHT } from './PrepareAnalysis';
import { Status } from './Status';

export const MIN_WIDTH = 1024;

class StandardHPLC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allPointData: props.allPointData,
      peakPointData: props.peakPointData,
      layout: {
        width: props.width,
        height: props.height,
        xaxis: {
          title: 'Retension Time(min)'
        },
        yaxis: {
          title: 'Intensity'
        },
        font: {
          family: FONT_FAMILY,
        },
      },
      colors: props.colors,
      status: null,
      statusMessage: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.peakPointData !== this.props.peakPointData) {
      this.setState({
        allPointData: this.props.allPointData,
        peakPointData: this.props.peakPointData,
        colors: this.props.peakPointData.marker.color,
      });
      this.props.changeColors(this.props.peakPointData.marker.color);
    }
  }

  async clickPeak(data) {
    const peakData = {
      data: data,
      allPointData: this.state.allPointData,
      peakPointData: this.state.peakPointData,
      peakAreaData: this.props.peakAreaData,
      xInversePeak: this.props.xInversePeak,
      colors: this.state.colors,
      isSample: false,
      isCalcCurve: true,
    };

    this.props.changeFetching(true);

    const returnProps = await this.props.clickHPLC(peakData);

    this.props.changeFetching(false);
    this.setState({
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });
    if (returnProps.status === 'error') return;
    this.setState({
      peakPointData: returnProps.peakPointData,
      colors: [...returnProps.colors],
      curvePointData: returnProps.curvePointData,
      guPointData: returnProps.guPointData,
    })
    this.props.changeColors(returnProps.colors);
    this.props.changeconvertFormula(returnProps.convertFormula);
    this.props.changePointData(
      this.state.allPointData, returnProps.peakPointData,
      returnProps.curvePointData, returnProps.guPointData
    );
    this.props.changePeakAreaData(returnProps.peakAreaData);
    this.props.changeInversePeak(returnProps.xInversePeak);
  }

  render() {
    const allPointData = this.props.allPointData;
    let peakPointData;
    if (this.props.isDrawGraph) {
      peakPointData = this.props.peakPointData;
    } else {
      peakPointData = this.state.peakPointData;
    }

    return (
      <div>
        {this.state.status !== null ?
          <Status
            status={this.state.status}
            statusMessage={this.state.statusMessage} /> : null}
        <Plot
          data={[
            allPointData,
            {
              type: "scatter",
              mode: "markers",
              x: peakPointData.x,
              y: peakPointData.y,
              name: peakPointData.name,
              marker: {
                color: this.state.colors,
                opacity: 0.5,
                size: 20,
                line: {
                  color: PURPLE,
                  width: 2
                }
              },
              text: Object.values(this.props.peakAreaData),
              hovertemplate:
                "<b>Peak Information</b><br><br>" +
                "Retention Time: %{x: peakPointData.xPeaks}<br>" +
                "peakArea: %{text}<br>" +
                "<extra></extra>"
            }]}
          layout={{
            ...this.state.layout,
            title: this.props.filename,
            width: Math.max(this.props.width, MIN_WIDTH),
            height: Math.max(this.props.height, MIN_HPLC_HEIGHT),
          }}
          config={{
            scrollZoom: true,
          }}
          className="peakGraph"
          onHover={(data) => {
            const props = {
              data: data, beforeColor: SKY_BLUE, afterColor: SCARLET,
              isHover: true,
            };
            const colors = this.props.changeHoverColor(props);
            if (colors) {
              this.setState({ colors: [...colors] });
            }
          }}
          onUnhover={(data) => {
            const props = {
              data: data, beforeColor: SCARLET, afterColor: SKY_BLUE,
              isHover: false,
            };
            const colors = this.props.changeHoverColor(props);
            if (colors) {
              this.setState({ colors: [...colors] });
              this.props.changeColors(colors);
            }
          }}
          onClick={(data) => {
            this.setState({ peakPointData: this.props.peakPointData })
            this.clickPeak(data);
          }}
        />
      </div>
    );
  }
}

export default StandardHPLC;
