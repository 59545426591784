import { Row, Button } from 'react-bootstrap';
import { Loader } from './Loader';
import { Status } from './Status';
import {
  AttentionEnter,
  AttentionNumeric,
  AttentionMinusCharge,
} from './Attention';
import { RenderInputForm } from './RenderInputForm';
import './DBSetting.css';
import './PeakInformation.css';

export const DBSetup = props => {
  const {
    reader,
    isEnter,
    pressEnterHandler,
    changeInputHandler,
    blurFocusHandler,
    changeDBModalShow,
    MSDiff,
    GUDiff,
    status,
    statusMessage,
    isSampleSelected,
    isFetching,
    isSelectedPeakMSFetched,
    isSameBefore,
    isNumeric,
    isValidMinusCharge,
    componentStyle,
  } = props;

  return (
    <>
      {status !== null ? <Status status={status} statusMessage={statusMessage} /> : null}
      {isSameBefore ? null : <AttentionEnter />}
      {isNumeric ? null : <AttentionNumeric />}
      {isValidMinusCharge ? null : <AttentionMinusCharge />}
      <div className="DBTabContents Sheet">
        <Row>
          <RenderInputForm
            reader={reader}
            isEnter={isEnter}
            pressEnterHandler={pressEnterHandler}
            changeInputHandler={changeInputHandler}
            blurFocusHandler={blurFocusHandler}
            keyName="MS Diff"
            stateValue={MSDiff}
            keyClassName="DBTresholdKey"
            valueClassName="CustomInputForm"
            id="MSDiff"
            keyColLength="8"
            valueColLength="4"
          />
          <RenderInputForm
            reader={reader}
            isEnter={isEnter}
            pressEnterHandler={pressEnterHandler}
            changeInputHandler={changeInputHandler}
            blurFocusHandler={blurFocusHandler}
            keyName="GU Diff(%)"
            stateValue={GUDiff}
            keyClassName="DBTresholdKey"
            valueClassName="CustomInputForm"
            id="GUDiff"
            keyColLength="8"
            valueColLength="4"
          />
        </Row>
        <Row xs={12} className="loader">
          {isFetching ? <Loader className="loaderIcon" /> : <></>}
        </Row>
        <Row >
          <Row className="displayButtonRow">
            <button
              className="displayButton CustomButton"
              onClick={() => changeDBModalShow(true)}
              disabled={!isSampleSelected || isFetching || !isSelectedPeakMSFetched}
            >
              Display DB Table
            </button>
          </Row>
        </Row>
      </div>
    </>
  );
}

