import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import './PeakInformation.css';
import { MouseOverPopover } from './PopOver';

const REGEX = /[0-9.]/g;
const NotSelectedComment = '-';

const Style = {
  backgroundColor: 'rgb(196, 213, 235)',
  margin: '15px',
  padding: '10px'
}

class AttentionEnter extends React.Component {
  render() {
    return (
      <div className="attentionPressEnterMessage">※Please Enter to confirm your changes</div>
    );
  }
}

class AttentionNumeric extends React.Component {
  render() {
    return (
      <div className="attentionInputDigitMessage">※Contains non-numeric characters</div>
    );
  }
}

const QuantPeakData = props => {
  const {
    isSelected, quantRetentionTime, gu, quantPeakArea, maxPeakArea,
    changePeakAreaRatio, changeBasePeakArea, changeBaseAmount, changeBaseUnit, changeUnit,
    basePeakArea, peakAreaRatio, baseAmount, baseUnit, unit
  } = props;

  const [isDisabled, setDisabled] = useState(true);
  const [isPressEnterAttention, setPressEnterAttention] = useState(false);
  const [isInputInvalid, setInputInvalid] = useState(false);
  const [isInput, setIsInput] = useState(false);
  const [targetPeakArea, setTargetPeakArea] = useState(quantPeakArea);

  const setDisabledHandler = event => {
    const unit = event.target.value;
    changeUnit(unit);
    if (unit === '%') {
      setDisabled(true);
      changePeakAreaRatio(parseFloat((targetPeakArea / basePeakArea) * 100).toFixed(2))
    } else {
      setDisabled(false);
      changeBaseUnit(unit);
    }
  }

  const changeValue = event => {
    changePeakAreaRatio(event.target.value);
    if (peakAreaRatio !== event.target.value) {
      setPressEnterAttention(true);
    }
    if (event.target.value !== '' && event.target.value.match(REGEX) === null) {
      setInputInvalid(true);
    } else if (event.target.value !== '' && event.target.value.match(REGEX) !== null) {
      setInputInvalid(event.target.value.split('').length !== event.target.value.match(REGEX).length);
    }
  }

  const pressEnterHandler = (event) => {
    const eventTargetValue = event.target.value;
    if (event.key === 'Enter' && !isInputInvalid) {
      setIsInput(true);
      changeBaseAmount(eventTargetValue);
      changeBasePeakArea(quantPeakArea);
      setPressEnterAttention(true);
      event.target.blur();
    }
  }

  const blurFocusHandler = () => {
    setPressEnterAttention(false);
    setInputInvalid(false);
  }

  useEffect(() => {
    if (document.activeElement.tagName !== 'INPUT') {
      if (maxPeakArea === 0 || quantPeakArea === '-') {
        changePeakAreaRatio('-');
      } else {
        let ratio;
        if (unit === 'nmol') {
          ratio = (quantPeakArea / basePeakArea) / 1000;
        } else if (unit === 'fmol') {
          ratio = (quantPeakArea / basePeakArea) * 1000;
        } else if (unit === 'pmol') {
          ratio = quantPeakArea / basePeakArea;
        } else {
          ratio = (quantPeakArea / basePeakArea) * 100;
        }
        if (!isInput) {
          changePeakAreaRatio(parseFloat(ratio).toFixed(2));
        }
      }
    }
    if (quantPeakArea !== targetPeakArea) {
      setTargetPeakArea(quantPeakArea);
      setIsInput(false);
    }
    if (basePeakArea === 0) {
      changeBasePeakArea(maxPeakArea);
    }
    if (unit === '%') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [quantPeakArea, maxPeakArea, basePeakArea, isDisabled]);

  return (
    <React.Fragment className="peakInformation">
    <Paper elevation={3} style={Style}>
      {
        isPressEnterAttention ?
          <AttentionEnter /> : <div className="peakInformation-title">Peak Information</div>
      }
      {
        isInputInvalid ?
          <AttentionNumeric /> : null
      }
      <div className="peakDataRow">
        <Row className="peakKey">
          <Col xs={7}>Retention Time</Col>
          <Col xs={5}>
            <Form.Control
              type='input'
              value={isSelected ? parseFloat(quantRetentionTime).toFixed(2) : NotSelectedComment}
              disabled />
          </Col>
        </Row>
        <Row className="peakKey">
          <Col xs={7}>GU</Col>
          <Col xs={5}>
            <Form.Control
              type='input'
              value={isSelected ? parseFloat(gu).toFixed(2) : NotSelectedComment}
              disabled />
          </Col>
        </Row>
        <Row className="peakKey">
          <Col xs={7}>Peak Area</Col>
          <Col xs={5}>
            <Form.Control
              type='input'
              value={isSelected ? parseInt(quantPeakArea) : NotSelectedComment}
              disabled />
          </Col>
        </Row>
        <Divider className="divider">Quantification</Divider>
        <div className='peakAreaRatio'>
          <Row >
            <Col xs={4}>
              Base Peak Area
            </Col>
            <Col xs={8}>
              ratio or amount <MouseOverPopover name={'ratio or amount'} />
            </Col>
          </Row>
          <Row className="peakKey PeakAreaRow">
            <Col xs={4}>
              <Form.Control
                type='input'
                value={basePeakArea !== 0 ? basePeakArea : '-'}
                disabled />
            </Col>
            <Col xs={4}>
              <Form.Control
                type='input'
                value={peakAreaRatio}
                onChange={(event) => changeValue(event)}
                onKeyPress={(event) => pressEnterHandler(event)}
                onBlur={(event) => blurFocusHandler(event)}
                disabled={isDisabled} />
            </Col>
            <Col xs={4}>
              <select onChange={(event) => setDisabledHandler(event)} disabled={!isSelected}>
                <option selected={unit === '%'}>%</option>
                <option selected={unit === 'pmol'}>pmol</option>
                <option selected={unit === 'nmol'}>nmol</option>
                <option selected={unit === 'fmol'}>fmol</option>
              </select>
            </Col>
          </Row>
          <div>Relation area and amount : {basePeakArea !== 0 ? <p>{basePeakArea} ・・・ {parseFloat(baseAmount).toFixed(2)}{baseUnit}</p> : '-'} </div>
        </div>
      </div>
    </Paper>
    </React.Fragment>
  );
}

export default QuantPeakData;
