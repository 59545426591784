
import { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Loader } from './Loader';

export const MSMSStatus = props => {
  const { sfnStatus, getMSMSResult } = props;

  useEffect(() => {
    const intervalID = setInterval(() => getMSMSResult(), 1000);
    return () => clearInterval(intervalID);
  }, [])

  const showMSMSStatus = () => {
    if (sfnStatus === 'SUCCEEDED') {
      return (
        <Alert severity="success">
          <AlertTitle>SUCCEEDED</AlertTitle>
          MSMS Internal analysis is <strong>succeded</strong>.
        </Alert>
      );
    } else if (sfnStatus === 'RUNNING') {
      return (
        <Alert severity="info">
          <AlertTitle>RUNNING</AlertTitle>
          MSMS Internal analysis is <strong>running</strong>.
          {sfnStatus === 'RUNNING' ? <Loader /> : <></>}
        </Alert>
      );
    } else if (sfnStatus === 'STOP') {
      return (
        <Alert severity="warning">
          <AlertTitle>STOP</AlertTitle>
          MSMS Internal analysis is <strong>stopped</strong>.
        </Alert>
      );
    } else {
      // do nothing
    }
  }

  return <>{showMSMSStatus()}</>;
}
