export const calcValence = diffValue => {
  let valenceList = [];
  if (1.0 <= diffValue) {
    valenceList = [1];
  } else if (0.9 <= diffValue && diffValue < 1.0) {
    valenceList = [1, 2];
  } else if (0.6 <= diffValue && diffValue < 0.9) {
    valenceList = [2];
  } else if (0.5 <= diffValue && diffValue < 0.6) {
    valenceList = [2, 3];
  } else if (0.4 <= diffValue && diffValue < 0.5) {
    valenceList = [3];
  } else {
    valenceList = [3, 4, 5, 6];
  }
  return valenceList;
}
