// リージョン
export const REGION = 'ap-northeast-1';
// API Gateway ID
export const API_GATEWAY_ID = 'b3vwnyp6xl';
// API Gateway STAGE
export const STAGE = 'prd';
// CognitoユーザープールID
export const USER_POOL_ID = 'ap-northeast-1_J74ochAmR';
// Cognito アプリケーションクライアントID
export const APP_CLIENT_ID = '5518ibtkceajoa6vuhbolki462';
// CognitoアイデンティティプールID
export const IDENTITY_POOL_ID = 'ap-northeast-1:18d65e7a-44bf-471a-81ff-3c4a286e9682';
// 実験プロトコルPDFを格納したS3バケット
export const BUCKET_NAME = 'glycomics-prd-resources-bucket';
// 実験プロトコル（英語）
export const ENGLISH_PROTOCOL = 'protocol_en.pdf';
// 実験プロトコル（日本語）
export const JAPANESE_PROTOCOL = 'protocol_ja.pdf';
// 利用規約
export const TOS = 'Terms_of_Service.html';
// ライセンス
export const LICENSE = 'LICENSE.html';
