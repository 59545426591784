import { MSMSSpecified } from './MSMSSpecified';
import { MSMSUnSpecified } from './MSMSUnSpecified';

export const MSMSModalWrapper = props => {
  const {
    isSpecified,
    changeIsSpecified,
    show,
    onHide,
    width,
    height,
    allPointData,
    filename,
    selectedDBRows,
    top10MZ,
    top10Composition,
    top10CompositionMass,
    top10Diff,
    valence,
    selectPulldownDBRow,
    changeSelectPulldownDBRow,
    allDBRows,
    changeSelectedDBRow,
    selectionModel,
    changeUnSpecifiedSelectionModel,
    unSpecifiedSelectionModel,
  } = props;

  return (
    isSpecified ?
      <MSMSSpecified
        show={show}
        onHide={onHide}
        width={width}
        height={height}
        allPointData={allPointData}
        filename={filename}
        selectedDBRows={selectedDBRows}
        top10MZ={top10MZ}
        top10Composition={top10Composition}
        top10CompositionMass={top10CompositionMass}
        top10Diff={top10Diff}
        valence={valence}
        selectPulldownDBRow={selectPulldownDBRow}
        changeSelectPulldownDBRow={changeSelectPulldownDBRow}
        changeIsSpecified={changeIsSpecified}
        unSpecifiedSelectionModel={unSpecifiedSelectionModel}
      />
      :
      <MSMSUnSpecified
        show={show}
        onHide={onHide}
        width={width}
        height={height}
        allPointData={allPointData}
        filename={filename}
        selectedDBRows={selectedDBRows}
        top10MZ={top10MZ}
        top10Composition={top10Composition}
        top10CompositionMass={top10CompositionMass}
        top10Diff={top10Diff}
        valence={valence}
        selectPulldownDBRow={selectPulldownDBRow}
        changeSelectPulldownDBRow={changeSelectPulldownDBRow}
        allDBRows={allDBRows}
        changeIsSpecified={changeIsSpecified}
        changeSelectedDBRow={changeSelectedDBRow}
        unSpecifiedSelectionModel={unSpecifiedSelectionModel}
        changeUnSpecifiedSelectionModel={changeUnSpecifiedSelectionModel}
      />
  );
}
