import { MouseOverPopover } from './PopOver';
import { Row, Button, Col, Form } from 'react-bootstrap';

export const RenderInputForm = props => {
  const {
    reader,
    isEnter,
    pressEnterHandler,
    changeInputHandler,
    blurFocusHandler,
    keyName, stateValue, keyClassName, valueClassName,
    id, keyColLength, valueColLength,
    isMSMS,
  } = props;

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (isMSMS) {
        pressEnterHandler(event, stateValue, id, reader, true);
      } else {
        pressEnterHandler(event, stateValue, id, reader);
      }
    }
  };
  const onChange = (event) => changeInputHandler(event, stateValue, id);
  const onBlur = (event) => blurFocusHandler(event, stateValue, isEnter);

  return (
    <>
      <Col xs={keyColLength} className={keyClassName}>
        <span className="CustomHeader6">{keyName}</span>
        <MouseOverPopover name={keyName} />
      </Col>
      <Col xs={valueColLength}>
        <Form.Control type='input' className={valueClassName} defaultValue={stateValue}
          onChange={onChange} onKeyPress={onKeyPress} onBlur={onBlur} />
      </Col>
    </>
  );
}
