import * as React from 'react';
import Popover from '@mui/material/Popover';
import { AiFillQuestionCircle } from "react-icons/ai";
import './PopOver.css';

export const MouseOverPopover = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <label>
      <p
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AiFillQuestionCircle className="helpIcon" />
      </p>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <p className="PopoverText">{helpComment[props.name]}</p>
      </Popover>
    </label>
  );
}

const helpComment = {
  'Slope': 'Difference in slope between the front two and back two of the three points.',
  'NeighborhoodIntensity': 'Average value of the Intensity of neighboring points.',
  'PeakArea': 'Peaks are grouped and integrated from inverted peak to inverted peak for each group.',
  'NeighborhoodCount': 'Number of nearby points before and after the peak.',
  'Start Retension Time': 'Start position of Retension time.',
  'End Retension Time': 'End position of Retension time.',
  'Minus Charge': 'Negative ions contained in the glycan structure.',
  'IsotopePeak Diff': 'Threshold for how far before or after the maximum peak the isotope peak is detected.',
  'MS Diff': 'Thresholds for MS value in the database and detected MS value.',
  'GU Diff(%)': 'Thresholds for GU value in the database and detected GU value.',
  'ToleranceDiff': 'Permissible tolerance difference in MSMS analysis.',
  'ratio or amount': 'If the unit is "mol", enter the amount of substance for the selected peak.',
  'MSMSPullDownList': 'This menu switches the data selected in the DB table. Format is "DB Name / GU / Composition / CompositionMS".',
  'MSMSModalTableHeaderComposition': (
    <>
      <span className='helpCommentCompositionMS' style={{color: 'blue'}}>Blue</span>: The number contained in the composition matches.<br />
      <span className='helpCommentCompositionMS' style={{color: 'black'}}>Black</span>: The number contained in the composition does not match but appears in fragments.<br />
      <span className='helpCommentCompositionMS' style={{color: 'red'}}>Red</span>: The Component does not appear in fragments.
    </>
  ),
  'MSMSUnSpecified': 'Click on a row to view fragment information.',
  'ionType': 'Select ion type from NH4+, Na+, K+ in case not found H+ type. Default type is NH4+.',
};
