import React from 'react';
import { Loader } from './Loader';
import Header from './Header.js'
import { Container, Row, Col, Form } from 'react-bootstrap';
import './PrepareAnalysis.css';
import SettingThreshold from './SettingThreshold.js'
import StandardHPLC from './StandardHPLC.js'
import GU from './GU.js'
import './PrepareAnalysis.css';
import './StandardHPLC.css';
import { Status } from './Status';
import { CsvShortNotice } from './HomeNotices';

export const PADDING = 40
export const SETTING_AREA = 150
export const INPUT_AREA = 240

export const MIN_HPLC_HEIGHT = 840 - SETTING_AREA
export const MIN_WIDTH = 1000;
export const HPLC_WIDTH_RATE = 0.75;
export const GU_WIDTH_RATE = 0.25;

class PrepareAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allPointData: props.allPointData,
      peakPointData: props.peakPointData,
      curvePointData: props.curvePointData,
      guPointData: props.guPointData,
      reader: props.reader,
      isFetched: false,
      isFetching: false,
      isDrawGraph: false,
      threshold: props.threshold,
      height: window.innerHeight - INPUT_AREA,
      width: Math.max(window.innerWidth, MIN_WIDTH),
      status: null,
      statusMessage: null,
      isDragOver: false,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        height: window.innerHeight - INPUT_AREA,
        width: Math.max(window.innerWidth, MIN_WIDTH),
      });
    });
  }

  dropHandler(event) {
    this.setState({ isDragOver: false });
    this.props.changeFilename(event.dataTransfer.files[0].name);
    event.preventDefault();
    const reader = new FileReader();
    reader.readAsText(event.dataTransfer.files[0]);
    reader.onload = () => {
      this.props.changeReader(reader);
      this.runFetch(reader);
    }
  }

  dragOverHandler(event) {
    event.preventDefault();
    this.setState({ isDragOver: true });
  }

  dragLeaveHandler(event) {
    event.preventDefault();
    this.setState({ isDragOver: false })
  }

  uploadFile(event) {
    if (event.target.files === null) {
      return
    }
    this.props.changeFilename(event.target.files[0].name);
    const self = this;
    const file = event.target.files;
    const reader = new FileReader();
    reader.readAsText(file[0]);
    this.setState({ reader: reader });
    reader.onload = function () {
      self.props.changeReader(reader);
      self.runFetch(reader);
    }
  }

  async runFetch(reader) {
    const raw = {
      data: reader.result,
      'slopeOrder': this.state.threshold.slope,
      'areaOrder': this.state.threshold.peakArea,
      'averageOrder': this.state.threshold.neighborhoodIntensity,
      'sampleNum': this.state.threshold.neighborhoodCount,
      isCalcCurve: true,
    }

    this.changeFetching(true);

    const returnProps = await this.props.fetchHPLC({ raw: raw });
    this.setState({
      allPointData: returnProps.allPointData,
      peakPointData: returnProps.peakPointData,
      curvePointData: returnProps.curvePointData,
      guPointData: returnProps.guPointData,
      isFetched: returnProps.isFetched,
      isFetching: false,
      isDrawGraph: true,
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });
    this.props.changeconvertFormula(returnProps.convertFormula);
    this.props.changePointData(
      returnProps.allPointData, returnProps.peakPointData,
      returnProps.curvePointData, returnProps.guPointData
    );
    this.props.changePeakAreaData(returnProps.peakAreaData);
    this.props.changeInversePeak(returnProps.xInversePeak);
  }

  changeFetched(isFetched) {
    this.setState({
      isFetched: isFetched
    })
  }

  changeFetching(isFetched) {
    this.setState({
      isFetching: isFetched
    })
  }

  changeHPLC(peakPointData) {
    this.setState({
      peakPointData: peakPointData,
    });
  }

  render() {
    let loader = null
    if (this.state.isFetching === true) {
      loader = <Loader />;
    }

    return (
      <Container className="Analysis"
        onDragOver={(event) => this.dragOverHandler(event)}
        onDragLeave={(event) => this.dragLeaveHandler(event)}
        onDrop={(event) => this.dropHandler(event)}
      >
        {
          this.state.isDragOver ?
            <div className='dragOverDisplay'>
              <p style={{ width: '50%', margin: 'auto', fontSize: '70px' }}>
                Drag and Drop
              </p>
              <p style={{ width: '50%', margin: 'auto' }}>
                ~ Standard Data ~
              </p>
            </div> :
            <React.Fragment>
              <Header
                title='Analysis ~Prepare Analysis for Converting GU~'
                setManualBackButton={this.props.setManualBackButton}
              />
              <Form.Group>
                <Row className="inputForm">
                  <Col xs={12}>
                    <Form.Label className="inputComment">
                      <span className="CustomHeader5">Input Standard Data</span>
                    </Form.Label>
                    <label className="fileLabel">
                      <input type="file" className="file" onChange={(event) => this.uploadFile(event)}></input>
                    </label>
                    {loader}
                    <CsvShortNotice />
                  </Col>
                </Row>
              </Form.Group>
              {this.state.status !== null ?
                <Status
                  status={this.state.status}
                  statusMessage={this.state.statusMessage} /> : null}
              <Row>
                <Col xs={9}>
                  <SettingThreshold
                    threshold={this.state.threshold}
                    reader={this.props.reader}
                    isDrawGraph={this.state.isDrawGraph}
                    allPointData={this.props.allPointData}
                    isFetching={this.state.isFetching}
                    changeGU={this.props.changeGU}
                    changeHPLC={this.changeHPLC.bind(this)}
                    changeFetching={this.changeFetching.bind(this)}
                    changeThreshold={this.props.changeThreshold}
                    changePointData={this.props.changePointData}
                    changeconvertFormula={this.props.changeconvertFormula}
                    changePeakAreaData={this.props.changePeakAreaData}
                    changeInversePeak={this.props.changeInversePeak}
                    fetchHPLC={this.props.fetchHPLC}
                    isSample={this.props.isSample}
                  />
                  <Row>
                    <Col xs={12}>
                      <StandardHPLC
                        peakPointData={this.state.peakPointData}
                        allPointData={this.state.allPointData}
                        isFetched={this.state.isFetched}
                        changeHPLC={this.changeHPLC.bind(this)}
                        changeFetched={this.changeFetched.bind(this)}
                        changeFetching={this.changeFetching.bind(this)}
                        changePeakAreaData={this.props.changePeakAreaData}
                        changeInversePeak={this.props.changeInversePeak}
                        isDrawGraph={this.state.isDrawGraph}
                        clickHPLC={this.props.clickHPLC}
                        width={this.state.width * HPLC_WIDTH_RATE - PADDING}
                        height={this.state.height - SETTING_AREA}
                        changeconvertFormula={this.props.changeconvertFormula}
                        changePointData={this.props.changePointData}
                        changeHoverColor={this.props.changeHoverColor}
                        changeColors={this.props.changeColors}
                        colors={this.props.colors}
                        peakAreaData={this.props.peakAreaData}
                        xInversePeak={this.props.xInversePeak}
                        filename={this.props.filename}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={3}>
                  <GU
                    curvePointData={this.props.curvePointData}
                    guPointData={this.props.guPointData}
                    isDrawGraph={this.state.isDrawGraph}
                    width={this.state.width * GU_WIDTH_RATE - PADDING}
                    height={this.state.height}
                  />
                </Col>
              </Row>
            </React.Fragment>}
      </Container>
    )
  }
}

export default PrepareAnalysis;
