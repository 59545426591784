import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import QuantAnalysis from './QuantAnalysis';
import PrepareAnalysis from './PrepareAnalysis.js';
import {
  fetchHPLCAction,
  fetchMSAction,
  recalcHPLCAction,
  fetchDBAction,
  fetchContact,
} from './LambdaActions.js';
import { getDBData } from './DBFunctions';
import SampleAnalysis from './SampleAnalysis.js';
import { changeHoverColor } from './HPLCFunctions';
import { calcValence } from './CalcValence.js';
import Home from './Home';
import { DescribeExecution, StartExecution } from './AsyncRequests';
import './App.css';
import Version from './Version.js';
import Manual from './Manual.js';
import { Protocol } from './Protocol';
import { License } from './License';
import { Contact } from './Contact';
import DrawGlycan from './DrawGlycan.js';
import { Footer } from './Footer';

import { Authenticator } from '@aws-amplify/ui-react';
import { Login } from './Login';
import { RequireAuth } from './RequireAuth';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // マニュアル
      enProtocolUrl: '',
      jaProtocolUrl: '',
      isManualBackButton: false,
      // 利用規約
      tosUrl: '',
      // ライセンス
      licenseUrl: '',
      // 共通パラメータ
      convertFormula: [],
      is_moved: false,
      is_fetched: false,
      // スタンダード HPLC パラメータ
      allPointData: {},
      peakPointData: {},
      peakAreaData: {},
      xInversePeak: [],
      colors: [],
      curvePointData: {},
      guPointData: {},
      reader: null,
      threshold: {
        slope: 2000,
        neighborhoodIntensity: 2000,
        peakArea: 1000000,
        neighborhoodCount: 150
      },
      filename: null,
      // サンプル HPLC パラメータ
      sampleAllPointData: {},
      samplePeakPointData: {},
      samplePeakAreaData: {},
      samplexInversePeak: [],
      sampleColors: [],
      sampleLabels: [],
      sampleRetentionTime: '-',
      samplePeakArea: '-',
      sampleGU: '-',
      sampleUnit: '%',
      isSampleSelected: false,
      isHover: false,
      imageYaxis: 0,
      imageXaxis: 0,
      structureTarget: 'Not Selected',
      sampleFilename: null,
      // 定量化 HPLC パラメータ
      quantAllPointData: {},
      quantPeakPointData: {},
      quantPeakAreaData: {},
      quantxInversePeak: [],
      quantColors: [],
      quantRetentionTime: '-',
      quantPeakArea: '-',
      quantGU: '-',
      quantBasePeakArea: 0,
      peakAreaRatio: '-',
      baseAmount: 1.00,
      baseUnit: 'pmol',
      quantUnit: '%',
      isQuantSelected: false,
      quantMaxPeakArea: 0,
      quantFilename: null,
    };
  }

  changeFilename(filename) {
    this.setState({ filename: filename });
  }

  changeSampleFilename(filename) {
    this.setState({ sampleFilename: filename });
  }

  changeQuantFilename(filename) {
    this.setState({ quantFilename: filename });
  }


  checkFetch(props) {
    const { is_fetched } = props;
    this.setState({ is_fetched });
    return props;
  }

  changeconvertFormula(convertFormula) {
    this.setState({
      convertFormula: convertFormula
    });
  }

  changeSamplePeakInformation(sampleRetentionTime, samplePeakArea, gu) {
    this.setState({
      sampleRetentionTime: sampleRetentionTime,
      samplePeakArea: samplePeakArea,
      sampleGU: gu,
    });
  }

  changeQuantPeakInformation(quantRetentionTime, quantPeakArea, gu) {
    this.setState({
      quantRetentionTime: quantRetentionTime,
      quantPeakArea: quantPeakArea,
      quantGU: gu,
    });
  }

  changePointData(allPointData, peakPointData, curvePointData, guPointData) {
    this.setState({
      allPointData: allPointData,
      peakPointData: peakPointData,
      curvePointData: curvePointData,
      guPointData: guPointData,
    });
  }

  changeThreshold(threshold) {
    this.setState({
      threshold: threshold
    })
  }

  changeGU(curvePointData, guPointData) {
    this.setState({
      curvePointData: curvePointData,
      guPointData: guPointData
    })
  }

  changeReader(reader) {
    this.setState({ reader: reader });
  }

  changeColors(colors) {
    this.setState({
      colors: colors
    })
  }

  changeSampleColors(colors) {
    this.setState({
      sampleColors: colors
    })
  }

  changeSampleLabels(sampleLabels) {
    this.setState({
      sampleLabels: sampleLabels
    })
  }

  changeQuantColors(colors) {
    this.setState({
      quantColors: colors
    })
  }

  changePeakAreaData(peakAreaData) {
    this.setState({ peakAreaData: peakAreaData });
  }

  changeSamplePeakAreaData(peakAreaData) {
    this.setState({ samplePeakAreaData: peakAreaData });
  }

  changeQuantPeakAreaData(peakAreaData) {
    this.setState({ quantPeakAreaData: peakAreaData });
  }

  changeInversePeak(xInversePeak) {
    this.setState({ xInversePeak: xInversePeak });
  }

  changeSampleInversePeak(xInversePeak) {
    this.setState({ samplexInversePeak: xInversePeak });
  }

  changeQuantInversePeak(xInversePeak) {
    this.setState({ quantxInversePeak: xInversePeak });
  }

  changeHover(boolean) {
    this.setState({ isHover: boolean });
  }

  changeImageAxis(clientWidhth, imageXaxis, imageYaxis) {
    this.setState({
      clientWidhth: clientWidhth, imageXaxis: imageXaxis, imageYaxis: imageYaxis
    });
  }

  changeStructureTarget(structureTarget) {
    this.setState({ structureTarget: structureTarget });
  }

  changeSamplePointData(sampleAllPointData, samplePeakPointData) {
    this.setState({
      sampleAllPointData: sampleAllPointData,
      samplePeakPointData: samplePeakPointData,
    })
  }

  changeQuantPointData(quantAllPointData, quantPeakPointData) {
    this.setState({
      quantAllPointData: quantAllPointData,
      quantPeakPointData: quantPeakPointData,
    })
  }

  changeBasePeakArea(basePeakArea) {
    this.setState({
      quantBasePeakArea: basePeakArea
    });
  }

  changePeakAreaRatio(peakAreaRatio) {
    this.setState({
      peakAreaRatio: peakAreaRatio
    });
  }

  changeBaseAmount(baseAmount) {
    this.setState({
      baseAmount: baseAmount
    });
  }

  changeBaseUnit(baseUnit) {
    this.setState({
      baseUnit: baseUnit
    });
  }

  changeSampleUnit(unit) {
    this.setState({
      sampleUnit: unit
    });
  }

  changeQuantUnit(unit) {
    this.setState({
      quantUnit: unit
    });
  }

  changeIsSampleSelected(isSelected) {
    this.setState({
      isSampleSelected: isSelected
    });
  }

  changeIsQuantSelected(isSelected) {
    this.setState({
      isQuantSelected: isSelected
    });
  }

  changeMaxPeakArea(maxPeakArea) {
    this.setState({
      quantMaxPeakArea: maxPeakArea
    });
  }

  setEnProtocolUrl(url) {
    this.setState({
      enProtocolUrl: url
    });
  }

  setJaProtocolUrl(url) {
    this.setState({
      jaProtocolUrl: url
    });
  }

  setTosUrl(url) {
    this.setState({
      tosUrl: url
    });
  }

  setLicenseUrl(url) {
    this.setState({
      licenseUrl: url
    });
  }

  setManualBackButton(boolean) {
    this.setState({
      isManualBackButton: boolean
    });
  }

  render() {
    return (
      <div>
        <Authenticator.Provider>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    setEnProtocolUrl={this.setEnProtocolUrl.bind(this)}
                    setJaProtocolUrl={this.setJaProtocolUrl.bind(this)}
                    setTosUrl={this.setTosUrl.bind(this)}
                    setLicenseUrl={this.setLicenseUrl.bind(this)}
                    isManualBackButton={this.state.isManualBackButton}
                    setManualBackButton={this.setManualBackButton.bind(this)}
                  />
                }
              />
              <Route
                path="/login"
                element={
                  <Login
                    tosUrl={this.state.tosUrl}
                  />
                }
              />
              <Route
                path="/Analysis/Prepare"
                element={
                  <RequireAuth>
                    <PrepareAnalysis
                      fetchHPLC={(props) => this.checkFetch(fetchHPLCAction(props))}
                      clickHPLC={(props) => this.checkFetch(recalcHPLCAction(props))}
                      changeHoverColor={(props) => changeHoverColor(props)}
                      changeconvertFormula={this.changeconvertFormula.bind(this)}
                      changePointData={this.changePointData.bind(this)}
                      changeThreshold={this.changeThreshold.bind(this)}
                      changeReader={this.changeReader.bind(this)}
                      changeGU={this.changeGU.bind(this)}
                      changeColors={this.changeColors.bind(this)}
                      changePeakAreaData={this.changePeakAreaData.bind(this)}
                      changeInversePeak={this.changeInversePeak.bind(this)}
                      changeFilename={this.changeFilename.bind(this)}
                      filename={this.state.filename}
                      colors={this.state.colors}
                      allPointData={this.state.allPointData}
                      peakPointData={this.state.peakPointData}
                      curvePointData={this.state.curvePointData}
                      guPointData={this.state.guPointData}
                      threshold={this.state.threshold}
                      reader={this.state.reader}
                      isSample={false}
                      peakAreaData={this.state.peakAreaData}
                      xInversePeak={this.state.xInversePeak}
                      setManualBackButton={this.setManualBackButton.bind(this)}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/Analysis/Sample"
                element={
                  <RequireAuth>
                    <SampleAnalysis
                      fetchMS={(props) => fetchMSAction(props)}
                      fetchHPLC={(props) => fetchHPLCAction(props)}
                      fetchDB={(props => fetchDBAction(props))}
                      clickHPLC={(props) => this.checkFetch(recalcHPLCAction(props))}
                      DescribeExecution={(props) => DescribeExecution(props)}
                      StartExecution={(props) => StartExecution(props)}
                      getDBData={(props) => getDBData(props)}
                      changeHoverColor={(props) => changeHoverColor(props)}
                      calcValence={(props) => calcValence(props)}
                      backPrepare={() => this.setState({ is_moved: false })}
                      changePointData={this.changeSamplePointData.bind(this)}
                      changePeakAreaData={this.changeSamplePeakAreaData.bind(this)}
                      changeInversePeak={this.changeSampleInversePeak.bind(this)}
                      changePeakInformation={this.changeSamplePeakInformation.bind(this)}
                      changeColors={this.changeSampleColors.bind(this)}
                      changeLabels={this.changeSampleLabels.bind(this)}
                      changeUnit={this.changeSampleUnit.bind(this)}
                      changeFilename={this.changeSampleFilename.bind(this)}
                      filename={this.state.sampleFilename}
                      allPointData={this.state.sampleAllPointData}
                      peakPointData={this.state.samplePeakPointData}
                      peakAreaData={this.state.samplePeakAreaData}
                      xInversePeak={this.state.samplexInversePeak}
                      colors={this.state.sampleColors}
                      labels={this.state.sampleLabels}
                      sampleRetentionTime={this.state.sampleRetentionTime}
                      samplePeakArea={this.state.samplePeakArea}
                      basePeakArea={this.state.quantBasePeakArea}
                      unit={this.state.sampleUnit}
                      gu={this.state.sampleGU}
                      convertFormula={this.state.convertFormula}
                      isSample={true}
                      isHover={this.state.isHover}
                      imageXaxis={this.state.imageXaxis}
                      imageYaxis={this.state.imageYaxis}
                      clientWidhth={this.state.clientWidhth}
                      structureTarget={this.state.structureTarget}
                      changeHover={this.changeHover.bind(this)}
                      changeImageAxis={this.changeImageAxis.bind(this)}
                      changeStructureTarget={this.changeStructureTarget.bind(this)}
                      changeIsSelected={this.changeIsSampleSelected.bind(this)}
                      isSelected={this.state.isSampleSelected}
                      setManualBackButton={this.setManualBackButton.bind(this)}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/Analysis/Quantification"
                element={
                  <RequireAuth>
                    <QuantAnalysis
                      fetchMS={(props) => fetchMSAction(props)}
                      fetchHPLC={(props) => fetchHPLCAction(props)}
                      fetchDB={(props => fetchDBAction(props))}
                      clickHPLC={(props) => this.checkFetch(recalcHPLCAction(props))}
                      getDBData={(props) => getDBData(props)}
                      changeHoverColor={(props) => changeHoverColor(props)}
                      calcValence={(props) => calcValence(props)}
                      backPrepare={() => this.setState({ is_moved: false })}
                      changePointData={this.changeQuantPointData.bind(this)}
                      changePeakInformation={this.changeQuantPeakInformation.bind(this)}
                      changePeakAreaData={this.changeQuantPeakAreaData.bind(this)}
                      changePeakAreaRatio={this.changePeakAreaRatio.bind(this)}
                      changeBasePeakArea={this.changeBasePeakArea.bind(this)}
                      changeBaseAmount={this.changeBaseAmount.bind(this)}
                      changeBaseUnit={this.changeBaseUnit.bind(this)}
                      changeUnit={this.changeQuantUnit.bind(this)}
                      changeMaxPeakArea={this.changeMaxPeakArea.bind(this)}
                      changeInversePeak={this.changeQuantInversePeak.bind(this)}
                      basePeakArea={this.state.quantBasePeakArea}
                      peakAreaRatio={this.state.peakAreaRatio}
                      baseAmount={this.state.baseAmount}
                      baseUnit={this.state.baseUnit}
                      unit={this.state.quantUnit}
                      changeColors={this.changeQuantColors.bind(this)}
                      changeIsSelected={this.changeIsQuantSelected.bind(this)}
                      changeFilename={this.changeQuantFilename.bind(this)}
                      filename={this.state.quantFilename}
                      maxPeakArea={this.state.quantMaxPeakArea}
                      allPointData={this.state.quantAllPointData}
                      peakPointData={this.state.quantPeakPointData}
                      peakAreaData={this.state.quantPeakAreaData}
                      xInversePeak={this.state.quantxInversePeak}
                      colors={this.state.quantColors}
                      quantRetentionTime={this.state.quantRetentionTime}
                      quantPeakArea={this.state.quantPeakArea}
                      gu={this.state.quantGU}
                      convertFormula={this.state.convertFormula}
                      isSample={true}
                      isSelected={this.state.isQuantSelected}
                    />
                  </RequireAuth>
                }
              />
              <Route path="/DrawGlycan" element={<DrawGlycan />} />
              <Route path="/Version" element={<Version />} />
              <Route
                path="/Manual"
                element={<Manual isManualBackButton={this.state.isManualBackButton} />} />
              <Route
                path="/Protocol"
                element={
                  <Protocol
                    enProtocolUrl={this.state.enProtocolUrl}
                    jaProtocolUrl={this.state.jaProtocolUrl}
                  />
                }
              />
              <Route
                path="/License"
                element={
                  <License
                    licenseUrl={this.state.licenseUrl}
                  />
                }
              />
              <Route
                path="/Contact"
                element={
                  <Contact
                    fetchContact={(props) => fetchContact(props)}
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </Authenticator.Provider>
        <Footer />
      </div>
    );
  }
}

export default App;
