import ImageHome from "./image/home.png";
import ImageSignin from "./image/signin.png";
import ImageSignup from "./image/signup.png";
import ImageStandard from "./image/standard.png";
import ImageSample from "./image/sample.png";
import ImageMS from "./image/MS.png";
import ImageDB from "./image/DB.png";
import ImageMSMS from "./image/MSMS.png";

const defaultDocs =
`Here an introduction to each page is shown by clicking the button on the left panel.

選択した画面の概要が表示されます。`;

const homeDocs =
`Home screen
Click "Sign in / Create Account" to user authentication page.
Click "Software Manual" to move to the user guide page.
Click "Protocol" to move to experimental protocol page.
Click "License" to move to license page for this software.
Click "Contact us" to enable contact us about this software.

ホーム画面
「Sign in / Create Account」を押すとユーザー認証画面に移動します。
ユーザー認証画面ではサインインやサインアップを行います。
「Software Manual」を押すとガイド画面に移動します。
「Protocol」を押すと実験プロトコル画面に移動します。
「License」を押すとこのソフトウェアのライセンスページに移動します。
「Contact us」を押すとソフトウェアに関するお問い合わせをすることができます。`;

const signinDocs =
`Sign In Screen
Enter the user name and password, and click "Sign in" to move to the analysis screen.

サインイン画面
ユーザー名、パスワードを入力し、「Sign in」を押下すると解析画面に遷移します。`;

const signupDocs =
`Create Account Screen
Enter user information such as user name and password, and click "Create Account".
After clicking, a one-time password will be sent to the email address you entered.
After entering the one-time password, account creation is complete.

アカウント作成画面
ユーザー名、パスワードなどのユーザー情報を入力し、「Create Account」を押下します。
押下後、ワンタイムパスワードが入力したメールアドレスに送信されます。
ワンタイムパスワード入力後、アカウント作成が完了します。`;

const standardHplcDocs =
`Standard HPLC analysis window
Click the Logout button will log you out of the application and return you to the home screen.
Click Manual to move to the user guide page.
To upload the standard HPLC file(Only support CSV file), click the file icon and choose the file, or drag & drop it.
Set each parameter of the threshold of HPLC analysis in the blue-grey box.
The result of standard HPLC analysis is shown on the left panel, and the approximate curve of GU is on the right.
Click Move To Sample to move to the sample HPLC analysis. 

スタンダードHPLC解析画面
Logoutボタンを押すとアプリケーションからログアウトし、ホーム画面に戻ります。
「Manual」を押すとガイド画面に移動します。
ファイルアイコンをクリックし、ファイルを選択するか、ドラッグアンドドロップし、スタンダードHPLCファイル(CSVファイルのみサポート)をアップロードしてください。
淡い水色の枠内でHPLC解析の閾値に関するパラメーターを設定します。
左にスタンダードHPLC解析結果、右にGU近似曲線が表示されます。
「Move To Sample」を押すとサンプルHPLC解析に移動します。`;

const sampleHplcDocs =
`Sample HPLC analysis window
Click Back to Convert Formula to move to the sample HPLC analysis.
Click Manual to move to the user guide page.
Click the Logout button will log you out of the application and return you to the home screen.
To upload the standard HPLC file(Only support CSV file), click the file icon and choose the file, or drag & drop it.
Set each parameter of the threshold of HPLC analysis in the blue-grey box.
The result of standard HPLC analysis is shown on the left panel, and the peak information is on the right.
Click Move To Quantification to move to the quantitative analysis.
By clicking the peak point in the plot, you can choose MS, DB, or MSMS on the bottom-right.

サンプルHPLC解析画面
「Back to Convert Formula」を押すとスタンダードHPLC解析に移動します。
「Manual」を押すとガイド画面に移動します。
Logoutボタンを押すとアプリケーションからログアウトし、ホーム画面に戻ります。
ファイルアイコンをクリックし、ファイルを選択するか、ドラッグアンドドロップし、サンプルHPLCファイル(CSVファイルのみサポート)をアップロードしてください。
淡い水色の枠内でHPLC解析の閾値に関するパラメーターを設定します。
左のパネルにスタンダードHPLC解析結果、右にはピーク情報が表示されます。
「Move to Quantification」を押すと定量解析に移動します。
プロットのピークポイントを押すと右下で「MS」、「DB」、「MSMS」が選択可能になります。`;

const msDocs =
`MS analysis window
Upload the MS file(Only support CSV file), and click Display MS Graph to move to this MS analysis window.
The top peak, the isotope peak, and the close button are shown on the top.
The results of the MS analysis are plotted on the center.
Click ADD, or DELETE in the grey box on the bottom to change the valence.

MS解析画面
MSファイル(CSVファイルのみサポート)をアップロードし、「Display MS Graph」を押すと、このMS解析画面に移動します。
上段にTopピーク、Isotopeピーク、閉じるボタンが表示されます。
中段にMS解析結果がプロットされます。
下段の灰色の枠内で「ADD」、「DELETE」を押すと、価数を追加、消去できます。`;

const dbDocs =
`DB search window
Click Display DB table to move to this DB search window.
The file name, the GU value, the MS value, the button for the setting of the elements, 
and the close button are shown in the header.
The results of the DB search are shown in a table.
The cells of the matched DB names are highlighted.
The structural representation is shown when you place your mouse over the cell.

DB検索画面
「Display DB Table」を押すと、このDB検索結果画面に移動します。
ヘッダーにファイル名、GU値、MS値、組成設定ボタン、閉じるボタンが表示されます。
DB検索結果がテーブルとして表示されます。
マッチしたDB名のセルは強調表示されます。マウスをセルに移動させると、構造図が表示されます。`;

const msmsDocs =
`MSMS analysis window
This analysis will give you fragment information for each composition in the DB search results.
After uploading the MS file, you will be able to navigate to the MSMS tab and upload the MSMS file.
After uploading the MS file, press Display MSMS Graph to see the fragment information.

MSMS解析画面
この解析により、DB検索結果の各組成のフラグメント情報を得ることができます。
MSファイルをアップロードした後、MSMSタブに移動し、MSMSファイルをアップロードすることができるようになります。
MSファイルをアップロード後、「Display MSMS Graph」を押すと、フラグメント情報が表示されます。`;

export const BUTTON_LIST = [
  {
    id: 0,
    name: "default",
    introduction: defaultDocs,
  },
  {
    id: 1,
    name: "home",
    introduction: homeDocs,
    img: ImageHome,
  },
  {
    id: 2,
    name: "signin",
    introduction: signinDocs,
    img: ImageSignin,
  },
  {
    id: 3,
    name: "signup",
    introduction: signupDocs,
    img: ImageSignup,
  },
  {
    id: 4,
    name: "standard",
    introduction: standardHplcDocs,
    img: ImageStandard,
  },
  {
    id: 5,
    name: "sample",
    introduction: sampleHplcDocs,
    img: ImageSample,
  },
  {
    id: 6,
    name: "MS",
    introduction: msDocs,
    img: ImageMS,
  },
  {
    id: 7,
    name: "DB",
    introduction: dbDocs,
    img: ImageDB,
  },
  {
    id: 8,
    name: "MSMS",
    introduction: msmsDocs,
    img: ImageMSMS,
  }
];
