import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import '@inovua/reactdatagrid-community/index.css';
import './DB.css';
import { DBSettingModal } from './DBSetting';
import { dbColumns, ROW_PER_PAGE } from './DBTableConstant';
import { SelectList } from './MSGraph';
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsIcon from '@mui/icons-material/Settings';

const HEADER_HEIGHT = 48;
const ROW_HEIGHT = 40;
const IMAGE_LEFT_STANDARD = 980;

const DataTable = props => {
  const { selectionModel, changeSelectionModel, datasource, allDBRows } = props;

  return (
    <div style={{ 
      minWidth: '1000px',
      minHeight: '692px',
      margin: '10px',
      display: 'flex',
    }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          className={'DataGrid'}
          rows={datasource}
          columns={dbColumns}
          headerHeight={HEADER_HEIGHT}
          rowHeight={ROW_HEIGHT}
          autoHeight={true}
          // 機能制限：MSMS
          // checkboxSelection={true}
          disableColumnMenu={true}
          rowsPerPageOptions={[5, 10, 15, 25, 40, 50, 100]}
          initialState={{
            pagination: {
              pageSize: ROW_PER_PAGE,
            },
          }}
          // 機能制限：MSMS
          // selectionModel={selectionModel}
          getCellClassName={(params) => {
            if (params.field === 'dbName' && params.value.props.dbName !== 'Not match') {
              return 'dbNameMatch';
            }
            return '';
          }}
          // 機能制限：MSMS
          // onSelectionModelChange={(newSelectionModel) => {
          //   changeSelectionModel(newSelectionModel);
          //   const dbNameSet = props.datasource.reduce((dbNameSet, currentDatasource) => {
          //     if (
          //       currentDatasource.dbName.props.dbName !== 'Not match' && 
          //       newSelectionModel.includes(currentDatasource.id)
          //     ) {
          //       dbNameSet.add(currentDatasource.dbName.props.dbName);
          //     }
          //     return dbNameSet
          //   }, new Set([]));

          //   const selectedDBRows = props.datasource.reduce((selectedDBRows, currentDatasource) => {
          //     if (newSelectionModel.includes(currentDatasource.id)) {
          //       currentDatasource['matched'] = allDBRows.find(dbObj => dbObj.id === currentDatasource.id)['matched'];
          //       selectedDBRows.push(currentDatasource);
          //     }
          //     return selectedDBRows
          //   }, []);
          //   props.changeSelectedDBRow(selectedDBRows);
          //   props.changeSelectPulldownDBRow(selectedDBRows[0]);

          //   if (selectedDBRows.length === 0) {
          //     props.changeIsSpecified(false);
          //   } else {
          //     props.changeIsSpecified(true);
          //   }

          //   const dbNames = Array.from(dbNameSet).sort()
          //   if (dbNames.length > 2) {
          //     const refineDbNames = dbNames.slice(0, 2)
          //     refineDbNames.push('･･･')
          //     props.changePeakLabel(refineDbNames.join(','))
          //   } else {
          //     props.changePeakLabel(dbNames.join(','))
          //   }
          // }}
        />
      </div>
    </div>
  );
}

const imgStyle = {
  zIndex: '100',
  position: 'absolute',
  border: 'groove rgb(198, 230, 241)',
  backgroundColor: 'rgb(198, 230, 241)',
  left: '475px',
  top: '164px',
  textAlign: 'center',
};

export class DBModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DBSettingModalShow: false,
      showStructure: false,
      left: imgStyle.left,
      isLoadingImg: false,
    };
  }

  changeShow() {
    this.setState({ DBSettingModalShow: false });
  }

  changeShowStructure() {
    this.setState({ showStructure: true });
  }

  makeIndex(datasource, dbName) {
    const sortDatasource = datasource;
    for (let i = 0; i < datasource.length; i++) {
      if (sortDatasource[i].dbName.props.dbName === dbName) {
        return i;
      }
    }
    return 1;
  }

  getImgWidth(dbName) {
    const image = new Image();
    image.src = 'data:image/png;base64,' + this.props.images[dbName];
    image.addEventListener('load', () => {
      this.setState({ isLoadingImg: false });
    }, false);
    let width;
    if (image.width === 0) {
      width = parseFloat(imgStyle.left.replace('px', ''));
      this.setState({isLoadingImg: true});
    } else {
      width = IMAGE_LEFT_STANDARD - image.width;
    }
    return width
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        centered
        dialogClassName="DBModal"
        style={{overflow: 'auto'}}
      >
        <div className="DBModalBody">
          <Row className="DBModalHeader">
            <Col xs={10}>
              <Row className="DBInformation Sheet">
                <table>
                  <thead>
                    <tr>
                      <th className="CustomHeader6">Data</th>
                      <th className="CustomHeader6">GU</th>
                      <th className="CustomHeader6">MS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {this.props.filename === 'Drag and Drop' ? 'No Files Uploaded.' : this.props.filename}
                      </td>
                      <td>
                        {parseFloat(this.props.gu).toFixed(2)}
                      </td>
                      <td>
                        <select className="dbMSValue">
                          {SelectList({ array: this.props.calculatedMass.filter(v => v >= 0), key: 'mass', selectedIndex: 0 })}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </Col>

            <Col xs={1} className="dbSettingCol">
              <SettingsIcon
                className="SettingsIcon"
                fontSize="large"
                onClick={() => {
                this.setState({ DBSettingModalShow: true });
              }} />
            </Col>
            <Col xs={1} className="closeModalCol">
              <CancelIcon
                className="CancelIcon"
                fontSize="large"
                onClick={() => this.props.onHide()}
              />
            </Col>
            <DBSettingModal
              fetchDB={this.props.fetchDB}
              getDBData={this.props.getDBData}
              show={this.state.DBSettingModalShow}
              onHide={() => this.setState({ DBSettingModalShow: false })}
              changeShow={this.changeShow.bind(this)}
              changeMaxComponentValues={this.props.changeMaxComponentValues}
              changeDBData={this.props.changeDBData}
              gu={this.props.gu}
              ionValue={this.props.ionValue}
              calculatedMass={this.props.calculatedMass}
              MSDiff={this.props.MSDiff}
              GUDiff={this.props.GUDiff}
              maxComponentValues={this.props.maxComponentValues}
              minusCharge={this.props.minusCharge}
            />
          </Row>
          {this.props.isHover && !this.state.isLoadingImg ?
            <img
              src={"data:image/png;base64," + this.props.images[this.props.structureTarget]}
              className="StructureImage"
              style={
                {...imgStyle,
                top: `calc(${imgStyle.top} + ${ROW_HEIGHT} * ${this.makeIndex(this.props.datasource, this.props.structureTarget)}px)`,
                left: `calc(${this.getImgWidth(this.props.structureTarget)}px)`,
              }}
            /> : null
          }
          {this.props.show ?
            <DataTable
              composition={this.props.composition}
              datasource={this.props.datasource}
              allDBRows={this.props.allDBRows}
              changeShowStructure={this.changeShowStructure.bind(this)}
              changePeakLabel={this.props.changePeakLabel}
              changeSelectedDBRow={this.props.changeSelectedDBRow}
              changeSelectPulldownDBRow={this.props.changeSelectPulldownDBRow}
              changeIsSpecified={this.props.changeIsSpecified}
              className="DataTable"
              selectionModel={this.props.selectionModel}
              changeSelectionModel={this.props.changeSelectionModel}
            />
            : null}
        </div>
      </Modal>
    );
  }
}
