import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const SelectDBData = props => {
  const {
    selectedDBRows, changeSelectPulldownDBRow, setIsChange,
    dbData, setDBData, getCompositionRemovedColor,
  } = props;

  const handleChange = (event) => {
    const [dbName, gu, composition, compositionMS] = event.target.value.split('/');
    setDBData(event.target.value);
    for (const dbRow of selectedDBRows) {
      if (
        dbRow.dbName.props.dbName === dbName &&
        dbRow.dbGU === gu &&
        dbRow.composition === composition &&
        dbRow.compositionMS === compositionMS
      ) {
        changeSelectPulldownDBRow(dbRow);
        setIsChange(true);
        break;
      }
    }
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200 }} style={{color: 'rgb(255, 255, 255)'}}>
        <InputLabel id="demo-simple-select-autowidth-label" style={{color: 'rgb(0, 0, 0)', fontSize: '20px'}}>Selected DB</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dbData}
          onChange={handleChange}
          label="Selected DB Data"
          className='SelectArea'
          style={{color: 'rgb(0, 0, 0)', fontSize: '20px', backgroundColor: 'rgb(255, 255, 255)'}}
        >
          <MenuItem value="">
            <em>DB Name / GU / Composition / CompositionMS</em>
          </MenuItem>
          {
            selectedDBRows.map(obj => {
              const slashDBData = [
                obj.dbName.props.dbName,
                obj.dbGU,
                getCompositionRemovedColor(obj.composition),
                obj.compositionMS
              ].join('/');
              return <MenuItem value={slashDBData}>{slashDBData}</MenuItem>;
            })
          }
        </Select>
      </FormControl>
    </div>
  );
}
