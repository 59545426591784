import { SKY_BLUE, GRAY } from './PlotlyStyle.js';

const API_GATEWAY_ID = 'xjaksmzock';
const STAGE = 'dev';

export const DescribeExecution = async props => {
  let returnProps = {};
  const raw = JSON.stringify({
    executionId: props.executionId
  });

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch(`https://${API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/${STAGE}/msms/describe`, requestOptions)
    .then(response => response.text())
    .then(result => {
      const parseResponse = JSON.parse(result);
      console.log(parseResponse)
      const sfnStatus = parseResponse['status'];

      if (sfnStatus === 'RUNNING') {
        returnProps['sfnStatus'] = 'RUNNING';
      } else {
        const parseOutput = JSON.parse(parseResponse['output']);

        const allPointData = {
          x: parseOutput.all_data_dic.xArray,
          y: parseOutput.all_data_dic.yArray,
          type: 'bar',
          marker: {
            color: []
          }
        };
        const peakPointData = {
          x: parseOutput.peak_data_dic.xPeaks,
          y: parseOutput.peak_data_dic.yPeaks,
        }
  
        const top10MZ = parseOutput['top10_mz'];
        const top10Composition = parseOutput['top10_composition'];
        const top10CompositionMass = parseOutput['top10_composition_mass'];
        const top10Diff = parseOutput['top10_diff'];
        const valence = parseOutput['valence_list'];
  
        const color = [];
        for (let i = 0; i < allPointData.x.length; i++) {
          if (peakPointData.x.indexOf(allPointData.x[i]) !== -1) {
            color[i] = SKY_BLUE;
          } else {
            color[i] = GRAY;
          }
        }
        allPointData.marker.color = color;
  
        returnProps = {
          allPointData: allPointData,
          peakPointData: peakPointData,
          top10MZ: top10MZ,
          top10Composition: top10Composition,
          top10CompositionMass: top10CompositionMass,
          top10Diff: top10Diff,
          valence: valence,
          sfnStatus: sfnStatus,
          status: 'success',
          statusMessage: 'success',
        };
      }
    })
    .catch(error => {
      returnProps = {
        allPointData: { x: [], y: [], marker: { color: [] } },
        peakPointData: { x: [], y: [], marker: { color: [] } },
        top10MZ: [],
        top10Composition: [],
        top10CompositionMass: [],
        top10Diff: [],
        valence: [],
        status: 'error',
        statusMessage: 'Unexpected Error',
      };
      console.log('error', error)
    });
  return returnProps;
}

export const StartExecution = async props => {
  let returnProps;
  const raw = JSON.stringify({
    'data': props.reader.result,
    'minusCharge': props.minusCharge,
    'valence': props.valence,
    'toleranceDiff': props.toleranceDiff,
    'toleranceDiffUnit': props.toleranceDiffUnit,
    'composition': props.composition,
    'isSpecified': props.isSpecified,
  });

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch(`https://${API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/${STAGE}/msms/start`, requestOptions)
    .then(response => response.text())
    .then(result => {
      returnProps = {
        ...JSON.parse(result),
        status: 'success',
        statusMessage: 'success',
      };
      console.log(returnProps);
    })
    .catch (error => {
  returnProps = {
    allPointData: { x: [], y: [], marker: { color: [] } },
    peakPointData: { x: [], y: [], marker: { color: [] } },
    top10MZ: [],
    top10Composition: [],
    top10CompositionMass: [],
    top10Diff: [],
    valence: [],
    status: 'error',
    statusMessage: 'Unexpected Error',
  };
  console.log('error', error)
});
return returnProps;
}
