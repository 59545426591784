import { Link } from 'react-router-dom';
import {Container, Row, Col, Button} from 'react-bootstrap'
import './DrawGlycan.css';
import React, { useRef, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';

const DrawGlycan = () => {
  
  const glycan = useRef(null);

  const [isImage, setIsImage] = useState(false);

  const [ isLoading, setIsLoading ] = useState(false);

  const [image, setImage] = useState("");
  
  const onClickDraw = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    setIsImage(true);

    setIsLoading(true);

    const formData = glycan.current.value;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
    };

    fetch('https://6mnzqnbdma.execute-api.ap-northeast-1.amazonaws.com/dev', requestOptions)
    .then(response => response.text())
    .then(result => {
      const parseResponse = JSON.parse(result);
      setImage(parseResponse);
      console.log(parseResponse);
      setIsLoading(false);
    })
    .catch(error => {
      console.log('error', error);
    });
  }
  
  return (
    <Container fluid className="draw-glycan">
      <Row>
        <Col>
          <Link to="/" className="back-link-top">
            back
          </Link>
        </Col>
      </Row>
      <Row className="draw-glycan-body">
        <Col md={{span:10, offset:1}}>
          <Row>
            <h1 className="draw-glycan-heading">Draw Glycan</h1>
          </Row>
          <Row>
            <p className="glycan-input-notification">Enter structural formula here </p>
            <input
              className="glycan-input"
              ref={glycan}
            />
          </Row>
          <Row>
            <Button className="draw-button" onClick={onClickDraw}>Draw</Button>
          </Row>
          <div className="loading">
            {isLoading ? <CircularProgress className="loading"/>: isImage && <img src={`data:image/png;base64,${image}`} className="figure"/>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to="/" className="back-link-bottop">
            back
          </Link>
        </Col>
      </Row>   
    </Container>
  );
}

export default DrawGlycan;