import { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { MSSetup } from './MSSetup';
import { DBSetup } from './DBSetup';
import { MSMSSetup } from './MSMSSetup';
import './Tabs.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <p>{children}</p>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabs = (props) => {
  const {
    MSfilename,
    MSReader,
    MSMSReader,
    uploadMSFile,
    uploadMSMSFile,
    isEnter,
    pressEnterHandler,
    changeInputHandler,
    blurFocusHandler,
    changeMSModalShow,
    changeDBModalShow,
    changeMSMSModalShow,
    changeToleranceDiffUnit,
    reRunMSMSFetch,
    MSDiff,
    GUDiff,
    ToleranceDiff,
    ToleranceDiffUnit,
    minusCharge,
    status,
    statusMessage,
    isSampleSelected,
    isSameBefore,
    isNumeric,
    isMSFetching,
    isSelectedPeakMSFetched,
    isMSMSFetching,
    isValidMinusCharge,
    isotopePeakDiff,
    componentStyle,
    tabValue,
    changeTabValue,
    sfnStatus,
    getMSMSResult,
  } = props;

  const handleChange = (event, newValue) => {
    changeTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="secondary"
          variant="fullWidth"
          aria-label="full width tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: 'rgb(60, 120, 150)',
              height: '7px',
            }
          }}
          centered
        >
          <Tab label={<span className="informationTab CustomButton">MS</span>} {...a11yProps(0)} />
          <Tab label={<span className="informationTab CustomButton">DB</span>} {...a11yProps(1)} />
          {/* 機能制限：MSMS */}
          {/* {
            !isSampleSelected || isMSFetching || !isSelectedPeakMSFetched ?
            <Tab label={<span className="informationTabDisabled">MSMS</span>} {...a11yProps(2)} disabled /> :
            <Tab label={<span className="informationTab">MSMS</span>} {...a11yProps(2)} />
          } */}
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <MSSetup
          reader={MSReader}
          uploadFile={uploadMSFile}
          isEnter={isEnter}
          pressEnterHandler={pressEnterHandler}
          changeInputHandler={changeInputHandler}
          blurFocusHandler={blurFocusHandler}
          changeMSModalShow={changeMSModalShow}
          minusCharge={minusCharge}
          status={status}
          statusMessage={statusMessage}
          isSampleSelected={isSampleSelected}
          isSameBefore={isSameBefore}
          isNumeric={isNumeric}
          isFetching={isMSFetching}
          isSelectedPeakMSFetched={isSelectedPeakMSFetched}
          isValidMinusCharge={isValidMinusCharge}
          isotopePeakDiff={isotopePeakDiff}
          componentStyle={componentStyle}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <DBSetup
          reader={MSReader}
          uploadFile={uploadMSFile}
          isEnter={isEnter}
          pressEnterHandler={pressEnterHandler}
          changeInputHandler={changeInputHandler}
          blurFocusHandler={blurFocusHandler}
          changeDBModalShow={changeDBModalShow}
          MSDiff={MSDiff}
          GUDiff={GUDiff}
          status={status}
          statusMessage={statusMessage}
          isSampleSelected={isSampleSelected}
          isSameBefore={isSameBefore}
          isNumeric={isNumeric}
          isFetching={isMSFetching}
          isSelectedPeakMSFetched={isSelectedPeakMSFetched}
          isValidMinusCharge={isValidMinusCharge}
          isotopePeakDiff={isotopePeakDiff}
          componentStyle={componentStyle}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <MSMSSetup
          reader={MSMSReader}
          uploadFile={uploadMSMSFile}
          isEnter={isEnter}
          pressEnterHandler={pressEnterHandler}
          changeInputHandler={changeInputHandler}
          blurFocusHandler={blurFocusHandler}
          changeMSMSModalShow={changeMSMSModalShow}
          ToleranceDiff={ToleranceDiff}
          ToleranceDiffUnit={ToleranceDiffUnit}
          changeToleranceDiffUnit={changeToleranceDiffUnit}
          status={status}
          statusMessage={statusMessage}
          isSameBefore={isSameBefore}
          isNumeric={isNumeric}
          isFetching={isMSMSFetching}
          isValidMinusCharge={isValidMinusCharge}
          isotopePeakDiff={isotopePeakDiff}
          componentStyle={componentStyle}
          sfnStatus={sfnStatus}
          getMSMSResult={getMSMSResult}
          reRunMSMSFetch={reRunMSMSFetch}
        />
      </TabPanel>
    </Box>
  );
}

export default BasicTabs;
