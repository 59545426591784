import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import App from './App';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

import {
  REGION,
  USER_POOL_ID,
  APP_CLIENT_ID,
  IDENTITY_POOL_ID,
} from './AWSConfig';

const cognitoConstants = {
  aws_project_region: REGION,
  aws_cognito_identity_pool_id: IDENTITY_POOL_ID,
  aws_cognito_region: REGION,
  aws_user_pools_id: USER_POOL_ID,
  aws_user_pools_web_client_id: APP_CLIENT_ID,
};

Amplify.configure(cognitoConstants);

ReactDOM.render(
  <Authenticator.Provider>
    <App />
  </Authenticator.Provider>,
  document.getElementById('root')
);
