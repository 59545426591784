// Plotlyグラフ内の点の色
export const PURPLE = 'rgb(230, 100, 250)'
export const SKY_BLUE = 'rgb(17, 157, 255)'
export const SCARLET = 'rgb(200, 50, 50)'
export const GREEN = 'rgb(0, 255, 200)'
export const RED = 'rgb(255, 0, 0)'
export const ORANGE = 'rgb(250, 160, 0)'
export const GRAY = 'rgb(140, 140, 140)'
export const LIGHT_PINK = 'rgb(255,182,193)'

// Plotlyグラフ内フォント
export const FONT_FAMILY = "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
