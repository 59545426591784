import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Plot from 'react-plotly.js';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { Loader } from './Loader';
import { VALENCE_LIST, THRESHOLD_LIST } from './ValenceConstant';
import './MSGraph.css';
import './ModalContent.css';
import './PlotlyStyle.css';
import { Status } from './Status';
import { LIGHT_PINK, ORANGE, RED } from './PlotlyStyle';
import { ionMap } from './MSConstant';
import cloneDeep from 'lodash/cloneDeep';
import { MouseOverPopover } from './PopOver';
import { FONT_FAMILY } from './PlotlyStyle.js';
import CancelIcon from '@mui/icons-material/Cancel';

const MIN_WIDTH = 1000;
const MIN_HEIGHT = 500;
const X_START = 0;
const X_END = 2000;
const Y_START = 0;
const Y_END = 7000;

export const SelectList = props => {
  const { array, key, selectedIndex } = props;
  const listElement = array.reduce((result, value, i) => {
    result.push(
      i === selectedIndex ? <option selected="true">
        {key === 'mass' ? parseFloat(value).toFixed(2) : value}</option>
        : <option>
          {key === 'mass' ? parseFloat(value).toFixed(2) : value}</option>
    );
    return result;
  }, [])
  return (
    <React.Fragment>
      {listElement.length > 0 ? listElement
        : <option selected="true">-</option>}
    </React.Fragment>
  );
}

class FooterBottomRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIonValueIndex: props.ionValue[0] - 1,
      selectedMassIndex: props.selectedMassIndex,
      ionValueIndexList: props.ionValue.map(v => v - 1).filter(v => v >= 0),
      status: null,
      statusMessage: null,
    }
  }

  async reload(valenceList, massList) {
    const fetchProps = {
      mass: massList,
      gu: this.props.gu,
      valenceList: valenceList,
      MSDiff: this.props.MSDiff,
      GUDiff: parseFloat(this.props.GUDiff) / 100,
      minusCharge: this.props.minusCharge,
      maxComponentValues: this.props.maxComponentValues,
      ionType: this.props.ionType,
    }
    this.props.changeFetching(true);
    const returnProps = await this.props.fetchDB(fetchProps);
    this.props.changeFetching(false);
    this.setState({
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });
    if (returnProps.status === 'error') return;
    const dbProps = {
      dbData: returnProps.dbData,
      composition: returnProps.composition,
      massList: massList,
      valenceList: valenceList,
      changeHover: this.props.changeHover,
      isHover: this.props.isHover,
      changeImageAxis: this.props.changeImageAxis,
      imageYaxis: this.props.imageYaxis,
      changeStructureTarget: this.props.changeStructureTarget,
    }
    const datasource = this.props.getDBData(dbProps);
    this.props.changeDatasource(datasource);
    this.props.changeDBData(returnProps.dbData, returnProps.composition, datasource);
    this.props.changeIonValence(valenceList);
    this.props.changeCalculatedMass(massList);
    this.props.changeImages(returnProps.images);
  }

  _removeValence(i) {
    const ionValueIndexList = this.state.ionValueIndexList.reduce((res, idx) => {
      if (idx !== i) res.push(idx);
      return res;
    }, [])
    this.setState({ ionValueIndexList: ionValueIndexList });
    const newValenceList = ionValueIndexList.reduce((res, idx) => {
      if (idx !== i) res.push(idx);
      return res;
    }, []).map(i => {
      return VALENCE_LIST[i];
    }).sort((a, b) => a - b);
    const newMassList = ionValueIndexList.map(i => {
      return this.props.massList[i];
    }).sort((a, b) => a - b);
    this.reload(newValenceList, newMassList)
  }

  _addValence(i) {
    const ionValueIndexList = this.state.ionValueIndexList.concat([i]).sort((a, b) => a - b);
    this.setState({ ionValueIndexList: ionValueIndexList });
    const newValenceList = ionValueIndexList.map(i => {
      return VALENCE_LIST[i];
    });
    const newMassList = ionValueIndexList.map(i => {
      return this.props.massList[i];
    }).sort((a, b) => a - b);
    this.reload(newValenceList, newMassList)
  }


  _getIonValenceComponent(props) {
    const ionValueIndexList = props.ionValue.map(v => v - 1);
    if (props.massList.length === 0) return;
    return VALENCE_LIST.map((v, i) => {
      return (
        <React.Fragment>
          {i === 0 && this.state.status !== null ?
            <Status
              status={this.state.status}
              statusMessage={this.state.statusMessage} /> : null}
          <Row className="ionValenceRow" key={i}>
            <Col xs={1} className="ionValence">{v}</Col>
            <Col xs={2} className="selected">
              {ionValueIndexList.includes(i) ? "SELECTED" : null}
            </Col>
            <Col xs={3} className="mass value">{props.massList[i].toFixed(2)}</Col>
            <Col xs={1} className="mass threshould">
              {THRESHOLD_LIST[i][0] !== -1 ? THRESHOLD_LIST[i][0].toFixed(1) : null}
            </Col>
            <Col xs={1} className="mass">~</Col>
            <Col xs={1} className="mass threshould">
              {THRESHOLD_LIST[i][1] !== -1 ? THRESHOLD_LIST[i][1].toFixed(1) : null}
            </Col>
            <Col xs={{ span: 2, offset: 1 }}>
              {ionValueIndexList.includes(i) ?
                <Button className="button btn-danger valenceBtn"
                  onClick={() => this._removeValence(i)}
                  disabled={this.props.isFetching}
                >DELETE</Button>
                :
                <Button className="button btn-primary valenceBtn"
                  onClick={() => this._addValence(i)}
                  disabled={this.props.isFetching}
                >ADD</Button>
              }
            </Col>
          </Row>
        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <div className="footerBottomRow">
        {/* <Row className="footerTopRow"> */}
        {/* 機能制限：イオン種判別 */}
        {/* <Col xs={3} className="diffKey">Ion form</Col> */}
        {/* <Col xs={3} className="value">{this.props.ionTypeDetection.join(', ')}</Col> */}
        {/* </Row> */}
        <Row>
          <Col xs={3}>
            <span className="CustomHeader6">Ion Valence</span>
          </Col>
          <Col xs={3}>
            <span className="CustomHeader6">Calculated Mass</span>
          </Col>
          <Col xs={3}>
            <span className="CustomHeader6">Diff Range</span>
          </Col>
        </Row>
        {this._getIonValenceComponent(this.props)}
      </div>
    );
  }
}

export class MSModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isTopCheckBox: false,
      isIsoCheckBox: false,
      allPointData: props.allPointData,
      colors: props.allPointData.marker.color,
    }
  }

  async reload(valenceList, massList) {
    const fetchProps = {
      mass: massList,
      valenceList: valenceList,
      gu: this.props.gu,
      MSDiff: this.props.MSDiff,
      GUDiff: parseFloat(this.props.GUDiff) / 100,
      minusCharge: this.props.minusCharge,
      maxComponentValues: this.props.maxComponentValues,
      ionType: this.props.ionType,
    }
    this.changeFetching(true);
    const returnProps = await this.props.fetchDB(fetchProps);
    this.changeFetching(false);
    this.setState({
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });
    if (returnProps.status === 'error') return;
    const dbProps = {
      dbData: returnProps.dbData,
      composition: returnProps.composition,
      massList: massList,
      valenceList: valenceList,
      changeHover: this.props.changeHover,
      isHover: this.props.isHover,
      changeImageAxis: this.props.changeImageAxis,
      imageYaxis: this.props.imageYaxis,
      changeStructureTarget: this.props.changeStructureTarget,
    }
    const datasource = this.props.getDBData(dbProps);
    this.props.changeDBData(returnProps.dbData);
    this.props.changeComposition(returnProps.composition);
    this.props.changeDatasource(datasource);
    this.props.addMatchedColumn(cloneDeep(datasource));
    this.props.changeIonValence(valenceList);
    this.props.changeCalculatedMass(massList);
    this.props.changeImages(returnProps.images);
  }

  clickPeak(data, type) {
    const color = data.points[0].data.marker.color;
    const index = data.points[0].pointIndex;
    const xPoints = data.points[0].data.x;
    let topPeak = xPoints[this.props.topIndex] === undefined ? -1 : xPoints[this.props.topIndex];
    let isoPeak = xPoints[this.props.isoIndex] === undefined ? -1 : xPoints[this.props.isoIndex];
    if (type === 'top') {
      color[index] = RED;
      color[this.props.topIndex] = this.props.initialAllPointData.marker.color[this.props.topIndex];
      topPeak = xPoints[index];
    } else {
      color[index] = ORANGE;
      color[this.props.isoIndex] = this.props.initialAllPointData.marker.color[this.props.isoIndex];
      isoPeak = xPoints[index];
    }
    const allPointData = this.state.allPointData;
    allPointData.marker.color = color;
    this.props.changeMSColor([...color]);
    this.props.changeTopIndex(type === 'top' ? index : this.props.topIndex);
    this.props.changeIsoIndex(type === 'iso' ? index : this.props.isoIndex);

    let newValenceList = [];
    if (parseFloat(this.props.isotopePeak) !== -1 && parseFloat(this.props.topPeak) !== -1) {
      newValenceList = this.props.calcValence(
        Math.abs(parseFloat(this.props.isotopePeak) - parseFloat(this.props.topPeak))
      );
    } else {
      newValenceList = [1];
    }

    let ionTypeDetection;
    if (this.props.ionTypeDetection.length === 0) {
      ionTypeDetection = ['H+'];
    } else {
      ionTypeDetection = this.props.ionTypeDetection;
    }
    const minMZ = this.props.calcMinMZ(topPeak, isoPeak);
    const newMassList = newValenceList.map(valence =>
      (minMZ - (ionMap[ionTypeDetection[0]] + ionMap['H+'] * (valence - 1)) / valence) * valence
    );
    this.props.changeIonValence(newValenceList);
    this.props.calcMassByMeasuredMass(newMassList[0], ionTypeDetection);
    this.reload(newValenceList, newMassList);
  }

  changeFetching(boolean) {
    this.setState({ isFetching: boolean });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allPointData !== this.props.allPointData) {
      this.setState({
        colors: this.props.allPointData.marker.color,
        xStart: this.props.allPointData.x[0],
        xEnd: this.props.allPointData.x.slice(-1)[0],
      });
    }
    if (!this.props.show && prevProps.graphRange === this.props.graphRange) {
      this.props.changeGraphRange({
        xStart: null,
        xEnd: null,
        yStart: null,
        yEnd: null,
      });
    }
  }

  async _changeIonType(event) {
    this.changeFetching(true);
    this.props.changeIonType(event.target.value);
    await this.props.runFetch(this.props.reader, event, 'ionType');
    this.changeFetching(false);
  }

  render() {
    let allPointData;
    if (this.props.isDrawGraph) {
      allPointData = this.props.allPointData;
    } else {
      allPointData = this.state.allPointData;
    }
    return (
      <Modal
        {...this.props}
        centered
        dialogClassName="MSModal"
        style={{ overflow: 'auto' }}
      >
        <div className="MSModalBody">
          <Row className="MSModalHeader">
            <Col xs={10}>
              <Row className="MSPeakInformation Sheet">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          id="top_peak"
                          className="PeakCheckBox"
                          checked={this.state.isTopCheckBox}
                          onChange={() => {
                            this.setState({
                              isTopCheckBox: !this.state.isTopCheckBox,
                              isIsoCheckBox: false,
                            });
                          }} />
                        <label for="top_peak" className="CustomHeader6" style={{ cursor: 'pointer' }}>
                          Top Peak
                        </label>
                      </th>
                      <th>
                        <input
                          type="checkbox"
                          id="iso_peak"
                          className="PeakCheckBox"
                          checked={this.state.isIsoCheckBox}
                          onChange={() => {
                            this.setState({
                              isTopCheckBox: false,
                              isIsoCheckBox: !this.state.isIsoCheckBox,
                            });
                          }} />
                        <label for="iso_peak" className="CustomHeader6" style={{ cursor: 'pointer' }}>
                          Isotope Peak
                        </label>
                      </th>
                      <th>
                        <span className="CustomHeader6">Top-Isotope Diff</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {this.props.topPeak === -1 ? '-' : parseFloat(this.props.topPeak).toFixed(2)}
                      </td>
                      <td>
                        {this.props.isotopePeak === -1 ? '-' : parseFloat(this.props.isotopePeak).toFixed(2)}
                      </td>
                      <td>
                        {this.props.topPeak !== -1 && this.props.isotopePeak !== -1
                          ? Math.abs(parseFloat(this.props.isotopePeak) - parseFloat(this.props.topPeak)).toFixed(2)
                          : '-'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </Col>
            <Col xs={1}>
              {this.state.isFetching ? <Loader className="loaderIcon" /> : null}
            </Col>
            {/* <Col xs={2} className="ionForm">
              Ion form
              <MouseOverPopover name="ionType" />
            </Col>
            <Col xs={1}>
              <select
                className="selectIonForm"
                onChange={(event) => this._changeIonType(event)}
                value={this.props.ionType}
              >
                <option>NH4+</option>
                <option>Na+</option>
                <option>K+</option>
              </select>
            </Col> */}
            <Col xs={1} className="closeModalCol">
              <CancelIcon
                className="CancelIcon"
                fontSize="large"
                onClick={() => this.props.onHide()}
              />
            </Col>
          </Row>
          <div className="MSPlotlyDiv">
            <Plot
              data={[
                {
                  ...allPointData,
                  marker: {
                    color: this.props.MSColor,
                  },
                  showlegend: false,
                  hovertemplate:
                    "(%{x: peakPointData.xPeaks}, %{y: peakPointData.yPeaks})" +
                    "<extra></extra>"
                },
                {
                  x: [0],
                  y: [0],
                  name: 'Top Peak',
                  type: 'bar',
                  marker: {
                    color: RED,
                    opacity: 0
                  },
                },
                {
                  x: [0],
                  y: [0],
                  name: 'Isotope Peak',
                  type: 'bar',
                  marker: {
                    color: ORANGE,
                    opacity: 0
                  },
                }
              ]}
              layout={{
                title: this.props.filename === 'Drag and Drop' ? 'No Files Uploaded.' : this.props.filename,
                font: {
                  size: 10
                },
                width: Math.max(this.props.width * 0.8, MIN_WIDTH),
                height: Math.max(this.props.height * 0.6, MIN_HEIGHT),
                xaxis: {
                  title: 'm/z',
                  range: [this.props.graphRange.xStart, this.props.graphRange.xEnd],
                },
                yaxis: {
                  title: 'Intensity',
                  range: [this.props.graphRange.yStart, this.props.graphRange.yEnd],
                },
                font: {
                  family: FONT_FAMILY,
                },
              }}
              config={{
                scrollZoom: true,
              }}
              onHover={(data) => {
                const color = JSON.parse(JSON.stringify(data.points[0].data.marker.color));
                const index = data.points[0].pointIndex;
                color[index] = LIGHT_PINK;
                this.props.changeMSColor([...color])
              }}
              onUnhover={(data) => {
                const color = JSON.parse(JSON.stringify(data.points[0].data.marker.color));
                const index = data.points[0].pointIndex;
                if (this.props.topIndex === index) {
                  color[index] = RED;
                } else if (this.props.isoIndex === index) {
                  color[index] = ORANGE;
                } else {
                  color[index] = this.props.initialAllPointData.marker.color[index];
                }
                this.props.changeMSColor([...color])
              }}
              onClick={(data) => {
                if (this.state.isTopCheckBox) {
                  this.props.changeTopPeak(parseFloat(data.points[0].x));
                  this.clickPeak(data, 'top');
                } else if (this.state.isIsoCheckBox) {
                  this.props.changeIsoPeak(parseFloat(data.points[0].x));
                  this.clickPeak(data, 'iso');
                } else {
                  // do nothing
                }
              }}
              onRelayout={(event) => {
                this.props.changeGraphRange({
                  xStart: event['xaxis.range[0]'],
                  xEnd: event['xaxis.range[1]'],
                  yStart: event['yaxis.range[0]'],
                  yEnd: event['yaxis.range[1]']
                });
              }}
            />
          </div>
          <div className="MSModalFooter Sheet">
            <FooterBottomRow
              topPeak={this.props.topPeak}
              isotopePeak={this.props.isotopePeak}
              ionValue={this.props.ionValue}
              calculatedMass={this.props.calculatedMass}
              fetchDB={this.props.fetchDB}
              getDBData={this.props.getDBData}
              changeDatasource={this.props.changeDatasource}
              changeDBData={this.props.changeDBData}
              changeIonValence={this.props.changeIonValence}
              changeImages={this.props.changeImages}
              gu={this.props.gu}
              ionType={this.props.ionType}
              MSDiff={this.props.MSDiff}
              GUDiff={this.props.GUDiff}
              minusCharge={this.props.minusCharge}
              maxComponentValues={this.props.maxComponentValues}
              changeCalculatedMass={this.props.changeCalculatedMass}
              changeSelectedMassIndex={this.props.changeSelectedMassIndex}
              changeFetching={this.changeFetching.bind(this)}
              massList={this.props.massList}
              selectedMassIndex={this.props.selectedMassIndex}
              changeHover={this.props.changeHover}
              isHover={this.props.isHover}
              changeImageAxis={this.props.changeImageAxis}
              imageYaxis={this.props.imageYaxis}
              changeStructureTarget={this.props.changeStructureTarget}
              isFetching={this.state.isFetching}
              ionTypeDetection={this.props.ionTypeDetection}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
