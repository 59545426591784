import { Row, Button, Col } from 'react-bootstrap';
import { Loader } from './Loader';
import { Status } from './Status';
import {
  AttentionEnter,
  AttentionNumeric,
  AttentionMinusCharge,
} from './Attention';
import { RenderInputForm } from './RenderInputForm';
import { CsvShortNotice } from './HomeNotices';

export const MSSetup = props => {
  const {
    reader,
    uploadFile,
    isEnter,
    pressEnterHandler,
    changeInputHandler,
    blurFocusHandler,
    changeMSModalShow,
    minusCharge,
    status,
    statusMessage,
    isSampleSelected,
    isSameBefore,
    isNumeric,
    isFetching,
    isSelectedPeakMSFetched,
    isValidMinusCharge,
    componentStyle,
  } = props;

  return (
    <>
      {status !== null ? <Status status={status} statusMessage={statusMessage} /> : null}
      {isSameBefore ? null : <AttentionEnter />}
      {isNumeric ? null : <AttentionNumeric />}
      {isValidMinusCharge ? null : <AttentionMinusCharge />}
      <div className="MSTabContents Sheet">
        <Col xs={12} className="MSInputFileRow">
          <label className="MSInputFileKey fileLabel">
            <span className="MSInputFileKey CustomHeader5">Input MS Data</span>
            <input type="file" className="file" onChange={(event) => uploadFile(event)}></input>
          </label>
          {isFetching ? <Loader className="loaderIcon" /> : <></>}
        </Col>
        <CsvShortNotice />
        <Row className="minusChargeDataRow">
          <RenderInputForm
            reader={reader}
            isEnter={isEnter}
            pressEnterHandler={pressEnterHandler}
            changeInputHandler={changeInputHandler}
            blurFocusHandler={blurFocusHandler}
            keyName="Minus Charge"
            stateValue={minusCharge}
            keyClassName="minusChargeKey"
            valueClassName="CustomInputForm"
            id="minusCharge"
            keyColLength="8"
            valueColLength="4"
          />
        </Row>
        <Row>
          <Row className="displayButtonRow">
            <button
              className="displayButton CustomButton"
              onClick={() => changeMSModalShow(true)}
              disabled={!isSampleSelected || isFetching || !isSelectedPeakMSFetched}
            >
              Display MS Graph
            </button>
          </Row>
        </Row>
      </div>
    </>
  );
}
