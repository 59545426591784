import * as React from 'react';
import Popover from '@mui/material/Popover';

import './HomeNotices.css'

const MouseOverPopover = props => {
  const {
    helpCommentKey,
    explainedTerm,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <label>
      <p
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className="explainedTerm"
      >
        {explainedTerm}
      </p>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <p className="PopoverText">{helpComment[helpCommentKey]}</p>
      </Popover>
    </label>
  );
}

const helpComment = {
  'CSV': `
    CSV is a file format that uses comma-separated values, and it is typically identified by the file extension ".csv".
    Please pay attention to the difference between CSV files and Excel files.
  `,
};

export const CsvNotice = () => {
  return (
    <span className="CsvNotice">
      When inputting data using this software, please make sure to use a <MouseOverPopover helpCommentKey="CSV" explainedTerm="CSV" /> file. 
      Therefore, please export the results obtained from your analyzer in the CSV file format.
    </span>
  );
}

export const CsvShortNotice = () => {
  return (
    <span className="CsvNotice CustomSmallElement">
      ※Please upload a CSV file. 
    </span>
  );
}

export const HomeNotices = () => {
  return (
    <div className="ImportantInfo">
      <span className="notices CustomHeader4">Notices</span>
      <div className="ImportantInfoBody">
        <CsvNotice />
      </div>
    </div>
  );
}
