import React from 'react';
// import { withRouter } from 'react-router-dom';
import Plot from 'react-plotly.js';
import { Row, Col } from 'react-bootstrap';
import './GU.css'
import './PlotlyStyle.css';
import { MIN_HPLC_HEIGHT } from './PrepareAnalysis';
import { useNavigate } from 'react-router-dom';
import { FONT_FAMILY } from './PlotlyStyle.js';

const BUTTON_AREA = 70
const MARGIN = 20
const MIN_WIDTH = 340

const GU = props => {
  const navigate = useNavigate();
  const layout = {
    width: props.width - MARGIN,
    height: props.height - BUTTON_AREA,
    title: '',
    xaxis: {
      title: 'Retension Time(min)'
    },
    yaxis: {
      title: 'GU'
    },
    font: {
      family: FONT_FAMILY,
    },
  };

  return (
    <div elevation={3} className="GUFormulaArea Sheet">
      <Row>
        <div className="GUHeader">
          <span className="CustomHeader5">GU convert formula</span>
        </div>
        <div className="moveToSampleButton">
          <button
            className="MoveSampleCustomButton CustomButton"
            onClick={() => navigate('/Analysis/Sample')}
            disabled={Object.keys(props.guPointData).length === 0}
          >
            Move To Sample
          </button>
        </div>
        <Col xs={12}>
          <Plot
            data={[
              props.curvePointData,
              props.guPointData
            ]}
            layout={{
              ...layout,
              width: Math.max(props.width - MARGIN, MIN_WIDTH),
              height: Math.max(props.height - BUTTON_AREA, MIN_HPLC_HEIGHT)
            }}
            config={{
              scrollZoom: true,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default GU;
// export default withRouter(GU);
