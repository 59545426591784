import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Manual.css';
import React, { useEffect, useState } from "react";
import LeaderLine from "leader-line-new";
import { BUTTON_LIST } from "./GuideInfo.js";

const Manual = props => {
  const { isManualBackButton } = props;
  const [id, setId] = useState(0);

  let lines = [];

  useEffect(() => {
    lines.push(new LeaderLine(document.getElementById("home"), document.getElementById("sign-in"), { color: 'rgb(20, 106, 186)', size: 3, startSocket: 'bottom', endSocket: 'top' }));
    lines.push(new LeaderLine(document.getElementById("home"), document.getElementById("sign-up"), { color: 'rgb(20, 106, 186)', size: 3, startSocket: 'bottom', endSocket: 'top' }));
    lines.push(new LeaderLine(document.getElementById("sign-in"), document.getElementById("standard-hplc"), { color: 'rgb(20, 106, 186)', size: 3, startSocket: 'bottom', endSocket: 'top' }));
    lines.push(new LeaderLine(document.getElementById("sign-up"), document.getElementById("standard-hplc"), { color: 'rgb(20, 106, 186)', size: 3, startSocket: 'bottom', endSocket: 'top' }));
    lines.push(new LeaderLine(document.getElementById("standard-hplc"), document.getElementById("sample-hplc"), { color: 'rgb(20, 106, 186)', size: 3, startSocket: 'bottom', endSocket: 'top' }));
    lines.push(new LeaderLine(document.getElementById("sample-hplc"), document.getElementById("ms"), { color: 'rgb(20, 106, 186)', size: 3, startSocket: 'bottom', endSocket: 'top' }));
    lines.push(new LeaderLine(document.getElementById("sample-hplc"), document.getElementById("db"), { color: 'rgb(20, 106, 186)', size: 3, startSocket: 'bottom', endSocket: 'top' }));
    // 機能制限：MSMS
    // lines.push(new LeaderLine(document.getElementById("sample-hplc"), document.getElementById("msms"), { color: 'rgb(20, 106, 186)', size: 3, startSocket: 'bottom', endSocket: 'top' }));
    // lines.push(new LeaderLine(document.getElementById("home"), document.getElementById("used-db"), { color: 'rgb(74, 212, 233)', size: 2, endPlug: 'behind', dash: { len: 5, gap: 10 } }));
    lines.push(new LeaderLine(document.getElementById("home"), document.getElementById("protocol"), { color: 'rgb(74, 212, 233)', size: 2, endPlug: 'behind', dash: { len: 5, gap: 10 } }));
    lines.push(new LeaderLine(document.getElementById("home"), document.getElementById("license"), { color: 'rgb(74, 212, 233)', size: 2, endPlug: 'behind', dash: { len: 5, gap: 10 } }));
    lines.push(new LeaderLine(document.getElementById("home"), document.getElementById("contact"), { color: 'rgb(74, 212, 233)', size: 2, endPlug: 'behind', dash: { len: 5, gap: 10 } }));
    lines.push(new LeaderLine(document.getElementById("home"), document.getElementById("user-guide"), { color: 'rgb(74, 212, 233)', size: 2, endPlug: 'behind', dash: { len: 5, gap: 10 } }));
    lines.push(new LeaderLine(document.getElementById("standard-hplc"), document.getElementById("user-guide"), { color: 'rgb(74, 212, 233)', size: 2, endPlug: 'behind', dash: { len: 5, gap: 10 } }));
    lines.push(new LeaderLine(document.getElementById("sample-hplc"), document.getElementById("user-guide"), { color: 'rgb(74, 212, 233)', size: 2, endPlug: 'behind', dash: { len: 5, gap: 10 } }));
    lines.push(new LeaderLine(document.getElementById("db"), document.getElementById("setting-of-element"), { color: 'rgb(74, 212, 233)', size: 2, endPlug: 'behind', dash: { len: 5, gap: 10 } }));
    return () => {
      window.removeEventListener('popstate', lines.filter(l => l.remove()), false);
    };
  }, []);

  return (
    <Container fluid className="software-manual">
      <Row>
        <Col xs={12}>
          {isManualBackButton ?
            <Link to="/" className="back-link-top">back</Link> :
            <Link className="back-link-top" onClick={() => window.close()}>close</Link>
          }
        </Col>
      </Row>
      <Row className="guide-body">
        <Col className="flow-chart" md={{ span: 5, offset: 1 }}>
          <div className="flow-chart-box">
            <Row>
              <Col md={{ span: 6, offset: 1 }}>
                <Row>
                  <Col md={{ span: 3, offset: 3 }}>
                    <Button className="home" id="home" variant="link" onClick={() => setId(1)}>
                      Home
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Button className="sign-in" id="sign-in" variant="link" onClick={() => setId(2)}>
                      Sign in
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button className="sign-up" id="sign-up" variant="link" onClick={() => setId(3)}>
                      Create Account
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 3, offset: 3 }}>
                    <Button className="standard" id="standard-hplc" variant="link" onClick={() => setId(4)}>
                      Standard{"\n"}HPLC
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 3, offset: 3 }}>
                    <Button className="sample" id="sample-hplc" variant="link" onClick={() => setId(5)}>
                      Sample{"\n"}HPLC
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md={{ span: 4, offset: 1 }}>
                <Row>
                  {/* <Button className="used-db" id="used-db">Used DB</Button> */}
                  <Button className="protocol" id="protocol">Protocol</Button>
                </Row>
                <Row>
                  <Button className="license" id="license">License</Button>
                </Row>
                <Row>
                  <Button className="contact" id="contact">Contact us</Button>
                </Row>
                <Row>
                  <Button className="manual-guide" id="user-guide">Software Manual</Button>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <Button className="msManual" id="ms" variant="link" onClick={() => setId(6)}>
                  MS
                </Button>
              </Col>
              <Col xs={4}>
                <Button className="db" id="db" variant="link" onClick={() => setId(7)}>
                  DB
                </Button>
              </Col>
              {/* 機能制限：MSMS */}
              {/* <Col xs={4}>
                <Button className="msms" id="msms" variant="link" onClick={() => setId(8)}>
                  MSMS
                </Button>
              </Col> */}
            </Row>
            <Row>
            <Col md={{ span: 3, offset: 4 }}>
              <Button className="compositionSetting" id="setting-of-element">Setting of element</Button>
            </Col>
            </Row>
          </div>
        </Col>
        <Col xs={5}>
          <Row>
            <Col className="preview">
              <div className="preview-header"><p>Preview</p></div>
              <div className="preview-box">
                <img src={BUTTON_LIST[id].img} className="image" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="summary">
              <div className="summary-header"><p>Summary</p></div>
              <div className="summary-box">
                {BUTTON_LIST[id].introduction}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {isManualBackButton ?
            <Link to="/" className="back-link-top">back</Link> :
            <Link className="back-link-top" onClick={() => window.close()}>close</Link>
          }
        </Col>
      </Row>
    </Container>
  );
}
export default Manual;