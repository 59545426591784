import { Row, Button, Col, Form } from 'react-bootstrap';
import Paper from '@mui/material/Paper';
import { Loader } from './Loader';
import { Status } from './Status';
import {
  AttentionEnter,
  AttentionNumeric,
  AttentionMinusCharge,
} from './Attention';
import { MSMSStatus } from './MSMSStatus';
import { MouseOverPopover } from './PopOver';

const RenderInputForm = props => {
  const {
    reader,
    isEnter,
    pressEnterHandler,
    changeInputHandler,
    blurFocusHandler,
    stateValue, valueClassName,
    id, valueColLength,
    isMSMS,
  } = props;

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (isMSMS) {
        pressEnterHandler(event, stateValue, id, reader, true);
      } else {
        pressEnterHandler(event, stateValue, id, reader);
      }
    }
  };
  const onChange = (event) => changeInputHandler(event, stateValue, id);
  const onBlur = (event) => blurFocusHandler(event, stateValue, isEnter);

  return (
    <Col xs={valueColLength}>
      <Form.Control type='input' className={valueClassName} defaultValue={stateValue}
        onChange={onChange} onKeyPress={onKeyPress} onBlur={onBlur} />
    </Col>
  );
}


export const MSMSSetup = props => {
  const {
    reader,
    uploadFile,
    isEnter,
    pressEnterHandler,
    changeInputHandler,
    blurFocusHandler,
    changeMSMSModalShow,
    ToleranceDiff,
    ToleranceDiffUnit,
    changeToleranceDiffUnit,
    status,
    statusMessage,
    isSameBefore,
    isNumeric,
    isFetching,
    isValidMinusCharge,
    componentStyle,
    sfnStatus,
    getMSMSResult,
    reRunMSMSFetch,
  } = props;

  return (
    <>
      {status !== null ? <Status status={status} statusMessage={statusMessage} /> : null}
      {isSameBefore ? null : <AttentionEnter />}
      {isNumeric ? null : <AttentionNumeric />}
      {isValidMinusCharge ? null : <AttentionMinusCharge />}
      <Paper elevation={3} style={componentStyle}>
        <Col xs={12} className="MSInputFileRow">
          <label className="MSInputFileKey fileLabel">
            Input MSMS Data
            <input type="file" className="file" onChange={(event) => uploadFile(event)}></input>
          </label>
          {isFetching && sfnStatus !== 'RUNNING' ? <Loader className="loaderIcon" /> : <></>}
        </Col>
        <Row>
          <Col xs={8} className="ToleranceKey">
          Tolerance Diff
          <MouseOverPopover name="ToleranceDiff" />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <RenderInputForm
              reader={reader}
              isEnter={isEnter}
              pressEnterHandler={pressEnterHandler}
              changeInputHandler={changeInputHandler}
              blurFocusHandler={blurFocusHandler}
              stateValue={ToleranceDiff}
              id="ToleranceDiff"
              valueColLength="12"
              isMSMS={true}
            />
          </Col>
          <Col xs={4} className="toleranceDiffUnitSelector" style={{fontSize: '25px'}}>
            <select onChange={(event) => reRunMSMSFetch(reader, ToleranceDiff, event.target.value)} value={ToleranceDiffUnit}>
              <option>m/z</option>
              <option>ppm</option>
            </select>
          </Col>
        </Row>
        <Row >
          <Row className="displayButtonRow">
            <Button
              className="displayButton"
              onClick={() => changeMSMSModalShow(true)}
              disabled={sfnStatus !== 'SUCCEEDED'}
            >
              Display MSMS Graph
            </Button>
          </Row>
        </Row>
      </Paper>
      <MSMSStatus sfnStatus={sfnStatus} getMSMSResult={getMSMSResult} />
    </>
  );
}

