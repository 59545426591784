import { Link } from 'react-router-dom';
import {Container, Row, Col, Button} from 'react-bootstrap'

import './License.css'

export const License = props => {
  const {
    licenseUrl,
  } = props;

  return (
    <div className="License">
      <Row>
        <Col md={{ span: 1, offset: 0 }}>
          <Link to="/" className="back-link-top">
            back
          </Link>
        </Col>
      </Row>
      <h1 className="LicenseTitle">License</h1>
      <p className="LicenseDescription">
        This software uses several open source libraries.
        The list of those libraries and licenses is as follows.
      </p>
      <div className="LicenseLinkParent">
        <a
          href={licenseUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="LicenseLink"
        >
          <span className="CustomHeader5">Libraries and Licenses</span>
        </a>
      </div>
    </div>
  );
}
