import { GREEN } from './PlotlyStyle';

export const changeHoverColor = props => {
  const pointIndex = props.data.points[0].pointIndex;
  let colors = props.data.points[0].data.marker.color;
  if (Array.isArray(colors) && colors.length > 0) {
    if (colors[pointIndex] === props.beforeColor) {
      colors[pointIndex] = props.afterColor;
      if (props.isHover) {
        for (let i = 0; i < colors.length; i++) {
          if (i !== pointIndex && colors[i] !== GREEN) {
            colors[i] = props.beforeColor;
          }
        }
      }
      return colors;
    }
  }
}