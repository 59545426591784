import { Link } from 'react-router-dom';
import {Container, Row, Col, Button} from 'react-bootstrap'
import './Version.css';
import React from "react";
import { DataGrid } from '@mui/x-data-grid';

const DATABASE_COLS = [
  {field:"date", headerName:"Date", flex:3},
  {field:"name", headerName:"File Name", flex:10},
  {
    field:"DL",
    headerName:"",
    flex:1,
    align:"center",
    renderCell: (params) => {
      return (
        <Button className="DL-button">DL</Button>
      )
    }
  }
];

const DATABASE_LIST = [
  {id:1, date:"2022/08/04 ~", name:"Glycan_data_20220704.csv"},
  {id:2, date:"2022/05/12 ~", name:"Glycan_data_20220331_revised.csv"},
];

const Version = () => {
  return (
    <Container fluid className="version">
      <Row>
        <Col>
          <Link to="/" className="back-link-top">
            back
          </Link>
        </Col>
      </Row>
      <Row className="version-data">
        <Col md={{span:10, offset:1}}>
          <DataGrid
            className="DataGridSetting"
            rows={DATABASE_LIST}
            columns={DATABASE_COLS}
            autoHeight
            showColumnRightBorder
            showCellRightBorder
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to="/" className="back-link-bottop">
            back
          </Link>
        </Col>
      </Row>   
    </Container>
  );
}

export default Version;