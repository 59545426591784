import React from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import { MouseOverPopover } from './PopOver';
import './SettingThreshold.css'
import { Status } from './Status';

const REGEX = /[0-9.]/g;

class AttentionEnter extends React.Component {
  render() {
    return (
      <div className="attentionPressEnterMessage">※Please Enter to confirm your changes</div>
    );
  }
}

class AttentionNumeric extends React.Component {
  render() {
    return (
      <div className="attentionInputDigitMessage">※Contains non-numeric characters</div>
    );
  }
}

class SettingThreshold extends React.Component {
  constructor(props) {
    super(props);
    // 親コンポーネントである PrepareAnalysis の props をセットしているので null になることはない
    this.state = {
      slope: props.threshold.slope,
      neighborhoodIntensity: props.threshold.neighborhoodIntensity,
      peakArea: props.threshold.peakArea,
      neighborhoodCount: props.threshold.neighborhoodCount,
      slopeText: props.threshold.slope,
      startTime: props.startTime,
      endTime: props.endTime,
      isSameBefore: true,
      isNumeric: true,
      isEnter: false,
      status: null,
      statusMessage: null,
    }
  }

  async runFetch(reader, event, targetValue) {
    document.getElementById('slope').value =
      event.target.id === 'slope' ? targetValue : this.state.slope;
    document.getElementById('neighborhoodIntensity').value =
      event.target.id === 'neighborhoodIntensity' ? targetValue : this.state.neighborhoodIntensity;
    document.getElementById('peakArea').value =
      event.target.id === 'peakArea' ? targetValue : this.state.peakArea;
    document.getElementById('neighborhoodCount').value =
      event.target.id === 'neighborhoodCount' ? targetValue : this.state.neighborhoodCount;

    const raw = {
      data: reader.result,
      'slopeOrder': document.getElementById('slope').value,
      'averageOrder': document.getElementById('neighborhoodIntensity').value,
      'areaOrder': document.getElementById('peakArea').value,
      'sampleNum': document.getElementById('neighborhoodCount').value,
      isCalcCurve: true,
    }

    this.props.changeFetching(true);
    const returnProps = await this.props.fetchHPLC({ raw: raw });
    console.log(returnProps)
    this.setState({
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });
    this.props.changeHPLC(returnProps.peakPointData);
    this.props.changeGU(returnProps.curvePointData, returnProps.guPointData);
    this.props.changeFetching(false);
    if (this.props.isSample) {
      this.props.changePointData(
        this.props.allPointData, returnProps.peakPointData,
      );
    } else {
      this.props.changeconvertFormula(returnProps.convertFormula);
      this.props.changePointData(
        this.props.allPointData, returnProps.peakPointData,
        returnProps.curvePointData, returnProps.guPointData
      );
      this.props.changePeakAreaData(returnProps.peakAreaData);
    }
  }

  pressEnterHandler(event, stateThreshold, keyName) {
    const targetValue = event.target.value;
    if (targetValue === '') {
      event.target.value = stateThreshold;
      this.setState({
        isNumeric: true,
        isSameBefore: true,
      });
      event.target.blur();
    } else if (targetValue.match(REGEX) === null ||
      (targetValue.match(REGEX) !== null &&
        targetValue.split('').length !== targetValue.match(REGEX).length)) {
      // 数字以外の文字を含む文字列が入力された場合
      this.setState({ isNumeric: false });
    } else {
      // すべて数字である場合
      this.setState({
        [keyName]: parseFloat(targetValue),
        isSameBefore: true
      });
      event.target.blur();
      event.target.value = targetValue;

      const thresholdObject = {
        slope: this.state.slope,
        neighborhoodIntensity: this.state.neighborhoodIntensity,
        peakArea: this.state.peakArea,
        neighborhoodCount: this.state.neighborhoodCount
      };
      thresholdObject[keyName] = parseFloat(targetValue);
      this.props.changeThreshold(thresholdObject);
      if (targetValue !== String(stateThreshold)) {
        // 閾値に変更があった場合
        if (keyName === 'startTime') {
          this.props.changeRange(targetValue, this.state.endTime);
        } else if (keyName === 'endTime') {
          this.props.changeRange(this.state.startTime, targetValue);
        } else if (this.props.allPointData.x.length !== 0) {
          // ファイルアップロード前であれば、fetch は行わない
          this.runFetch(this.props.reader, event, targetValue);
        }
      }
    }
  }

  changeInputHandler(event, stateThreshold) {
    this.setState({ isSameBefore: event.target.value === String(stateThreshold) });
    if (event.target.value !== '' && event.target.value.match(REGEX) === null) {
      // 空文字ではなく、すべて数字以外の文字列である場合
      this.setState({ isNumeric: false });
    } else if (event.target.value !== '' && event.target.value.match(REGEX) !== null) {
      // 空文字ではなく、すべて数字、または、数字も数字以外の文字も両方含まれる場合
      this.setState({ isNumeric: event.target.value.split('').length === event.target.value.match(REGEX).length });
    } else {
      // 空文字である場合
      this.setState({ isNumeric: true });
    }
  }

  blurFocusHandler(event, threshold, isEnter) {
    if (!isEnter) {
      // エンターが押されずにカーソルが離れた場合
      event.target.value = threshold;
      this.setState({
        isSameBefore: true,
        isNumeric: true,
      });
    } else {
      // エンターが押された場合
      this.setState({ isEnter: false });
    }
  }

  renderInputForm(props) {
    const {
      name, id, stateValue
    } = props;
    const onKeyPress = (event) => {
      if (event.key === 'Enter') {
        this.pressEnterHandler(event, stateValue, id);
      }
    };
    const onChange = (event) => this.changeInputHandler(event, stateValue);
    const onBlur = (event) => this.blurFocusHandler(event, stateValue, this.state.isEnter);

    return (
      <React.Fragment>
        <Col xs={3} className="labelThreshold">
          <span className="CustomHeader6">{name}</span>
          <MouseOverPopover name={name} />
        </Col>
        <Col xs={3} >
          <FormControl id={id} className="CustomInputForm" defaultValue={stateValue}
            onChange={onChange} onKeyPress={onKeyPress} onBlur={onBlur} />
        </Col>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="settingThreshold Sheet">
        <Row>
          {this.state.status !== null ?
            <Status
              status={this.state.status}
              statusMessage={this.state.statusMessage} /> : null}
          <Row>
            <Col xs={5}>
              {this.state.isSameBefore ? null : <AttentionEnter />}
            </Col>
            <Col xs={5}>
              {this.state.isNumeric ? null : <AttentionNumeric />}
            </Col>
          </Row>
          <Row>
            {this.renderInputForm({ name: 'Slope', id: 'slope', stateValue: this.state.slope })}
            {this.renderInputForm({ name: 'PeakArea', id: 'peakArea', stateValue: this.state.peakArea })}
          </Row>
          <Row>
            {this.renderInputForm({
              name: 'NeighborhoodIntensity', id: 'neighborhoodIntensity', stateValue: this.state.neighborhoodIntensity
            })}
            {this.renderInputForm({
              name: 'NeighborhoodCount', id: 'neighborhoodCount', stateValue: this.state.neighborhoodCount
            })}
          </Row>
          {this.props.isSample ?
            <React.Fragment>
              <Row className="RangeRow">
                {this.renderInputForm({ name: 'Start Retension Time', id: 'startTime', stateValue: this.state.startTime })}
                {this.renderInputForm({ name: 'End Retension Time', id: 'endTime', stateValue: this.state.endTime })}
              </Row>
            </React.Fragment>
            : null}
        </Row>
      </div>
    );
  }
}

export default SettingThreshold;
