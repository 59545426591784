import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Button, Col, Form } from 'react-bootstrap';
import Divider from '@mui/material/Divider';
import './PeakInformation.css';
import { MSModal } from './MSGraph.js';
import { DBModal } from './DB.js';
import { MSMSModalWrapper } from './MSMSModalWrapper';
import { componentValues } from './DBTableConstant';
import { VALENCE_LIST } from './ValenceConstant';
import { SKY_BLUE } from './PlotlyStyle';
import { ionMap } from './MSConstant';
import { MouseOverPopover } from './PopOver';
import BasicTabs from './Tab';
import cloneDeep from 'lodash/cloneDeep';

const REGEX = /[0-9.]/g;
const MINUS_CHARGE = ['0', '1', '2', '3', '4', '5', '?'];
const NotSelectedComment = '-';

const Style = {
  backgroundColor: 'rgb(196, 213, 235)',
  margin: '15px',
  padding: '10px'
}

const QuantPeakData = props => {
  const {
    isSelected, sampleRetentionTime, gu, samplePeakArea, basePeakArea, unit, changeUnit
  } = props;

  const [peakAreaRatio, setPeakAreaRatio] = useState('-');
  const [isInput, setIsInput] = useState(false);
  const [targetPeakArea, setTargetPeakArea] = useState(samplePeakArea);
  const navigate = useNavigate();

  useEffect(() => {
    if (document.activeElement.tagName !== 'INPUT') {
      if (samplePeakArea === '-') {
        setPeakAreaRatio('-');
      } else {
        let ratio;
        if (unit === 'nmol') {
          ratio = (samplePeakArea / basePeakArea) / 1000;
        } else if (unit === 'fmol') {
          ratio = (samplePeakArea / basePeakArea) * 1000;
        } else if (unit === 'pmol') {
          ratio = samplePeakArea / basePeakArea;
        } else {
          ratio = (samplePeakArea / basePeakArea) * 100;
        }
        if (!isInput) {
          setPeakAreaRatio(parseFloat(ratio).toFixed(2));
        }
      }
    }
    if (samplePeakArea !== targetPeakArea) {
      setTargetPeakArea(samplePeakArea);
      setIsInput(false);
    }
  });

  return (
    <div elevation={3} className="peakDataRow Sheet">
      <div>
        <Row className="peakKey">
          <Col xs={7}><span className="CustomHeader6">Retention Time</span></Col>
          <Col xs={5}>
            <Form.Control
              type='input'
              className="CustomInputForm"
              value={isSelected ? parseFloat(sampleRetentionTime).toFixed(2) : NotSelectedComment}
              disabled />
          </Col>
        </Row>
        <Row className="peakKey">
          <Col xs={7}><span className="CustomHeader6">GU</span></Col>
          <Col xs={5}>
            <Form.Control
              type='input'
              className="CustomInputForm"
              value={isSelected ? parseFloat(gu).toFixed(2) : NotSelectedComment}
              disabled />
          </Col>
        </Row>
        <Row className="peakKey">
          <Col xs={7}><span className="CustomHeader6">Peak Area</span></Col>
          <Col xs={5}>
            <Form.Control
              type='input'
              className="CustomInputForm"
              value={isSelected ? parseInt(samplePeakArea) : NotSelectedComment}
              disabled />
          </Col>
        </Row>
        {/* 機能制限：定量化 */}
        {/* <Divider className="divider">Quantification</Divider>
        <div className='peakAreaRatio'>
          <Row >
            <Col>
              ratio or amount
            </Col>
          </Row>
          <Row className="peakKey PeakAreaRow">
            <Col xs={6}>
              <Form.Control
                type='input'
                value={basePeakArea !== 0 ? peakAreaRatio : '-'}
                disabled />
            </Col>
            <Col xs={6}>
              <select onChange={(event) => changeUnit(event.target.value)} disabled={!isSelected}>
                <option selected={unit === '%'}>%</option>
                <option selected={unit === 'pmol'}>pmol</option>
                <option selected={unit === 'nmol'}>nmol</option>
                <option selected={unit === 'fmol'}>fmol</option>
              </select>
            </Col>
          </Row>
        </div>
        <Button className="displayButton" onClick={() => navigate('/Analysis/Quantification')}>Move to quantification</Button> */}
      </div>
    </div>
  );
}

const MatchedProvider = props => {
  const matchedString = props.props.children;
  const numerator = matchedString.split('/')[0];
  const denominator = matchedString.split('/')[1];
  return (
    <div>
      {numerator < 5 ? <span style={{ color: 'red' }}>{numerator}</span> : <span>{numerator}</span>}
      <span>/ {denominator}</span>
    </div>
  );
}

const getCompositionMap = composition => {
  let compositionMap = {};
  if (composition.hasOwnProperty('props')) {
    compositionMap = composition.props.children.map(x =>
      [x.props.children[0].props.children, x.props.children[1].props.children]
    ).reduce((previousValue, currentValue) => {
      previousValue[currentValue[0]] = currentValue[1];
      return previousValue;
    }, {});
  } else {
    const compositions = composition.split(/[0-9]/).map(x => {if (x.length > 0) return x}).filter(Boolean);
    const compositionNumbers = composition.split(/[a-zA-Z]/).map(x => {if (x.length > 0) return x}).filter(Boolean);
    compositionMap = compositions.reduce((result, composition, i) => {
      result[composition] = compositionNumbers[i];
      return result;
    }, {});
  }
  return compositionMap;
}

const ColorProvider = (composition, top10Composition) => {
  const top10CompositionMaps = top10Composition.map(comp => getCompositionMap(comp));
  const compositionMap = getCompositionMap(composition);

  const compositionStyle = {};
  for (const key of Object.keys(compositionMap)) {
    for (const comp of top10CompositionMaps) {
      if (comp[key] === compositionMap[key]) {
        compositionStyle[key] = {color: 'black', fontWeight: 'bold'};
        break;
      } else if (comp[key] < compositionMap[key]) {
        compositionStyle[key] = {color: 'blue', fontWeight: 'bold'};
        break;
      } else {
        compositionStyle[key] = {color: 'red', fontWeight: 'bold'};
      }
    }
  }

  return (
    <div>
      {
        Object.keys(compositionMap).map(key =>
          <>
            <span style={compositionStyle[key]}>{key}</span><span>{compositionMap[key]}</span>
          </>
        )
      }
    </div>
  );
}

class PeakInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // MS パラメータ
      isotopePeakDiff: 5.0,
      isMSFetching: false,
      isEnter: false,
      isNumeric: true,
      isSameBefore: true,
      isValidMinusCharge: true,
      maxComponentValues: {
        'Ac': componentValues.Ac.default,
        'Me': componentValues.Me.default,
        'N': componentValues.N.default,
        'Ng': componentValues.Ng.default,
        'K': componentValues.K.default,
        'U': componentValues.U.default,
        'S': componentValues.S.default,
        'P': componentValues.P.default,
        'H': componentValues.H.default,
        'Hn': componentValues.Hn.default,
        'dH': componentValues.dH.default,
        'X': componentValues.X.default,
        'PA': componentValues.PA.default,
      },
      MSModalShow: false,
      DBModalShow: false,
      images: {},
      selectedMassIndex: 0,
      status: null,
      statusMessage: null,
      isDrawGraph: false,
      MSGraphRange: {
        xStart: null,
        xEnd: null,
        yStart: null,
        yEnd: null,
      },
      isDragOver: false,
      // MSMS パラメータ
      allMSMSPointData: {},
      ToleranceDiff: 1,
      ToleranceDiffUnit: 'm/z',
      isMSMSFetching: false,
      MSMSModalShow: false,
      top10MZ: [],
      top10Composition: [],
      top10CompositionMass: [],
      top10Diff: [],
      valence: [],
      executionId: null,
      sfnStatus: 'STOP',
    }
  }

  uploadMSFile(event) {
    if (event.target.files === null) {
      return
    }
    this.props.changeTabValue(0);
    this.props.changeMSfilename(event.target.files[0].name);
    const file = event.target.files;
    const reader = new FileReader();
    reader.readAsText(file[0]);
    this.props.changeMSReader(reader);
    reader.onload = () => {
      this.runFetch(reader);
    }
    event.target.value = '';
  }

  uploadMSMSFile(event) {
    if (event.target.files === null) {
      return
    }
    this.props.changeTabValue(2);
    this.props.changeMSMSfilename(event.target.files[0].name);
    const file = event.target.files;
    const reader = new FileReader();
    reader.readAsText(file[0]);
    this.props.changeMSMSReader(reader);
    reader.onload = () => {
      this.runMSMSFetch(reader);
    }
    event.target.value = '';
  }

  async runFetch(reader, event=null, eventType=null) {
    const ionType = event !== null && eventType === 'ionType' ? event.target.value : this.props.ionType;
    this.props.changeTabValue(0);
    const props = {
      reader: reader,
      'minusCharge': this.props.minusCharge,
      'isotopePeakDiff': this.state.isotopePeakDiff,
      'MSDiff': parseFloat(this.props.MSDiff),
      'GUDiff': parseFloat(this.props.GUDiff) / 100,
      'gu': this.props.gu,
      'maxComponentValues': this.state.maxComponentValues,
      'ionType': ionType,
    }

    this.setState({
      isMSFetching: true,
    });

    const returnProps = await this.props.fetchMS(props);
    this.setState({
      isMSFetching: false,
      selectedMassIndex: returnProps.ionValue[0] - 1,
    });
    this.calcMassListByCalculatedMass(returnProps.calculatedMass, returnProps.ionValue, returnProps.ionTypeDetection);
    const dbProps = {
      dbData: returnProps.dbData,
      composition: returnProps.composition,
      massList: returnProps.calculatedMass,
      valenceList: returnProps.ionValue,
      changeHover: this.props.changeHover,
      isHover: this.props.isHover,
      changeImageAxis: this.props.changeImageAxis,
      imageYaxis: this.props.imageYaxis,
      changeStructureTarget: this.props.changeStructureTarget,
    }
    const datasource = this.props.getDBData(dbProps);
    this.setState({
      maxPeakPoint: returnProps.maxPeakPoint,
      isotopePeakPoint: returnProps.isotopePeakPoint,
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
      isDrawGraph: true,
      beforeTopColor: SKY_BLUE,
      beforeIsoColor: SKY_BLUE,
    });

    this.props.changeAllMSPointData(returnProps.allPointData);
    this.props.changeInitialAllMSPointData(returnProps.initialAllPointData);
    this.changeImages(returnProps.images);
    this.props.changeTopPeak(returnProps.maxPeakPoint.x);
    this.props.changeIsoPeak(returnProps.isotopePeakPoint.x);
    this.props.changeIonValue(returnProps.ionValue);
    this.props.changeCalculatedMass(returnProps.calculatedMass);
    this.props.changeComposition(returnProps.composition);
    this.props.changeDBData(returnProps.dbData);
    this.props.changeDatasource(datasource);
    this.props.addMatchedColumn(cloneDeep(datasource));
    this.props.changeTopIndex(returnProps.topIndex);
    this.props.changeIsoIndex(returnProps.isoIndex);
    this.props.changeSamplePeakXYOfFetchedMS(this.props.selecedSamplePeakXY);
    this.props.changeIonTypeDetection(returnProps.ionTypeDetection);
  }

  async runMSMSFetch(reader) {
    this.props.changeTabValue(2);

    const fetchProps = {
      reader: reader,
      'minusCharge': this.props.minusCharge,
      'toleranceDiff': this.state.ToleranceDiff,
      'toleranceDiffUnit': this.state.ToleranceDiffUnit,
      'isSpecified': this.props.isSpecified,
    };

    if (this.props.isSpecified) {
      fetchProps['valence'] = this.props.selectedDBRows.map(dbRow => dbRow.valence);
      fetchProps['composition'] = this.props.selectedDBRows.map(dbRow =>
        dbRow.composition).reduce((compositions, compositionObject) => {
          if (compositionObject.hasOwnProperty('props')) {
            const compositionParts = compositionObject.props.children.map(x => {
              const glycan = x.props.children[0].props.children;
              const containingNumber = x.props.children[1].props.children;
              return glycan + containingNumber;
            })
            compositions.push(compositionParts.join(''));
            return compositions;
          } else {
            compositions.push(compositionObject);
            return compositions;
          }
        }, []);
    } else {
      fetchProps['valence'] = this.props.datasource.map(dbRow => dbRow.valence);
      fetchProps['composition'] = this.props.datasource.map(dbRow => dbRow.composition);
    }

    this.setState({
      isMSMSFetching: true,
      sfnStatus: 'RUNNING',
    });

    const returnProps = await this.props.StartExecution(fetchProps);
    this.setState({
      executionId: returnProps.executionArn.split(':').slice(-1)[0],
      isMSMSFetching: false,
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });
  }

  async reRunMSMSFetch(reader, targetValue, targetValueUnit) {

    if (this.props.MSMSfilename === null) return;

    this.props.changeTabValue(2);

    const fetchProps = {
      reader: reader,
      'minusCharge': this.props.minusCharge,
      'toleranceDiff': targetValue,
      'toleranceDiffUnit': targetValueUnit,
      'isSpecified': this.props.isSpecified,
    };

    if (this.props.isSpecified) {
      fetchProps['valence'] = this.props.selectedDBRows.map(dbRow => dbRow.valence);
      fetchProps['composition'] = this.props.selectedDBRows.map(dbRow =>
        dbRow.composition).reduce((compositions, compositionObject) => {
          if (compositionObject.hasOwnProperty('props')) {
            const compositionParts = compositionObject.props.children.map(x => {
              const glycan = x.props.children[0].props.children;
              const containingNumber = x.props.children[1].props.children;
              return glycan + containingNumber;
            })
            compositions.push(compositionParts.join(''));
            return compositions;
          } else {
            compositions.push(compositionObject);
            return compositions;
          }
        }, []);
    } else {
      fetchProps['valence'] = this.props.datasource.map(dbRow => dbRow.valence);
      fetchProps['composition'] = this.props.datasource.map(dbRow => dbRow.composition);
    }

    this.setState({
      isMSMSFetching: true,
      sfnStatus: 'RUNNING',
      ToleranceDiffUnit: targetValueUnit,
    });

    const returnProps = await this.props.StartExecution(fetchProps);
    this.setState({
      executionId: returnProps.executionArn.split(':').slice(-1)[0],
      isMSMSFetching: false,
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });
  }

  async getMSMSResult() {

    if (this.state.executionId === null) {
      return;
    }

    this.props.changeTabValue(2);
    const fetchProps = { executionId: this.state.executionId };
    this.setState({ isMSMSFetching: true });

    const returnProps = await this.props.DescribeExecution(fetchProps);

    this.setState({ isMSMSFetching: false });
    if (returnProps.sfnStatus === 'RUNNING') {
      this.setState({ sfnStatus: 'RUNNING' });
      return;
    }

    this.setState({
      isMSMSFetching: false,
      allMSMSPointData: returnProps.allPointData,
      top10MZ: returnProps.top10MZ,
      top10Composition: returnProps.top10Composition,
      top10CompositionMass: returnProps.top10CompositionMass,
      top10Diff: returnProps.top10Diff,
      valence: returnProps.valence,
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
      executionId: null,
      sfnStatus: returnProps.sfnStatus,
    });

    if (returnProps.status === 'error') return;

    if (this.props.isSpecified) {
      const selectedDBRows = cloneDeep(this.props.selectedDBRows);
      returnProps.top10Diff.forEach((_, index) => {
        const count = returnProps.top10Diff[index].filter(diff => diff !== '-' && diff <= 1).length;
        selectedDBRows[index]['matched'] = MatchedProvider(<p>{count + ' / 10'}</p>);
        selectedDBRows[index]['composition'] = ColorProvider(selectedDBRows[index]['composition'], returnProps.top10Composition[index]);
      });
      this.props.changeSelectedDBRow(selectedDBRows);
    } else {
      const allDBRows = this.props.allDBRows;
      returnProps.top10Diff.forEach((_, index) => {
        const count = returnProps.top10Diff[index].filter(diff => diff !== '-' && diff <= 1).length;
        allDBRows[index]['matched'] = MatchedProvider(<p>{count + ' / 10'}</p>);
        allDBRows[index]['composition'] = ColorProvider(allDBRows[index]['composition'], returnProps.top10Composition[index]);
      });
      this.props.addMatchedColumn(allDBRows);
    }
  }

  changeInputHandler(event, stateThreshold, id) {
    this.setState({ isSameBefore: event.target.value === String(stateThreshold) });
    if (id === 'minusCharge') {
      // 変更があった入力欄がマイナスチャージである場合
      this.setState({ isValidMinusCharge: MINUS_CHARGE.indexOf(String(event.target.value)) !== -1 });
    } else {
      if (event.target.value !== '' && event.target.value.match(REGEX) === null) {
        // 空文字ではなく、すべて数字以外の文字列である場合
        this.setState({ isNumeric: false });
      } else if (event.target.value !== '' && event.target.value.match(REGEX) !== null) {
        // 空文字ではなく、すべて数字、または、数字も数字以外の文字も両方含まれる場合
        this.setState({ isNumeric: event.target.value.split('').length === event.target.value.match(REGEX).length });
      } else {
        // 空文字である場合
        this.setState({ isNumeric: true });
      }
    }
  }

  blurFocusHandler(event, threshold, isEnter) {
    if (!isEnter) {
      // エンターが押されずにカーソルが離れた場合
      event.target.value = threshold;
      this.setState({
        isNumeric: true,
        isSameBefore: true,
        isValidMinusCharge: true,
      });
    } else {
      // エンターが押された場合
      this.setState({ isEnter: false });
    }
  }

  pressEnterHandler(event, stateThreshold, keyName, reader, isMSMS=false) {
    const targetValue = event.target.value;
    if (targetValue === '') {
      // 入力が空文字の場合
      event.target.value = stateThreshold;
      this.setState({
        isNumeric: true,
        isSameBefore: true,
        isValidMinusCharge: true,
      });
      event.target.blur();
    } else {
      if (keyName === 'minusCharge') {
        // マイナスチャージの閾値変更の場合
        if (MINUS_CHARGE.indexOf(String(targetValue)) === -1) {
          //マイナスチャージのテキスト欄に、1 ～ 5, ? 以外が入力された場合
          this.setState({ isValidMinusCharge: false });
        } else {
          //マイナスチャージのテキスト欄に有効な値が入力された場合
          this.setState({ isSameBefore: true });
          this.props.setStateBind(keyName, targetValue);
          if (this.props.allPointData.x.length !== 0) {
            this.reRunFetch(reader, event, keyName);
          }
          event.target.blur();
          event.target.value = targetValue
        }
      } else if (targetValue.match(REGEX) === null ||
        (targetValue.match(REGEX) !== null &&
          targetValue.split('').length !== targetValue.match(REGEX).length)) {
        // 数字以外の文字を含む文字列が入力された場合
        this.setState({ isNumeric: false });
      } else {
        // すべて数字である場合
        this.setState({ isSameBefore: true });
        this.props.setStateBind(keyName, targetValue);
        if (this.props.allPointData.x.length !== 0) {
          if (isMSMS) {
            this.reRunMSMSFetch(reader, targetValue, this.state.ToleranceDiffUnit);
          } else {
            this.reRunFetch(reader, event, keyName);
          }
        }
        event.target.blur();
        event.target.value = targetValue;
      }
    }
  }

  async reRunFetch(reader, event, keyName) {

    if (this.props.MSfilename === null) return;

    const props = {
      reader: reader,
      'minusCharge': keyName === 'minusCharge' ?
        event.target.value === '?' ? '?' : parseFloat(event.target.value)
        :
        this.props.minusCharge === '?' ? '?' : parseFloat(this.props.minusCharge),
      'isotopePeakDiff': keyName === 'isotopePeakDiff' ? parseFloat(event.target.value) : parseFloat(this.state.isotopePeakDiff),
      'MSDiff': keyName === 'MSDiff' ? parseFloat(event.target.value) : parseFloat(this.props.MSDiff),
      'GUDiff': keyName === 'GUDiff' ? parseFloat(event.target.value) / 100 : parseFloat(this.props.GUDiff) / 100,
      'gu': this.props.gu,
      'maxComponentValues': this.state.maxComponentValues,
      'ionType': keyName === 'ionType' ? event.target.value : this.props.ionType,
    }

    this.setState({
      isMSFetching: true,
    });

    const returnProps = await this.props.fetchMS(props);
    this.setState({
      isMSFetching: false,
    });
    const dbProps = {
      dbData: returnProps.dbData,
      composition: returnProps.composition,
      massList: returnProps.calculatedMass,
      valenceList: returnProps.ionValue,
      changeHover: this.props.changeHover,
      isHover: this.props.isHover,
      changeImageAxis: this.props.changeImageAxis,
      imageYaxis: this.props.imageYaxis,
      changeStructureTarget: this.props.changeStructureTarget,
    }
    const datasource = this.props.getDBData(dbProps);
    this.setState({
      allPointData: returnProps.allPointData,
      maxPeakPoint: returnProps.maxPeakPoint,
      isotopePeakPoint: returnProps.isotopePeakPoint,
      images: returnProps.images,
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });
    this.props.changeTopPeak(returnProps.maxPeakPoint.x);
    this.props.changeIsoPeak(returnProps.isotopePeakPoint.x);
    this.props.changeDatasource(datasource);
    this.props.addMatchedColumn(cloneDeep(datasource));
    this.props.changeTopIndex(returnProps.topIndex);
    this.props.changeIsoIndex(returnProps.isoIndex);
    this.props.changeIonValue(returnProps.ionValue);
    this.props.changeCalculatedMass(returnProps.calculatedMass);
  }

  renderInputForm(props) {
    const {
      keyName, stateValue, keyClassName, valueClassName,
      id, keyColLength, valueColLength
    } = props;
    const onKeyPress = (event) => {
      if (event.key === 'Enter') {
        this.pressEnterHandler(event, stateValue, id);
      }
    };
    const onChange = (event) => this.changeInputHandler(event, stateValue, id);
    const onBlur = (event) => this.blurFocusHandler(event, stateValue, this.state.isEnter);

    return (
      <>
        <Col xs={keyColLength} className={keyClassName}>
          {keyName}
          <MouseOverPopover name={keyName} />
        </Col>
        <Col xs={valueColLength}>
          <Form.Control type='input' className={valueClassName} defaultValue={stateValue}
            onChange={onChange} onKeyPress={onKeyPress} onBlur={onBlur} />
        </Col>
      </>
    );
  }

  changeMaxComponentValues(maxComponentValues) {
    this.setState({ maxComponentValues: maxComponentValues });
  }

  changeSelectedMassIndex(index) {
    this.setState({ selectedMassIndex: index });
  }

  changeImages(images) {
    let newImages = {};
    if (Array.isArray(images)) {
      for (let i = 0; i < images.length; i++) {
        for (const key in images[i]) {
          newImages[key] = images[i][key];
        }
      }
    } else {
      newImages = images;
    }
    this.setState({ images: newImages });
  }

  calcMassListByCalculatedMass(calculatedMass, valenceList, ionTypeDetection) {
    if (calculatedMass.length === 0 || valenceList.length === 0 || ionTypeDetection.length === 0) return;
    const measuredMass = (
      calculatedMass[0] + (ionMap[ionTypeDetection[0]] + ionMap['H+'] * (valenceList[0] - 1))
    ) / valenceList[0];
    this.props.changeMassList(
      VALENCE_LIST.map(valence =>
        (measuredMass - (ionMap[ionTypeDetection[0]] + ionMap['H+'] * (valence - 1)) / valence) * valence
      )
    );
  }

  calcMassByMeasuredMass(measuredMass, ionTypeDetection) {
    if (ionTypeDetection.length === 0) return;
    this.props.changeMassList(
      VALENCE_LIST.map(valence =>
        (measuredMass - (ionMap[ionTypeDetection[0]] + ionMap['H+'] * (valence - 1)) / valence) * valence
      )
    );
  }

  calcMinMZ(topPeak, isoPeak) {
    let minMZ;
    if (topPeak === -1 && isoPeak === -1) {
      minMZ = -1;
    } else if (isoPeak === -1) {
      minMZ = topPeak;
    } else if (topPeak === -1) {
      minMZ = isoPeak;
    } else {
      minMZ = Math.min(topPeak, isoPeak);
    }
    return minMZ;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sampleRetentionTime !== this.props.sampleRetentionTime && prevProps.MSfilename !== null) {
      this.setState({
        MSfilename: null
      });
    }
    if (prevProps.allPointData !== this.props.allPointData) {
      this.props.changeMSColor(this.props.allPointData.marker.color);
    }
    if (prevProps.MSfilename !== this.props.MSfilename || prevProps.MSReader !== this.props.MSReader) {
      this.setState({
        MSfilename: this.props.MSfilename,
        MSReader: this.props.MSReader,
      });
      if (this.props.MSReader.result !== null) {
        this.runFetch(this.props.MSReader);
      }
    }
    if (prevProps.MSMSReader !== this.props.MSMSReader) {
      this.setState({
        MSMSfilename: this.props.MSMSfilename,
        MSMSReader: this.props.MSMSReader,
      });
      this.runMSMSFetch(this.props.MSMSReader);
    }
    if (prevProps.selectedDBRows.length !== this.props.selectedDBRows.length) {
      this.setState({ sfnStatus: 'STOP' });
    }
  }

  changeIndex(top, iso) {
    this.setState({
      topIndex: top,
      isoIndex: iso,
    })
  }

  changeGraphRange(graphRange) {
    this.setState({ MSGraphRange: graphRange });
  }

  changeMSModalShow(boolean) {
    this.setState({ MSModalShow: boolean });
  }

  changeDBModalShow(boolean) {
    this.setState({ DBModalShow: boolean });
  }

  changeMSMSModalShow(boolean) {
    this.setState({ MSMSModalShow: boolean });
  }

  changeToleranceDiffUnit(unit) {
    this.setState({ ToleranceDiffUnit: unit });
  }

  render() {
    return (
      <React.Fragment className="peakInformation">
        <QuantPeakData {...this.props} />
        {this.props.isSelected ?
          <>
            <BasicTabs
              MSReader={this.props.MSReader}
              MSMSReader={this.props.MSMSReader}
              uploadMSFile={this.uploadMSFile.bind(this)}
              uploadMSMSFile={this.uploadMSMSFile.bind(this)}
              isEnter={this.state.isEnter}
              pressEnterHandler={this.pressEnterHandler.bind(this)}
              changeInputHandler={this.changeInputHandler.bind(this)}
              blurFocusHandler={this.blurFocusHandler.bind(this)}
              changeMSModalShow={this.changeMSModalShow.bind(this)}
              changeDBModalShow={this.changeDBModalShow.bind(this)}
              changeMSMSModalShow={this.changeMSMSModalShow.bind(this)}
              changeToleranceDiffUnit={this.changeToleranceDiffUnit.bind(this)}
              reRunMSMSFetch={this.reRunMSMSFetch.bind(this)}
              minusCharge={this.props.minusCharge}
              MSDiff={this.props.MSDiff}
              GUDiff={this.props.GUDiff}
              ToleranceDiff={this.state.ToleranceDiff}
              ToleranceDiffUnit={this.state.ToleranceDiffUnit}
              status={this.state.status}
              statusMessage={this.state.statusMessage}
              isSampleSelected={this.props.isSelected}
              isSameBefore={this.state.isSameBefore}
              isNumeric={this.state.isNumeric}
              isMSFetching={this.state.isMSFetching}
              isSelectedPeakMSFetched={
                this.props.samplePeakXYOfFetchedMS.x === this.props.selecedSamplePeakXY.x
                && this.props.samplePeakXYOfFetchedMS.y === this.props.selecedSamplePeakXY.y
              }
              isMSMSFetching={this.state.isMSMSFetching}
              isValidMinusCharge={this.state.isValidMinusCharge}
              isotopePeakDiff={this.state.isotopePeakDiff}
              componentStyle={Style}
              tabValue={this.props.tabValue}
              changeTabValue={this.props.changeTabValue}
              sfnStatus={this.state.sfnStatus}
              getMSMSResult={this.getMSMSResult.bind(this)}
              MSfilename={this.state.MSfilename}
            />
          </> : <></>
        }


        <MSModal
          fetchDB={this.props.fetchDB}
          getDBData={this.props.getDBData}
          calcValence={this.props.calcValence}
          changeDBData={this.props.changeDBData}
          changeComposition={this.props.changeComposition}
          changeDatasource={this.props.changeDatasource}
          addMatchedColumn={this.props.addMatchedColumn}
          changeIonValence={this.props.changeIonValence}
          changeCalculatedMass={this.props.changeCalculatedMass}
          changeSelectedMassIndex={this.changeSelectedMassIndex.bind(this)}
          changeImages={this.changeImages.bind(this)}
          changeTopPeak={this.props.changeTopPeak}
          changeIsoPeak={this.props.changeIsoPeak}
          calcMassByMeasuredMass={this.calcMassByMeasuredMass.bind(this)}
          calcMinMZ={this.calcMinMZ}
          changeTopIndex={this.props.changeTopIndex}
          changeIsoIndex={this.props.changeIsoIndex}
          selectedMassIndex={this.state.selectedMassIndex}
          show={this.state.MSModalShow}
          onHide={() => this.setState({ MSModalShow: false })}
          graphRange={this.state.MSGraphRange}
          changeGraphRange={this.changeGraphRange.bind(this)}
          allPointData={this.props.allPointData}
          initialAllPointData={this.props.initialAllPointData}
          MSColor={this.props.MSColor}
          changeMSColor={this.props.changeMSColor}
          maxPeakPoint={this.state.maxPeakPoint}
          isotopePeakPoint={this.state.isotopePeakPoint}
          topPeak={this.props.topPeak}
          isotopePeak={this.props.isotopePeak}
          ionValue={this.props.ionValue}
          calculatedMass={this.props.calculatedMass}
          massList={this.props.massList}
          width={this.props.plotWidth}
          height={this.props.plotHeight}
          filename={this.props.MSfilename}
          gu={this.props.gu}
          ionType={this.props.ionType}
          MSDiff={this.props.MSDiff}
          GUDiff={this.props.GUDiff}
          minusCharge={this.props.minusCharge}
          maxComponentValues={this.state.maxComponentValues}
          changeHover={this.props.changeHover}
          isHover={this.props.isHover}
          changeImageAxis={this.props.changeImageAxis}
          imageYaxis={this.props.imageYaxis}
          changeStructureTarget={this.props.changeStructureTarget}
          isDrawGraph={this.state.isDrawGraph}
          topIndex={this.props.topIndex}
          isoIndex={this.props.isoIndex}
          changeIonType={this.props.changeIonType}
          runFetch={this.runFetch.bind(this)}
          reader={this.props.MSReader}
          ionTypeDetection={this.props.ionTypeDetection}
        />

        <DBModal
          fetchDB={this.props.fetchDB}
          getDBData={this.props.getDBData}
          show={this.state.DBModalShow}
          onHide={() => this.setState({ DBModalShow: false })}
          datasource={this.props.datasource}
          allDBRows={this.props.allDBRows}
          filename={this.props.MSfilename}
          gu={this.props.gu}
          ionValue={this.props.ionValue}
          calculatedMass={this.props.calculatedMass}
          changeMaxComponentValues={this.changeMaxComponentValues.bind(this)}
          changeCalculatedMass={this.props.changeCalculatedMass}
          changeSelectedMassIndex={this.changeSelectedMassIndex.bind(this)}
          selectedMassIndex={this.state.selectedMassIndex}
          massList={this.state.massList}
          changeDBData={this.props.changeDBData}
          changeComposition={this.props.changeComposition}
          MSDiff={this.props.MSDiff}
          GUDiff={this.props.GUDiff}
          maxComponentValues={this.state.maxComponentValues}
          minusCharge={this.props.minusCharge}
          dbName={this.props.dbName}
          isHover={this.props.isHover}
          imageXaxis={this.props.imageXaxis}
          imageYaxis={this.props.imageYaxis}
          clientWidhth={this.props.clientWidhth}
          changeImageAxis={this.props.changeImageAxis}
          images={this.state.images}
          structureTarget={this.props.structureTarget}
          changeHover={this.props.changeHover}
          changeStructureTarget={this.props.changeStructureTarget}
          changePeakLabel={this.props.changePeakLabel}
          changeSelectedDBRow={this.props.changeSelectedDBRow}
          changeSelectPulldownDBRow={this.props.changeSelectPulldownDBRow}
          changeIsSpecified={this.props.changeIsSpecified}
          selectionModel={this.props.selectionModel}
          changeSelectionModel={this.props.changeSelectionModel}
        />

        <MSMSModalWrapper
          isSpecified={this.props.isSpecified}
          changeIsSpecified={this.props.changeIsSpecified}
          show={this.state.MSMSModalShow}
          onHide={() => this.setState({ MSMSModalShow: false })}
          width={this.props.plotWidth}
          height={this.props.plotHeight}
          allPointData={this.state.allMSMSPointData}
          filename={this.props.MSMSfilename}
          selectedDBRows={this.props.selectedDBRows}
          top10MZ={this.state.top10MZ}
          top10Composition={this.state.top10Composition}
          top10CompositionMass={this.state.top10CompositionMass}
          top10Diff={this.state.top10Diff}
          valence={this.state.valence}
          selectPulldownDBRow={this.props.selectPulldownDBRow}
          changeSelectPulldownDBRow={this.props.changeSelectPulldownDBRow}
          datasource={this.props.datasource}
          allDBRows={this.props.allDBRows}
          selectionModel={this.props.selectionModel}
          changeSelectedDBRow={this.props.changeSelectedDBRow}
          unSpecifiedSelectionModel={this.props.unSpecifiedSelectionModel}
          changeUnSpecifiedSelectionModel={this.props.changeUnSpecifiedSelectionModel}
        />
      </React.Fragment>
    );
  }
}

export default PeakInformation;
