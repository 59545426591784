import React from 'react';
import Plot from 'react-plotly.js';
import { PURPLE, SKY_BLUE, SCARLET, GREEN, FONT_FAMILY } from './PlotlyStyle.js';
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
import { MIN_WIDTH } from './StandardHPLC';
import { MIN_HPLC_HEIGHT } from './PrepareAnalysis.js';
import { Status } from './Status';
import './PlotlyStyle.css';

const SETTING_AREA = 50;

class SampleHPLC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allPointData: props.allPointData,
      peakPointData: props.peakPointData,
      layout: {
        width: props.width,
        height: props.height,
        xaxis: {
          title: 'Retension Time(min)',
          range: [props.startTime, props.endTime],
        },
        yaxis: {
          title: 'Intensity'
        },
        font: {
          family: FONT_FAMILY,
        },
      },
      colors: props.colors,
      status: null,
      statusMessage: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.peakPointData !== this.props.peakPointData) {
      this.setState({
        allPointData: this.props.allPointData,
        peakPointData: this.props.peakPointData,
        colors: this.props.peakPointData.marker.color,
      });
      this.props.changeColors(this.props.peakPointData.marker.color);
      this.props.changeLabels(this.props.peakPointData.marker.color.map(() => ""));
    }
    if (prevProps.startTime !== this.props.startTime || prevProps.endTime !== this.props.endTime) {
      this.setState({
        layout: {
          ...this.state.layout,
          width: this.props.width,
          height: this.props.height,
          xaxis: {
            range: [this.props.startTime, this.props.endTime],
          },
        }
      });
    }
  }

  async clickPeak(data) {
    let clickType;
    if (data.event.button === 0) {
      // グラフを左クリックされた場合
      clickType = 'left';
    } else {
      // グラフを右クリックされた場合
      clickType = 'right';
    }

    const peakData = {
      data: data,
      allPointData: this.props.allPointData,
      peakPointData: this.state.peakPointData,
      peakAreaData: this.props.peakAreaData,
      xInversePeak: this.props.xInversePeak,
      colors: this.state.colors,
      labels: this.props.labels,
      isSample: true,
      isCalcCurve: false,
      clickType: clickType,
      changePeakInformation: this.props.changePeakInformation,
      convertFormula: this.props.convertFormula,
      changePeakAreaData: this.props.changePeakAreaData,
      changeInversePeak: this.props.changeInversePeak,
    };

    this.props.changeFetching(true);

    const returnProps = await this.props.clickHPLC(peakData);

    this.props.changeFetching(false);
    this.setState({
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });
    if (returnProps.status === 'error') return;
    this.setState({
      peakPointData: returnProps.peakPointData,
      colors: [...returnProps.colors],
      labels: [...returnProps.labels],
    });
    let isBreak = false;
    for (let i = 0; i < returnProps.colors.length; i++) {
      if (returnProps.colors[i] === GREEN) {
        this.props.changeSelectedPeak(true);
        isBreak = true;
        break;
      }
    }
    if (!isBreak) {
      this.props.changeSelectedPeak(false);
    }
    this.props.changeSelecedSamplePeakXY(returnProps.selecedSamplePeakXY)
    this.props.changeColors(returnProps.colors);
    this.props.changeLabels(returnProps.labels);
    this.props.changePointData(
      this.state.allPointData, returnProps.peakPointData,
    );
  }

  render() {
    const allPointData = this.props.allPointData;
    let peakPointData;
    if (this.props.isDrawGraph) {
      peakPointData = this.props.peakPointData;
    } else {
      peakPointData = this.state.peakPointData;
    }
    return (
      <div>
        {this.state.status !== null ?
          <Status
            status={this.state.status}
            statusMessage={this.state.statusMessage} /> : null}
        <ContextMenuTrigger id="contextMenu">
          <Plot
            data={[
              allPointData,
              {
                type: "scatter",
                mode: "markers+text",
                x: peakPointData.x,
                y: peakPointData.y,
                name: peakPointData.name,
                marker: {
                  color: this.state.colors,
                  opacity: 0.5,
                  size: 20,
                  line: {
                    color: PURPLE,
                    width: 2
                  }
                },
                textposition: "top center",
                text: this.props.labels,
                customdata: Object.values(this.props.peakAreaData).map(peakArea => [peakArea]),
                hovertemplate:
                  "<b>Peak Information</b><br><br>" +
                  "Retention Time: %{x: peakPointData.xPeaks}<br>" +
                  "peakArea: %{customdata[0]}<br>" +
                  "<extra></extra>"
              }]}
            layout={{
              ...this.state.layout,
              title: this.props.filename,
              width: Math.max(this.props.width, MIN_WIDTH),
              height: Math.max(this.props.height, MIN_HPLC_HEIGHT - SETTING_AREA),
            }}
            config={{
              scrollZoom: true,
            }}
            className="peakGraph"
            onHover={(data) => {
              const props = {
                data: data, beforeColor: SKY_BLUE, afterColor: SCARLET,
                isHover: true,
              };
              const colors = this.props.changeHoverColor(props);
              if (colors) {
                this.setState({ colors: [...colors] });
              }
            }}
            onUnhover={(data) => {
              const props = {
                data: data, beforeColor: SCARLET, afterColor: SKY_BLUE,
                isHover: false,
              };
              const colors = this.props.changeHoverColor(props);
              if (colors) {
                this.setState({ colors: [...colors] });
              }
            }}
            onClick={(data) => {
              this.setState({ peakPointData: this.props.peakPointData })
              this.clickPeak(data);
            }}
          />
        </ContextMenuTrigger>
        <ContextMenu id="contextMenu"></ContextMenu>
      </div>
    );
  }
}

export default SampleHPLC;
