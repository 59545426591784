import React from 'react';
import { Loader } from './Loader';
import Header from './Header.js';
import { Container, Row, Col, Form } from 'react-bootstrap';
import QuantHPLC from './QuantHPLC.js';
import SettingThreshold from './SettingThreshold.js';
import PeakData from './QuantInformation';
import './SampleHPLC.css';
import {
  PADDING, SETTING_AREA, INPUT_AREA, MIN_WIDTH, HPLC_WIDTH_RATE
} from './PrepareAnalysis';
import { Status } from './Status';

const DEFAULT_START = 20;
const DEFAULT_END = 80;

// 試料 HPLC では範囲指定があるのでその高さを SETTING_AREA に追加
const SAMPLE_SETTING_AREA = SETTING_AREA + 55;

class QuantAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allPointData: props.allPointData,
      peakPointData: props.peakPointData,
      reader: null,
      isFetched: false,
      isFetching: false,
      isDrawGraph: false,
      threshold: {
        slope: 2000,
        neighborhoodIntensity: 2000,
        peakArea: 1000000,
        neighborhoodCount: 150
      },
      height: window.innerHeight - INPUT_AREA,
      width: Math.max(window.innerWidth, MIN_WIDTH),
      startTime: DEFAULT_START,
      endTime: DEFAULT_END,
      status: null,
      statusMessage: null,
      isDragOver: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        height: window.innerHeight - INPUT_AREA,
        width: Math.max(window.innerWidth, MIN_WIDTH),
      });
    });
  }

  dropHandler(event) {
    this.setState({ isDragOver: false });
    this.props.changeFilename(event.dataTransfer.files[0].name);
    event.preventDefault();
    const reader = new FileReader();
    reader.readAsText(event.dataTransfer.files[0]);
    reader.onload = () => {
      this.runFetch(reader);
    }
  }

  dragOverHandler(event) {
    event.preventDefault();
    this.setState({ isDragOver: true });
  }

  dragLeaveHandler(event) {
    event.preventDefault();
    this.setState({ isDragOver: false })
  }

  uploadFile(event) {
    if (event.target.files === null) {
      return
    }
    this.props.changeFilename(event.target.files[0].name);
    const self = this;
    const file = event.target.files;
    const reader = new FileReader();
    reader.readAsText(file[0]);
    this.setState({ reader: reader });
    reader.onload = function () {
      self.runFetch(reader);
    }
  }

  async runFetch(reader) {
    const raw = {
      data: reader.result,
      'slopeOrder': this.state.threshold.slope,
      'areaOrder': this.state.threshold.peakArea,
      'averageOrder': this.state.threshold.neighborhoodIntensity,
      'sampleNum': this.state.threshold.neighborhoodCount,
      isCalcCurve: false,
    }

    this.changeFetching(true);

    const returnProps = await this.props.fetchHPLC({ raw: raw });
    this.props.changePointData(
      returnProps.allPointData,
      returnProps.peakPointData,
    );
    this.setState({
      allPointData: returnProps.allPointData,
      peakPointData: returnProps.peakPointData,
      peakAreaData: returnProps.peakAreaData,
      xInversePeak: returnProps.xInversePeak,
      curvePointData: returnProps.curvePointData,
      guPointData: returnProps.guPointData,
      isFetched: returnProps.isFetched,
      isFetching: false,
      isDrawGraph: true,
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });

    const topPeak = Object.values(returnProps.peakPointData.y).length > 0 ?
      Math.max(...Object.values(returnProps.peakPointData.y)) : 0;
    if (topPeak === 0) {
      this.props.changeMaxPeakArea(0);
    } else {
      const topPeakIndex = returnProps.peakPointData.y.indexOf(topPeak);
      const topPeakRetensionTime = returnProps.peakPointData.x[topPeakIndex];
      this.props.changeMaxPeakArea(returnProps.peakAreaData[topPeakRetensionTime]);
    }
    this.props.changePeakAreaData(returnProps.peakAreaData);
    this.props.changeInversePeak(returnProps.xInversePeak);
  }

  changeGU(curvePointData, guPointData) {
    this.setState({
      curvePointData: curvePointData,
      guPointData: guPointData
    })
  }
  changeFetched(isFetched) {
    this.setState({
      isFetched: isFetched
    })
  }

  changeFetching(isFetched) {
    this.setState({
      isFetching: isFetched
    })
  }

  setThreshold(threshold) {
    this.setState({
      threshold: threshold
    });
  }

  changeHPLC(peakPointData) {
    this.setState({
      peakPointData: peakPointData,
    });
  }

  changeThreshold(threshold) {
    this.setState({
      threshold: threshold
    })
  }

  changeRange(startTime, endTime) {
    this.setState({
      startTime: startTime,
      endTime: endTime,
    })
  }

  render() {
    let loader = null;
    if (this.state.isFetching === true) {
      loader = <Loader />;
    }
    return (
      <Container className="sampleHPLCAnalysis"
        onDragOver={(event) => this.dragOverHandler(event)}
        onDragLeave={(event) => this.dragLeaveHandler(event)}
        onDrop={(event) => this.dropHandler(event)}
      >
        {
          this.state.isDragOver ?
            <div className='dragOverDisplay'>
              <p style={{ width: '50%', margin: 'auto', fontSize: '70px' }}>
                Drag and Drop
              </p>
              <p style={{ width: '50%', margin: 'auto' }}>
                ~ Quantification Data ~
              </p>
            </div> :
            <React.Fragment>
              <Header
                title="Analysis ~Analysis for Quantification HPLC~"
                button="Back to Sample HPLC"
                buttonClassName="backFomulaButton"
                analysis="quantification" />
              <Row className="mainRow">
                <Col xs={9} className="HPLCGraphCol">
                  <Form.Group>
                    <Row className="inputForm">
                      <Col xs={12}>
                        <Form.Label className="inputComment">Input Quantification Data</Form.Label>
                        <label className="fileLabel">
                          <input type="file" className="file" onChange={(event) => this.uploadFile(event)}></input>
                        </label>
                        {loader}
                      </Col>
                    </Row>
                  </Form.Group>
                  {this.state.status !== null ?
                    <Status
                      status={this.state.status}
                      statusMessage={this.state.statusMessage} /> : null}
                  <SettingThreshold
                    allPointData={this.state.allPointData}
                    threshold={this.state.threshold}
                    setthreshold={this.setThreshold.bind(this)}
                    reader={this.state.reader}
                    isDrawGraph={(this.state.isDrawGraph)}
                    isFetching={this.state.isFetching}
                    changeGU={this.changeGU.bind(this)}
                    changeHPLC={this.changeHPLC.bind(this)}
                    changeFetching={this.changeFetching.bind(this)}
                    changeThreshold={this.changeThreshold.bind(this)}
                    fetchHPLC={this.props.fetchHPLC}
                    startTime={this.state.startTime}
                    endTime={this.state.endTime}
                    changeRange={this.changeRange.bind(this)}
                    changePointData={this.props.changePointData}
                  />
                  <Row>
                    <Col xs={12}>
                      <QuantHPLC
                        peakPointData={this.state.peakPointData}
                        allPointData={this.state.allPointData}
                        peakAreaData={this.props.peakAreaData}
                        changeColors={this.props.changeColors}
                        colors={this.props.colors}
                        xInversePeak={this.props.xInversePeak}
                        isFetched={this.state.isFetched}
                        changePeakAreaData={this.props.changePeakAreaData}
                        changeInversePeak={this.props.changeInversePeak}
                        changeFetching={this.changeFetching.bind(this)}
                        changeSelectedPeak={this.props.changeIsSelected}
                        changeFetched={this.changeFetched.bind(this)}
                        changePointData={this.props.changePointData}
                        isDrawGraph={this.state.isDrawGraph}
                        clickHPLC={this.props.clickHPLC}
                        width={this.state.width * HPLC_WIDTH_RATE - PADDING}
                        height={this.state.height - SAMPLE_SETTING_AREA}
                        changePeakInformation={this.props.changePeakInformation}
                        convertFormula={this.props.convertFormula}
                        startTime={this.state.startTime}
                        endTime={this.state.endTime}
                        changeHoverColor={this.props.changeHoverColor}
                        filename={this.props.filename}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={3}>
                  <PeakData
                    {...this.props}
                    maxPeakArea={this.props.maxPeakArea}
                    quantPeakArea={this.props.quantPeakArea}
                    changePeakAreaRatio={this.props.changePeakAreaRatio}
                    changeBasePeakArea={this.props.changeBasePeakArea}
                    changeBaseAmount={this.props.changeBaseAmount}
                    changeBaseUnit={this.props.changeBaseUnit}
                    changeUnit={this.props.changeUnit}
                    basePeakArea={this.props.basePeakArea}
                    peakAreaRatio={this.props.peakAreaRatio}
                    baseAmount={this.props.baseAmount}
                    baseUnit={this.props.baseUnit}
                    unit={this.props.unit}
                />
                </Col>
              </Row>
            </React.Fragment>}
      </Container>
    );
  }
}

export default QuantAnalysis;
