import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import './SetSlider.css'

const valueStyle = {
  marginLeft: 30,
  fontSize: 20,
}

export default class SetSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  render() {
    return (
      <Box className="SliderBox">
        <Grid container item spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Slider
              onChange={(event, newValue) => this.setState({ value: newValue })}
              min={this.props.defaultValues.min}
              max={this.props.defaultValues.max}
              value={this.state.value}
              className="SliderClass"
            />
          </Grid>
          <Grid item style={valueStyle} id={this.props.id}>{this.state.value}</Grid>
        </Grid>
      </Box>
    );
  }
}
