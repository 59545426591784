import React from 'react';
import { Spinner } from 'react-bootstrap';

export class Loader extends React.Component {
    render() {
        return (
            //ローディングアイコン
            <Spinner animation="border" className="loaderIcon"/>
        );
    }
}