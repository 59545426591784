import { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import Plot from 'react-plotly.js';
import { MSMSdbColumns } from './DBTableConstant';
import { MouseOverPopover } from "./PopOver";
import './MSMSGraph.css';

const MIN_WIDTH = 1000;
const MIN_HEIGHT = 500;
const HEADER_HEIGHT = 48;
const ROW_HEIGHT = 40;

const CloseModal = props => {
  return (
    <div
      className="closeModal"
      onClick={() => props.onHide()}
    ></div>
  );
}

export const MSMSUnSpecified = props => {
  const {
    allPointData, width, height,
    allDBRows,
    changeIsSpecified,
    changeSelectedDBRow,
    changeSelectPulldownDBRow,
    unSpecifiedSelectionModel,
    changeUnSpecifiedSelectionModel,
  } = props;

  return (
    <>
      <Modal
        {...props}
        centered
        dialogClassName="MSModal"
        style={{ overflow: 'auto' }}
      >
        <div className="modalBody">
          <div className="MSMSmodalTitleRow">
            <div className="MSMSmodalTitle">MSMS graph and Top 10 Intensity - UnSpecified<MouseOverPopover name="MSMSUnSpecified" /></div>
            <div className="MSMSmodalSelectArea"></div>
            <div className="closeModalCol">{CloseModal(props)}</div>
          </div>
          <div style={{
            minWidth: '1000px',
            minHeight: '69px',
            margin: '10px',
            display: 'flex',
          }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                className='DataGrid'
                rows={allDBRows}
                columns={MSMSdbColumns}
                headerHeight={HEADER_HEIGHT}
                rowHeight={ROW_HEIGHT}
                autoHeight={true}
                checkboxSelection={true}
                disableColumnMenu={true}
                pageSize={5}
                pagination
                getCellClassName={(params) => {
                  if (params.field === 'dbName' && params.value.props.dbName !== 'Not match') {
                    return 'dbNameMatch';
                  }
                  return '';
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  changeUnSpecifiedSelectionModel(newSelectionModel);
                  changeSelectedDBRow([allDBRows[newSelectionModel]]);
                  changeSelectPulldownDBRow(allDBRows[newSelectionModel]);
                  changeIsSpecified(true);
                }}
              />
            </div>
          </div>
          <div className="MSPlotlyDiv">
            <Plot
              data={[
                allPointData
              ]}
              layout={{
                title: props.filename === 'Drag and Drop' ? 'No Files Uploaded.' : props.filename,
                margin: {
                  t: 40,
                  b: 60
                },
                width: Math.max(width * 0.8, MIN_WIDTH),
                height: Math.max(height * 0.6, MIN_HEIGHT),
                xaxis: {
                  title: 'm/z',
                },
                yaxis: {
                  title: 'Intensity',
                },
              }}
            />
          </div>
        </div>
      </Modal>
    </>

  );
}
