import { MouseOverPopover } from "./PopOver";

// 1 ページ当たりのテーブルの行数
export const ROW_PER_PAGE = 15;

export const dbColumns = [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
  },
  {
    field: 'valence',
    headerName: 'Valence',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 120,
    flex: 1,
  },
  {
    field: 'ms',
    headerName: 'MS',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
  },
  {
    field: 'composition',
    headerName: 'Composition',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 180,
    flex: 1.5,
  },
  {
    field: 'compositionMS',
    headerName: 'Composition MS',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 200,
    flex: 1,
  },
  {
    field: 'msDiff',
    headerName: 'MS Diff',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
  },
  {
    field: 'dbName',
    headerName: 'DB Name',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
    renderCell: (params) => params.value
  },
  {
    field: 'dbGU',
    headerName: 'DB GU',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
  },
  {
    field: 'guDiff',
    headerName: 'GU Diff',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
  }
];

export const MSMSdbColumns = [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
  },
  {
    field: 'valence',
    headerName: 'Valence',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 120,
    flex: 1,
  },
  {
    field: 'ms',
    headerName: 'MS',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
  },
  {
    field: 'composition',
    headerName: 'Composition',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 180,
    flex: 1.5,
    renderCell: (params) => params.value,
    renderHeader: () => (
      <>
        {'Composition'}
        <MouseOverPopover name="MSMSModalTableHeaderComposition" />
      </>
    ),
  },
  {
    field: 'compositionMS',
    headerName: 'Composition MS',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 200,
    flex: 1,
  },
  {
    field: 'msDiff',
    headerName: 'MS Diff',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
  },
  {
    field: 'dbName',
    headerName: 'DB Name',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
    renderCell: (params) => params.value
  },
  {
    field: 'dbGU',
    headerName: 'DB GU',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
  },
  {
    field: 'guDiff',
    headerName: 'GU Diff',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
  },
  {
    field: 'matched',
    headerName: 'Matched',
    headerClassName: 'MuiTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
    renderCell: (params) => params.value
  }
];

export const dbNameIndex = 6;

export const settingColumns = [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
  },
  {
    field: 'element',
    headerName: 'Element',
    headerClassName: 'SettingTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 120,
    flex: 1,
  },
  {
    field: 'acronym',
    headerName: 'Acronym',
    headerClassName: 'SettingTableColumnHeader',
    hideSortIcons: true,
    sortable: false,
    width: 150,
    flex: 1,
  },
  {
    field: 'sliderComponents',
    headerClassName: 'sliderComponents',
    hideSortIcons: true,
    sortable: false,
    width: 180,
    flex: 1.5,
    disableColumnMenu: true,
    renderHeader: () => <SliderComponentsHeader/>,
    renderCell: (params) => params.value
  },
]

const SliderComponentsHeader = props => {
  return (
    <div className="minMaxHeader">
      <div className="minHeader">min</div>
      <div className="maxHeader">MAX</div>
    </div>
  );
}

export const componentValues = {
  'Ac': { max: 2, min: 0, default: 0, fullName: 'Acetyl'},
  'Me': { max: 2, min: 0, default: 0, fullName: 'Metyl'},
  'N': { max: 5, min: 0, default: 5, fullName: 'NeuAc'},
  'Ng': { max: 5, min: 0, default: 5, fullName: 'NeuGc'},
  'K': { max: 5, min: 0, default: 0, fullName: 'KDN'},
  'U': { max: 2, min: 0, default: 0, fullName: 'HexA'},
  'S': { max: 2, min: 0, default: 0, fullName: 'Sulfate'},
  'P': { max: 2, min: 0, default: 0, fullName: 'Phosphate'},
  'H': { max: 15, min: 0, default: 15, fullName: 'Hex'},
  'Hn': { max: 12, min: 0, default: 12, fullName: 'HexNAx'},
  'dH': { max: 5, min: 0, default: 5, fullName: 'dHex'},
  'X': { max: 2, min: 0, default: 0, fullName: 'Pen'},
  'PA': { max: 1, min: 1, default: 1, fullName: 'Pyridylamino'},
}
