export const AttentionEnter = () => {
  return (
    <div className="attentionPressEnterMessage">※Please Enter to confirm your changes</div>
  );
}

export const AttentionNumeric = () => {
  return (
    <div className="attentionInputDigitMessage">※Contains non-numeric characters</div>
  );
}

export const AttentionMinusCharge = () => {
  return (
    <div className="attentionMinusCharge">※Please Enter 0 to 5 or ? in Minus Charge.</div>
  );
}