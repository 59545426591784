import React from 'react';

const MINUS_CHARGE = ['0', '1', '2', '3', '4', '5', '?'];

const hoverProvider = WrappedComponent => {
  return class HoverProviderComponent extends React.Component {
    constructor(props) {
      super(props)
    }
    onMouseEnter = e => {
      const { onMouseEnter } = this.props;
      if (onMouseEnter) {
        onMouseEnter(e);
      }
      if (WrappedComponent.props.children !== 'Not match') {
        WrappedComponent.props.changeHover(true);
        WrappedComponent.props.changeImageAxis(
          document.body.clientWidth, e.pageX, e.pageY, 
        );
        WrappedComponent.props.changeStructureTarget(WrappedComponent.props.children);
      }
    }

    onMouseLeave = e => {
      const { onMouseLeave } = this.props;
      if (onMouseLeave) {
        onMouseLeave(e);
      }
      if (WrappedComponent.props.children !== 'Not match') {
        WrappedComponent.props.changeHover(false);
      }
    }

    render() {
      return (
        <div
          {...this.props}
          className={'hoverProvider'}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          {WrappedComponent.props.children}
        </div>
      )
    }
  }
}

export const translateComposition = compositionObject => {
  const keys = Object.keys(compositionObject);
  let composition = '';
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === 'mass') {
      break;
    }
    if (compositionObject[keys[i]] !== '0') {
      composition += keys[i] + compositionObject[keys[i]];
    }
  }
  return composition;
}

export const getDBData = props => {
  const addSign = (value, needSign = true) => {
    let num;
    if (isNaN(parseFloat(value))) {
      num = '-';
    } else if (value > 0 && needSign) {
      num = '+' + parseFloat(value).toFixed(2);
    } else {
      num = parseFloat(value).toFixed(2);
    }
    return num
  }

  let primayId = 0;
  const datasource = props.composition.reduce((mergeArray, compositionObject, minusCharge) => {
    const dataPerCharge = props.massList.reduce((dataPerCharge, massValue, i) => {
      const compositionsPerMass = compositionObject[massValue].reduce((compositionsPerMass, composition, j) => {
        for (let k = j; k < props.dbData[minusCharge][massValue].length; k++) {
          if (compositionObject[massValue][j]['tag'] === props.dbData[minusCharge][massValue][k]['tag']) {
            const DBName = hoverProvider(
              <p
                changeHover={props.changeHover}
                isHover={props.isHover}
                changeImageAxis={props.changeImageAxis}
                changeStructureTarget={props.changeStructureTarget}
              >
                {props.dbData[minusCharge][massValue][k].dbName}
              </p>
            );
            compositionsPerMass.push({
              id: primayId,
              valence: props.valenceList[i],
              ms: massValue.toFixed(2),
              composition: translateComposition(composition),
              compositionMS: parseFloat(composition.mass).toFixed(2),
              msDiff: addSign(massValue - composition.mass),
              dbName: <DBName dbName={props.dbData[minusCharge][massValue][k].dbName} />,
              dbGU: addSign(props.dbData[minusCharge][massValue][k].dbGU, false),
              guDiff: addSign(props.dbData[minusCharge][massValue][k].dbGUDiff),
            });
            primayId++;
          }
        }
        return compositionsPerMass;
      }, []);
      return dataPerCharge.concat(compositionsPerMass);
    }, []);
    return mergeArray.concat(dataPerCharge);
  }, []);
  return rePrimaryId(matchAscendingSort(datasource));
}

const matchAscendingSort = datasource => {
  const matchArray = [];
  const notMatchArray = [];
  for (let i = 0; i < datasource.length; i++) {
    if (datasource[i].dbName.props.dbName !== 'Not match') {
      matchArray.push(datasource[i]);
    } else {
      notMatchArray.push(datasource[i]);
    }
  }
  return matchArray.sort((a, b) => {
    // GU Diff が存在する matchArray をソートする
    // GU Diff を比較。GU Diff に差がある場合、昇順にソート
    if (parseFloat(Math.abs(a.guDiff)) !== parseFloat(Math.abs(b.guDiff))) {
      return parseFloat(Math.abs(a.guDiff)) - parseFloat(Math.abs(b.guDiff))
    }
    // GU Diff に差がない場合、MS Diff を比較し、昇順にソート
    return parseFloat(Math.abs(a.msDiff)) - parseFloat(Math.abs(b.msDiff));
  }).concat(
    // GU Diff が存在しない notMatchArray をソートする
    // MS Diff を比較し、昇順にソート
    notMatchArray.sort((a, b) => {
      return parseFloat(Math.abs(a.msDiff)) - parseFloat(Math.abs(b.msDiff));
    })
  )
}

const rePrimaryId = datasource => {
  let primaryId = 0;
  for (const obj of datasource) {
    if (Array.isArray(obj)) {
      for (const _obj of obj) {
        _obj['id'] = primaryId;
        primaryId++;
      }
    } else {
      obj['id'] = primaryId;
      primaryId++;
    }
  }
  return datasource;
}
