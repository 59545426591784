
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import './DBSetting.css';
import { settingColumns, componentValues } from './DBTableConstant';
import SetSlider from './SetSlider';
import { Loader } from './Loader';
import { Status } from './Status';
import CancelIcon from '@mui/icons-material/Cancel';

const HEADER_HEIGHT = 80;
const ROW_HEIGHT = 40;

const CloseModal = props => {
  return (
    <div
      className="closeModal"
      onClick={() => props.onHide()}
    ></div>
  );
};

const SettingTable = props => {
  const componentData = [];
  const keys = Object.keys(componentValues);
  for (let i = 0; i < keys.length; i++) {
    componentData.push(
      {
        id: i, element: componentValues[keys[i]].fullName,
        acronym: keys[i],
        sliderComponents: <SetSlider defaultValues={componentValues[keys[i]]} id={keys[i]} value={props.maxComponentValues[keys[i]]} />
      }
    );
  }

  return (
    <div style={{ 
      minWidth: '600px',
      minHeight: '600px',
      margin: '10px',
      display: 'flex',
    }}>
      <div style={{flexGrow: 1}}>
        <DataGrid
          className={'SettingDataGrid'}
          rows={componentData}
          columns={settingColumns}
          headerHeight={HEADER_HEIGHT}
          rowHeight={ROW_HEIGHT}
          autoHeight={true}
          disableColumnMenu={true}
          hideFooter={true}
        />
      </div>
    </div>
  );
}

export class DBSettingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      status: null,
      statusMessage: null,
    }
  }

  saveSetting() {
    const maxComponentValues = {};
    const keys = Object.keys(componentValues);
    for (let i = 0; i < keys.length; i++) {
      maxComponentValues[keys[i]] = document.getElementById(keys[i]).innerHTML;
    }
    this.props.changeMaxComponentValues(maxComponentValues);
    this.reload(maxComponentValues);
  }

  async reload(maxComponentValues) {
    const props = {
      'minusCharge': this.props.minusCharge,
      'mass': this.props.calculatedMass,
      'valenceList': this.props.ionValue,
      'MSDiff': this.props.MSDiff,
      'GUDiff': parseFloat(this.props.GUDiff) / 100,
      'gu': this.props.gu,
      'maxComponentValues': maxComponentValues,
    }
  
    this.setState({
      isFetching: true,
    });
  
    const returnProps = await this.props.fetchDB(props);
    this.setState({
      isFetching: false,
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });
    if (returnProps.status === 'error') return;
    const dbProps = {
      dbData: returnProps.dbData,
      composition: returnProps.composition,
      massList: this.props.calculatedMass,
      valenceList: this.props.ionValue,
    }
    const datasource = this.props.getDBData(dbProps);
    this.props.changeDBData(returnProps.dbData, returnProps.composition, datasource);
    this.props.changeShow();
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        centered
        dialogClassName="DBSettingModal"
        style={{overflow: 'auto'}}
      >
        <div className="dbSettingBody">
          <Row className="modalTitle">
            <Col xl={10}>
              <span className="DBSettingTitle CustomHeader5">
                Search Conditions for the Number of Each Element
              </span>
            </Col>
            <Col xl={1}>{this.state.isFetching ? <Loader className="loaderIcon" /> : null}</Col>
            <Col xl={1} className="closeModalCol">
              <CancelIcon
                className="CancelIcon"
                fontSize="large"
                onClick={() => this.props.onHide()}
              />
            </Col>
          </Row>
          {this.state.status !== null ?
          <Status
            status={this.state.status}
            statusMessage={this.state.statusMessage} /> : null}
          {this.props.show ? <SettingTable maxComponentValues={this.props.maxComponentValues} />
            : null}
          <div className="saveButtonDiv">
            <button className="CustomButton CustomHeader5"
              onClick={() => this.saveSetting()}
              >SAVE</button>
          </div>
        </div>
      </Modal>
    );
  }
}
