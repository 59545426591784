import './Footer.css';

export const Footer = () => {
  return (
    <div className="Footer">
      <footer>
        <p className="FooterString">© 2023 M&Ouml;BIUS Co., Ltd.</p>
      </footer>
    </div>
  );
}
