import { SKY_BLUE, SCARLET, GREEN, RED, ORANGE, GRAY } from './PlotlyStyle.js';
import { API_GATEWAY_ID, STAGE } from './AWSConfig';

export const fetchHPLCAction = async props => {
  let returnProps;
  const raw = JSON.stringify(props.raw);

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch(`https://${API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/${STAGE}/hplc`, requestOptions)
    .then(response => response.text())
    .then(result => {
      const parseResponse = JSON.parse(result);
      console.log(parseResponse);
      if ('errorMessage' in parseResponse) {
        returnProps = {
          allPointData: { x: [], y: [] },
          peakPointData: { x: [], y: [], marker: { color: [] } },
          peakAreaData: { x: [], y: [] },
          curvePointData: { x: [], y: [] },
          guPointData: { x: [], y: [] },
          isFetched: false,
          status: 'error',
          statusMessage: parseResponse['errorMessage'],
        };
      } else {
        const data = JSON.parse(parseResponse.body);
        const allPointData = {
          x: data.all_data_dic.xArray,
          y: data.all_data_dic.yArray,
          mode: 'lines',
          name: 'all point',
          marker: {
            color: 'rgb(0, 0, 0)'
          }
        }
        const peakPointData = {
          x: data.peak_data_dic.xPeaks,
          y: data.peak_data_dic.yPeaks,
          mode: 'markers',
          name: 'peak point',
          marker: {
            color: new Array(data.peak_data_dic.xPeaks.length).fill(SKY_BLUE)
          }
        }
  
        const peakAreaData = data.peak_data_dic.peakAreas;
        const xInversePeak = data.peak_data_dic.xInversePeak;
  
        const curvePointData = {
          x: data.curve_data.xArray,
          y: data.curve_data.yArray,
          mode: 'lines',
          name: 'approximate curve',
          marker: {
            color: 'blue'
          }
        }
        const guPointData = {
          x: Object.keys(data.curve_data.gu_dict).map(x => parseFloat(x)),
          y: Object.values(data.curve_data.gu_dict),
          mode: 'markers',
          name: 'glucose unit',
          marker: {
            color: 'red'
          }
        };
        returnProps = {
          allPointData,
          peakPointData,
          peakAreaData,
          xInversePeak,
          curvePointData,
          guPointData,
          convertFormula: data.curve_data.coeff,
          isFetched: true,
          isError: false,
          status: 'success',
          statusMessage: 'success',
        };
      }
    })
    .catch(error => {
      console.log('error', error)
      returnProps = {
        allPointData: { x: [], y: [] },
        peakPointData: { x: [], y: [], marker: { color: [] } },
        peakAreaData: { x: [], y: [] },
        curvePointData: { x: [], y: [] },
        guPointData: { x: [], y: [] },
        isFetched: false,
        status: 'error',
        statusMessage: 'Unexpected Error',
      };
    });
  return returnProps;
}

export const recalcHPLCAction = async props => {
  let returnProps;
  let peakAreaStatus = 'success';
  let peakAreaStatusMessage = 'success';
  const {
    data, allPointData, peakPointData, peakAreaData,
    xInversePeak, colors, labels, isSample, isCalcCurve
  } = props;

  const points = data.points[0];
  const pointIndex = points.pointIndex;
  const selecedSamplePeakXY = {
    x: peakPointData.x[pointIndex],
    y: peakPointData.y[pointIndex]
  }

  const calcGU = props => {
    let gu = 0;
    for (let i = 0; i < props.convertFormula.length; i++) {
      gu += parseFloat(props.convertFormula[i]) * (parseFloat(props.sampleRetentionTime) ** (5 - i));
    }
    return gu;
  }

  if (points.data.mode === 'lines') {
    // ピークではない点がクリックされた場合
    peakPointData.x.push(points.data.x[pointIndex]);
    peakPointData.x = peakPointData.x.sort((a, b) => a - b);
    const insertedIndex = peakPointData.x.indexOf(points.data.x[pointIndex]);
    peakPointData.y.splice(insertedIndex, 0, points.data.y[pointIndex]);
    if (isSample) {
      // サンプル HPLC の場合、ピーク追加と同時にピーク選択も行う
      colors.splice(insertedIndex, 0, GREEN);
      if (labels) {
        labels.splice(insertedIndex, 0, "");
      }
      for (let i = 0; i < colors.length; i++) {
        if (i !== insertedIndex) {
          // 一度に選択できるピークは1つなので、他のピークがクリックされたら色をスカイブルーに戻す
          colors[i] = SKY_BLUE;
        }
      }
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        xArray: allPointData.x,
        yArray: allPointData.y,
        xPeaks: peakPointData.x,
        xInversePeak: xInversePeak,
        addingPoint: points.data.x[pointIndex],
        addPeak: true,
        isCalcCurve: isCalcCurve,
      })
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };
      // サンプルHPLCにおいて、ピークではない点に対する積算値を計算するためのFetchを行う
      await fetch(`https://${API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/${STAGE}/hplc`, requestOptions)
        .then(response => response.text())
        .then(result => {
          const parseResponse = JSON.parse(result);
          if ('errorMessage' in parseResponse) {
            peakAreaStatus = 'error';
            peakAreaStatusMessage = 'Unexpected Error';
          } else {
            const responseBody = JSON.parse(parseResponse.body);
            const peakAreaData = responseBody.peakAreas;
            const xInversePeak = responseBody.xInversePeak;
            const sampleRetentionTime = peakPointData.x[insertedIndex];
            const samplePeakArea = peakAreaData[sampleRetentionTime];
            const peakProps = {
              sampleRetentionTime: sampleRetentionTime,
              samplePeakArea: samplePeakArea,
              convertFormula: props.convertFormula,
            }
            const gu = calcGU(peakProps);
            props.changePeakAreaData(peakAreaData);
            props.changePeakInformation(sampleRetentionTime, samplePeakArea, gu);
            props.changeInversePeak(xInversePeak);
          }
        })
        .catch(error => {
          console.log('error', error);
          peakAreaStatus = 'error';
          peakAreaStatusMessage = 'Unexpected Error';
        });
    } else {
      colors.push(SKY_BLUE);
    }
  } else if (!isSample) {
    // 標準HPLCのピークがクリックされた場合
    // 標準HPLCのときのみクリック時にピークを消す
    peakPointData.x.splice(points.pointIndex, 1);
    peakPointData.y.splice(points.pointIndex, 1);
    colors.splice(points.pointIndex, 1);
  } else {
    // サンプルHPLCのピークがクリックされた場合
    if (props.clickType === 'left') {
      // サンプルHPLCのピークが左クリックされた場合
      for (let i = 0; i < colors.length; i++) {
        if (colors[i] === SCARLET) {
          // ホバーしている状態でクリックされたら色をグリーンに変更
          colors[i] = GREEN;
          const sampleRetentionTime = peakPointData.x[pointIndex];
          const samplePeakArea = peakAreaData[sampleRetentionTime];
          const peakProps = {
            sampleRetentionTime: sampleRetentionTime,
            samplePeakArea: samplePeakArea,
            convertFormula: props.convertFormula,
          }
          const gu = calcGU(peakProps);
          props.changePeakInformation(sampleRetentionTime, samplePeakArea, gu);
        } else if (colors[i] === GREEN) {
          // 一度に選択できるピークは1つなので、他のピークがクリックされたら色をスカイブルーに戻す
          colors[i] = SKY_BLUE;
        }
      }
    } else {
      // サンプルHPLCのピークが右クリックされた場合、ピークを削除する
      peakPointData.x.splice(points.pointIndex, 1);
      peakPointData.y.splice(points.pointIndex, 1);
      colors.splice(points.pointIndex, 1);
      if (labels !== undefined) {
        labels.splice(points.pointIndex, 1);
      }
      selecedSamplePeakXY.x = ""
      selecedSamplePeakXY.y = ""
    }
  }

  if (isSample) {
    returnProps = {
      peakPointData: peakPointData,
      selecedSamplePeakXY: selecedSamplePeakXY,
      xInversePeak: xInversePeak,
      colors: [...colors],
      labels: labels !== undefined ? [...labels] : [],
      status: peakAreaStatus,
      statusMessage: peakAreaStatusMessage,
    };
    return returnProps;
  }

  const raw = JSON.stringify({
    peakAreaData: peakAreaData,
    xArray: allPointData.x,
    yArray: allPointData.y,
    xPeaks: peakPointData.x,
    xInversePeak: xInversePeak,
    addingPoint: points.data.x[pointIndex],
    addPeak: true,
    guSync: true,
    isCalcCurve: isCalcCurve,
  })

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    // mode: 'no-cors'
  };
  await fetch(`https://${API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/${STAGE}/hplc`, requestOptions)
    .then(response => response.text())
    .then(result => {
      const parseResponse = JSON.parse(result);
      console.log(parseResponse);
      if ('errorMessage' in parseResponse) {
        returnProps = {
          allPointData: { x: [], y: [] },
          peakPointData: { x: [], y: [], marker: { color: [] } },
          peakAreaData: { x: [], y: [] },
          curvePointData: { x: [], y: [] },
          guPointData: { x: [], y: [] },
          isFetched: false,
          status: 'error',
          statusMessage: parseResponse['errorMessage'],
        }
      } else {
        const data = JSON.parse(parseResponse.body);
        const curvePointData = {
          x: data.curve_data.xArray,
          y: data.curve_data.yArray,
          mode: 'lines',
          name: 'approximate curve',
          marker: {
            color: 'blue'
          }
        }
        const guPointData = {
          x: Object.keys(data.curve_data.gu_dict).map(x => parseFloat(x)),
          y: Object.values(data.curve_data.gu_dict),
          mode: 'markers',
          name: 'glucose Unit',
          marker: {
            color: 'red'
          }
        };
        returnProps = {
          peakPointData: peakPointData,
          peakAreaData: data.peakAreas,
          colors: [...colors],
          curvePointData: curvePointData,
          guPointData: guPointData,
          convertFormula: data.curve_data.coeff,
          xInversePeak: data.xInversePeak,
          status: 'success',
          statusMessage: 'success',
        }
      }
    })
    .catch(error => {
      returnProps = {
        peakPointData: { x: [], y: [], marker: { color: [] } },
        peakAreaData: { x: [], y: [] },
        colors: [],
        curvePointData: { x: [], y: [] },
        guPointData: { x: [], y: [] },
        convertFormula: [],
        xInversePeak: [],
        status: 'error',
        statusMessage: 'Unexpected Error',
      }
      console.log('error', error)
    });
  return returnProps;
}

export const fetchMSAction = async props => {
  let returnProps;
  const raw = JSON.stringify({
    'data': props.reader.result,
    'minusCharge': props.minusCharge,
    'threshold_range': props.isotopePeakDiff,
    'MSDiff': props.MSDiff,
    'GUDiff': props.GUDiff,
    'gu': String(props.gu),
    'maxComponentValues': props.maxComponentValues,
    'OnlyDBFetch': false,
    'ionType': props.ionType,
  });

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch(`https://${API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/${STAGE}/ms`, requestOptions)
    .then(response => response.text())
    .then(result => {
      const parseResponse = JSON.parse(result);
      console.log(parseResponse);
      if ('errorMessage' in parseResponse) {
        returnProps = {
          allPointData: { x: [], y: [], marker: { color: [] } },
          initialAllPointData: { x: [], y: [], marker: { color: [] } },
          maxPeakPoint: {x: -1, y: -1},
          isotopePeakPoint: {x: -1, y: -1},
          calculatedMass: [],
          ionValue: [],
          isFetched: true,
          composition: [],
          dbData: [],
          images: {},
          topIndex: '',
          isoIndex: '',
          status: 'error',
          statusMessage: parseResponse['errorMessage'],
        }
      } else {
        const data = JSON.parse(parseResponse.body);
        const allPointData = {
          x: data.all_data_dic.xArray,
          y: data.all_data_dic.yArray,
          type: 'bar',
          marker: {
            color: []
          }
        };
        const initialAllPointData = JSON.parse(JSON.stringify(allPointData));
        const peakPointData = {
          x: data.peak_data_dic.xPeaks,
          y: data.peak_data_dic.yPeaks,
        }
        const maxPeakPoint = {
          x: data.max_peak_point.ms_ratio,
          y: data.max_peak_point.max_peak,
        };
        const isotopePeakPoint = {
          x: data.isotope_peak_point.ms_ratio,
          y: data.isotope_peak_point.isotope_peak,
        };
  
        const color = [];
        const initialColor = [];
        let topIndex = null;
        let isoIndex = null;
        for (let i = 0; i < allPointData.x.length; i++) {
          if (allPointData.x[i] === maxPeakPoint.x) {
            color[i] = RED;
            initialColor[i] = SKY_BLUE;
            topIndex = i;
          } else if (allPointData.x[i] === isotopePeakPoint.x) {
            color[i] = ORANGE;
            initialColor[i] = SKY_BLUE;
            isoIndex = i
          } else if (peakPointData.x.indexOf(allPointData.x[i]) !== -1) {
            color[i] = SKY_BLUE;
            initialColor[i] = SKY_BLUE;
          } else {
            color[i] = GRAY;
            initialColor[i] = GRAY;
          }
        }
        allPointData.marker.color = color;
        initialAllPointData.marker.color = initialColor;
        
        let images = {};
        if (Array.isArray(data.images)) {
          for (let i = 0; i < data.images.length; i++) {
            for (let key in data.images[i]) {
              images[key] = data.images[i][key];
            }
          }
        } else {
          images = data.images;
        }

        returnProps = {
          allPointData,
          initialAllPointData,
          maxPeakPoint,
          isotopePeakPoint,
          calculatedMass: data.mass,
          ionValue: data.valence_list,
          isFetched: true,
          composition: data.component,
          dbData: data.db_data,
          images: images,
          topIndex: topIndex,
          isoIndex: isoIndex,
          ionTypeDetection: data.ionTypeDetection,
          status: 'success',
          statusMessage: 'success',
        };
      }
    })
    .catch(error => {
      returnProps = {
        allPointData: { x: [], y: [], marker: { color: [] } },
        initialAllPointData: { x: [], y: [], marker: { color: [] } },
        maxPeakPoint: {x: -1, y: -1},
        isotopePeakPoint: {x: -1, y: -1},
        calculatedMass: [],
        ionValue: [],
        isFetched: true,
        composition: [],
        dbData: [],
        images: {},
        topIndex: '',
        isoIndex: '',
        status: 'error',
        statusMessage: 'Unexpected Error',
      };
      console.log('error', error)
    });
  return returnProps;
}

export const fetchDBAction = async props => {
  let returnProps;
  const raw = JSON.stringify({
    'minusCharge': props.minusCharge,
    'mass': props.mass,
    'valenceList': props.valenceList,
    'MSDiff': props.MSDiff,
    'GUDiff': props.GUDiff,
    'gu': String(props.gu),
    'maxComponentValues': props.maxComponentValues,
    'OnlyDBFetch': true,
    'ionType': props.ionType,
  });

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch(`https://${API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/${STAGE}/ms`, requestOptions)
    .then(response => response.text())
    .then(result => {
      const parseResponse = JSON.parse(result);
      console.log(parseResponse);
      if ('errorMessage' in parseResponse) {
        returnProps = {
          allPointData: { x: [], y: [], marker: { color: [] }  },
          peakPointData: { x: [], y: [], marker: { color: [] } },
          peakAreaData: { x: [], y: [] },
          curvePointData: { x: [], y: [] },
          guPointData: { x: [], y: [] },
          isFetched: false,
          status: 'error',
          statusMessage: parseResponse['errorMessage'],
        }
      } else {
        const data = JSON.parse(parseResponse.body);
  
        returnProps = {
          isFetched: true,
          composition: data.component,
          dbData: data.db_data,
          images: data.images,
          status: 'success',
          statusMessage: 'success',
        };
      }
    })
    .catch(error => {
      returnProps = {
        composition: [],
        dbData: [],
        isFetched: true,
        status: 'error',
        statusMessage: 'Unexpected Error',
      };
      console.log('error', error)
    });
  return returnProps;
}

export const fetchContact = async props => {
  const {
    username,
    email,
    subject,
    message,
    setIsSuccessContactComplete,
    setIsFailContactComplete,
    setIsFetchContact,
  } = props;

  const url = `https://${API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/${STAGE}/contact`;
  const requestHeaders = new Headers();
  requestHeaders.append('Content-Type', 'application/json');

  const requestBody = JSON.stringify({
    username: username,
    email: email,
    subject: subject,
    message: message,
  });

  const requestOptions = {
    method: 'POST',
    body: requestBody,
    headers: requestHeaders,
  };

  await fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => {
      const res = JSON.parse(result);
      setIsFetchContact(false);

      if ('MessageId' in res) {
        setIsSuccessContactComplete(true);
      } else {
        setIsFailContactComplete(true);
        throw res;
      }
    })
    .catch(error => console.log(error));
}
