import { useNavigate, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import './Header.css'
import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

const ChangePageButton = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <button
        className={props.buttonClassName}
        onClick={() => navigate('/Analysis/Prepare')}
      >
        {props.button}
      </button>
    </>
  );
}

const BackToSampleHPLC = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <button
        className={props.buttonClassName}
        onClick={() => navigate('/Analysis/Sample')}
      >
        {props.button}
      </button>
    </>
  );
}

const theme = {
  name: 'button-theme',
  tokens: {
    colors: {
      border: {
        primary: { value: '{colors.teal.90}' },
      },
    },
    components: {
      button: {
        color: { value: '{colors.teal.90}' },
        primary: {
          backgroundColor: { value: '{colors.blue.60}' },

          _hover: {
            backgroundColor: { value: '{colors.blue.80}' },
          },
          _focus: {
            backgroundColor: { value: '{colors.blue.80}' },
          },
          _active: {
            backgroundColor: { value: '{colors.blue.90}' },
          },
        },
      },
    },
  },
};

const Header = props => {
  const { setManualBackButton } = props;

  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);

  const navigate = useNavigate();

  const logOut = () => {
    signOut();
    navigate('/');
  }

  return (
    <Row className="Analysis-title">
      <Col xs={8} className="HeaderTitleColumn">
        <h1 className="HeaderTitle">SugarScan</h1>
        <h4 className="HeaderSubTitle">{props.title}</h4>
      </Col>
      <Col xs={2} className='MoveButtonCol'>
        {
          props.backPrepare ?
            ChangePageButton(props) : props.analysis === 'quantification' ?
              BackToSampleHPLC(props) : null
        }
      </Col>
      <Col xs={1} className='LogoutButtonCol'>
        <button
          className="OpenManualButton CustomButton"
          onClick={() => {
            window.open('/Manual', "_blank", "noopener noreferrer");
          }}>
          Manual
        </button>
      </Col>
      <Col xs={1} className='LogoutButtonCol'>
        <button
          className="LogoutButton CustomButton"
          onClick={() => logOut()}
        >
          Logout
        </button>
      </Col>
    </Row>
  );
}

export default Header;
