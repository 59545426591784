import { Link } from 'react-router-dom';
import {Container, Row, Col, Button} from 'react-bootstrap'

import './Protocol.css'

export const Protocol = props => {
  const {
    enProtocolUrl,
    jaProtocolUrl,
  } = props;

  return (
    <div className="Protocol">
      <Row>
        <Col md={{ span: 1, offset: 0 }}>
          <Link to="/" className="back-link-top">
            back
          </Link>
        </Col>
      </Row>
      <div className="CustomHeader1">Protocol</div>
      <div className="ProtocolLinkParent">
        <a
          href={enProtocolUrl}
          target="_blank"
          rel="noopener noreferrer"
          fontWeight="bold"
          textDecoration="underline"
          className="ProtocolLink"
        >
          <span className="CustomHeader5">Protocol (English)</span>
        </a>
      </div>
      <div className="ProtocolLinkParent">
        <a
          href={jaProtocolUrl}
          target="_blank"
          rel="noopener noreferrer"
          fontWeight="bold"
          textDecoration="underline"
          className="ProtocolLink"
        >
          <span className="CustomHeader5">Protocol (日本語)</span>
        </a>
      </div>
    </div>
  );
}
