import React from 'react';
import { Loader } from './Loader';
import Header from './Header.js';
import { Container, Row, Col, Form } from 'react-bootstrap';
import SampleHPLC from './SampleHPLC.js';
import SettingThreshold from './SettingThreshold.js';
import PeakInformation from './PeakInformation.js';
import './SampleHPLC.css';
import {
  PADDING, SETTING_AREA, INPUT_AREA, MIN_WIDTH,
  HPLC_WIDTH_RATE, GU_WIDTH_RATE
} from './PrepareAnalysis';
import { Status } from './Status';
import { GREEN } from './PlotlyStyle.js';
import { CsvShortNotice } from './HomeNotices';

const DEFAULT_START = 20;
const DEFAULT_END = 80;

// 試料 HPLC では範囲指定があるのでその高さを SETTING_AREA に追加
const SAMPLE_SETTING_AREA = SETTING_AREA + 55;

class SampleAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // サンプル HPLC パラメータ
      allPointData: props.allPointData,
      peakPointData: props.peakPointData,
      selecedSamplePeakXY: {x: '', y: ''},
      samplePeakXYOfFetchedMS: {x: '', y: ''},
      reader: null,
      isFetched: false,
      isFetching: false,
      isDrawGraph: false,
      threshold: {
        slope: 2000,
        neighborhoodIntensity: 2000,
        peakArea: 1000000,
        neighborhoodCount: 150
      },
      height: window.innerHeight - INPUT_AREA,
      width: Math.max(window.innerWidth, MIN_WIDTH),
      startTime: DEFAULT_START,
      endTime: DEFAULT_END,
      status: null,
      statusMessage: null,
      isDragOver: false,
      MSReader: null,
      MSMSReader: null,
      MSfilename: null,
      MSMSfilename: null,
      // MSパラメータ
      allMSPointData: {
        x: [],
        y: [],
        mode: 'bar',
        marker: {
          color: []
        }
      },
      initialAllMSPointData: {
        x: [],
        y: [],
        mode: 'bar',
        marker: {
          color: []
        }
      },
      MSColor: [],
      topPeak: '',
      isotopePeak: '',
      ionValue: [],
      calculatedMass: [],
      massList: [],
      topIndex: null,
      isoIndex: null,
      minusCharge: '?',
      MSDiff: 1.0,
      GUDiff: 10,
      ionType: 'NH4+',
      ionTypeDetection: [],
      // DB パラメータ
      composition: [],
      dbData: [],
      datasource: [],
      selectionModel: [],
      allDBRows: [],
      // タブパラメータ
      tabValue: 0,
      // MSMS パラメータ
      selectedDBRows: [],
      unSpecifiedSelectionModel: [],
      selectPulldownDBRow: null,
      selectedComposition: null,
      isSpecified: false,
    };
  }

  addMatchedColumn(allDBRows) {
    this.setState({ allDBRows: allDBRows });
  }

  changeSelectionModel(selectionModel) {
    this.setState({ selectionModel: selectionModel });
  }

  changeUnSpecifiedSelectionModel(selectionModel) {
    this.setState({ unSpecifiedSelectionModel: selectionModel });
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        height: window.innerHeight - INPUT_AREA,
        width: Math.max(window.innerWidth, MIN_WIDTH),
      });
    });
  }

  dropHandler(event) {
    event.preventDefault();
    const reader = new FileReader();
    reader.readAsText(event.dataTransfer.files[0]);
    const filename = event.dataTransfer.files[0].name;
    if (this.props.isSelected && this.state.MSfilename === null) {
      this.setState({ isDragOver: false });
      reader.onload = () => {
        this.setState({
          MSReader: reader,
          MSfilename: filename
        });
      }
    } else if (this.state.MSfilename !== null) {
      this.setState({ isDragOver: false });
      reader.onload = () => {
        this.setState({
          MSMSReader: reader,
          MSMSfilename: filename
        });
      }
    } else {
      this.setState({
        reader: reader,
        isDragOver: false
      });
      this.props.changeFilename(filename);
      reader.onload = () => {
        this.runFetch(reader);
      }
    }
  }

  dragOverHandler(event) {
    event.preventDefault();
    this.setState({ isDragOver: true });
  }

  dragLeaveHandler(event) {
    event.preventDefault();
    this.setState({ isDragOver: false })
  }

  uploadFile(event) {
    if (event.target.files === null) {
      return
    }
    this.props.changeFilename(event.target.files[0].name);
    const file = event.target.files;
    const reader = new FileReader();
    reader.readAsText(file[0]);
    this.setState({ reader: reader });
    reader.onload = () => {
      this.runFetch(reader);
    }
  }

  async runFetch(reader) {
    const raw = {
      data: reader.result,
      'slopeOrder': this.state.threshold.slope,
      'areaOrder': this.state.threshold.peakArea,
      'averageOrder': this.state.threshold.neighborhoodIntensity,
      'sampleNum': this.state.threshold.neighborhoodCount,
      isCalcCurve: true,
    }

    this.changeFetching(true);

    const returnProps = await this.props.fetchHPLC({ raw: raw });
    this.props.changePointData(
      returnProps.allPointData,
      returnProps.peakPointData,
    );
    this.setState({
      allPointData: returnProps.allPointData,
      peakPointData: returnProps.peakPointData,
      xInversePeak: returnProps.xInversePeak,
      curvePointData: returnProps.curvePointData,
      guPointData: returnProps.guPointData,
      isFetched: returnProps.isFetched,
      isFetching: false,
      isDrawGraph: true,
      status: returnProps.status,
      statusMessage: returnProps.statusMessage,
    });
    this.props.changePeakAreaData(returnProps.peakAreaData);
    this.props.changeInversePeak(returnProps.xInversePeak);
  }

  changeGU(curvePointData, guPointData) {
    this.setState({
      curvePointData: curvePointData,
      guPointData: guPointData
    })
  }
  changeFetched(isFetched) {
    this.setState({
      isFetched: isFetched
    })
  }

  changeFetching(isFetched) {
    this.setState({
      isFetching: isFetched
    })
  }

  changeSelecedSamplePeakXY(selecedSamplePeakXY) {
    this.setState({
      selecedSamplePeakXY: selecedSamplePeakXY
    })
  }

  changeSamplePeakXYOfFetchedMS(samplePeakXYOfFetchedMS) {
    this.setState({
      samplePeakXYOfFetchedMS: samplePeakXYOfFetchedMS
    })
  }

  setThreshold(threshold) {
    this.setState({
      threshold: threshold
    });
  }

  changeHPLC(peakPointData) {
    this.setState({
      peakPointData: peakPointData,
    });
  }

  changeThreshold(threshold) {
    this.setState({
      threshold: threshold
    })
  }

  changeRange(startTime, endTime) {
    this.setState({
      startTime: startTime,
      endTime: endTime,
    })
  }


  changePeakLabel(label) {
    const selectedIndex = this.props.colors.findIndex(color => color === GREEN);
    if (selectedIndex !== -1) {
      const labels = this.props.labels
      labels[selectedIndex] = label
      this.props.changeLabels(labels)
    }
  }

  changeAllMSPointData(allMSPointData) {
    this.setState({
      allMSPointData: allMSPointData
    })
  }

  changeInitialAllMSPointData(initialAllMSPointData) {
    this.setState({
      initialAllMSPointData: initialAllMSPointData
    })
  }

  changeTopPeak(peak) {
    this.setState({ topPeak: peak });
  }

  changeIsoPeak(peak) {
    this.setState({ isotopePeak: peak });
  }

  changeIonValue(ionValue) {
    this.setState({ ionValue: ionValue })
  }

  changeCalculatedMass(calculatedMass) {
    this.setState({ calculatedMass: calculatedMass })
  }

  changeMassList(massList) {
    this.setState({ massList: massList })
  }

  changeTabValue(tabValue) {
    this.setState({ tabValue: tabValue });
  }

  changeComposition(composition) {
    this.setState({ composition: composition });
  }

  changeDBData(dbData) {
    this.setState({ dbData: dbData });
  }

  changeDatasource(datasource) {
    this.setState({ datasource: datasource });
  }

  changeTopIndex(topIndex) {
    this.setState({ topIndex: topIndex });
  }

  changeIsoIndex(isoIndex) {
    this.setState({ isoIndex: isoIndex });
  }

  changeMSColor(MSColor) {
    this.setState({ MSColor: MSColor });
  }

  changeIonValence(valenceList) {
    this.setState({ ionValue: valenceList });
  }

  changeSelectedDBRow(selectedDBRows) {
    this.setState({ selectedDBRows: selectedDBRows });
  }

  changeSelectPulldownDBRow(selectPulldownDBRow) {
    this.setState({ selectPulldownDBRow: selectPulldownDBRow });
  }

  changeIsSpecified(boolean) {
    this.setState({ isSpecified: boolean });
  }

  changeIonType(ionType) {
    this.setState({ ionType: ionType });
  }

  changeIonTypeDetection(ionTypeDetection) {
    this.setState({ ionTypeDetection: ionTypeDetection });
  }

  changeMSfilename(filename) {
    this.setState({MSfilename: filename});
  }

  changeMSMSfilename(filename) {
    this.setState({MSMSfilename: filename});
  }

  changeMSReader(reader) {
    this.setState({MSReader: reader});
  }

  changeMSMSReader(reader) {
    this.setState({MSMSReader: reader});
  }

  setStateBind(keyName, value) {
    this.setState({ [keyName]: value });
  }

  showFileType() {
    if (this.props.isSelected && this.state.MSfilename === null) {
      return <p style={{ width: '50%', margin: 'auto' }}>~ MS Data ~</p>
    } else if (this.state.MSfilename !== null) {
      return <p style={{ width: '50%', margin: 'auto' }}>~ MSMS Data ~</p>
    } else {
      return <p style={{ width: '50%', margin: 'auto' }}>~ Sample Data ~</p>
    }
  }

  render() {
    let loader = null;
    if (this.state.isFetching === true) {
      loader = <Loader />;
    }
    return (
      <Container className="Analysis"
        onDragOver={(event) => this.dragOverHandler(event)}
        onDragLeave={(event) => this.dragLeaveHandler(event)}
        onDrop={(event) => this.dropHandler(event)}
      >
        {
          this.state.isDragOver ?
            <div className='dragOverDisplay'>
              <p style={{ width: '50%', margin: 'auto', fontSize: '70px' }}>
                Drag and Drop
              </p>
              {this.showFileType()}
            </div> :
            <React.Fragment>
              <Header
                title="Analysis ~Analysis for Sample HPLC~"
                button="Back to Convert Formura"
                buttonClassName="backFomulaButton CustomButton"
                backPrepare={this.props.backPrepare}
                setManualBackButton={this.props.setManualBackButton}
              />
              <Form.Group>
                <Row className="inputForm">
                  <Col xs={12}>
                    <Form.Label className="inputComment">
                      <span className="CustomHeader5">Input Sample Data</span>
                    </Form.Label>
                    <label className="fileLabel">
                      <input type="file" className="file" onChange={(event) => this.uploadFile(event)}></input>
                    </label>
                    {loader}
                    <CsvShortNotice />
                  </Col>
                </Row>
              </Form.Group>
              <Row className="mainRow">
                <Col xs={9} className="HPLCGraphCol">
                  {this.state.status !== null ?
                    <Status
                      status={this.state.status}
                      statusMessage={this.state.statusMessage} /> : null}
                  <SettingThreshold
                    allPointData={this.state.allPointData}
                    threshold={this.state.threshold}
                    setthreshold={this.setThreshold.bind(this)}
                    reader={this.state.reader}
                    isDrawGraph={(this.state.isDrawGraph)}
                    isFetching={this.state.isFetching}
                    changeGU={this.changeGU.bind(this)}
                    changeHPLC={this.changeHPLC.bind(this)}
                    changeFetching={this.changeFetching.bind(this)}
                    changeThreshold={this.changeThreshold.bind(this)}
                    fetchHPLC={this.props.fetchHPLC}
                    startTime={this.state.startTime}
                    endTime={this.state.endTime}
                    changeRange={this.changeRange.bind(this)}
                    changePointData={this.props.changePointData}
                    isSample={this.props.isSample}
                  />
                  <Row>
                    <Col xs={12}>
                      <SampleHPLC
                        peakPointData={this.state.peakPointData}
                        allPointData={this.state.allPointData}
                        peakAreaData={this.props.peakAreaData}
                        changeColors={this.props.changeColors}
                        changeLabels={this.props.changeLabels}
                        colors={this.props.colors}
                        labels={this.props.labels}
                        xInversePeak={this.props.xInversePeak}
                        isFetched={this.state.isFetched}
                        changePeakAreaData={this.props.changePeakAreaData}
                        changeInversePeak={this.props.changeInversePeak}
                        changeFetching={this.changeFetching.bind(this)}
                        changeSelectedPeak={this.props.changeIsSelected}
                        changeSelecedSamplePeakXY={this.changeSelecedSamplePeakXY.bind(this)}
                        changeFetched={this.changeFetched.bind(this)}
                        changePointData={this.props.changePointData}
                        isDrawGraph={this.state.isDrawGraph}
                        clickHPLC={this.props.clickHPLC}
                        width={this.state.width * HPLC_WIDTH_RATE - PADDING}
                        height={this.state.height - SAMPLE_SETTING_AREA}
                        changePeakInformation={this.props.changePeakInformation}
                        convertFormula={this.props.convertFormula}
                        startTime={this.state.startTime}
                        endTime={this.state.endTime}
                        changeHoverColor={this.props.changeHoverColor}
                        filename={this.props.filename}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={3}>
                  <PeakInformation
                    fetchMS={this.props.fetchMS}
                    fetchDB={this.props.fetchDB}
                    DescribeExecution={this.props.DescribeExecution}
                    StartExecution={this.props.StartExecution}
                    getDBData={this.props.getDBData}
                    allPointData={this.state.allMSPointData}
                    initialAllPointData={this.state.initialAllMSPointData}
                    setStateBind={this.setStateBind.bind(this)}
                    changeAllMSPointData={this.changeAllMSPointData.bind(this)}
                    changeInitialAllMSPointData={this.changeInitialAllMSPointData.bind(this)}
                    calcValence={this.props.calcValence}
                    width={this.state.width * GU_WIDTH_RATE - PADDING}
                    height={this.state.height}
                    sampleRetentionTime={this.props.sampleRetentionTime}
                    samplePeakArea={this.props.samplePeakArea}
                    basePeakArea={this.props.basePeakArea}
                    changeUnit={this.props.changeUnit}
                    unit={this.props.unit}
                    gu={this.props.gu}
                    isSelected={this.props.isSelected}
                    selecedSamplePeakXY={this.state.selecedSamplePeakXY}
                    samplePeakXYOfFetchedMS={this.state.samplePeakXYOfFetchedMS}
                    changeSamplePeakXYOfFetchedMS={this.changeSamplePeakXYOfFetchedMS.bind(this)}
                    plotWidth={this.state.width * HPLC_WIDTH_RATE - PADDING}
                    plotHeight={this.state.height - SETTING_AREA}
                    isHover={this.props.isHover}
                    changeHover={this.props.changeHover}
                    imageXaxis={this.props.imageXaxis}
                    imageYaxis={this.props.imageYaxis}
                    clientWidhth={this.props.clientWidhth}
                    structureTarget={this.props.structureTarget}
                    changeImageAxis={this.props.changeImageAxis}
                    changeStructureTarget={this.props.changeStructureTarget}
                    MSReader={this.state.MSReader}
                    MSMSReader={this.state.MSMSReader}
                    changeMSReader={this.changeMSReader.bind(this)}
                    changeMSMSReader={this.changeMSMSReader.bind(this)}
                    isDragOver={this.state.isDragOver}
                    changePeakLabel={this.changePeakLabel.bind(this)}
                    MSfilename={this.state.MSfilename}
                    changeMSfilename={this.changeMSfilename.bind(this)}
                    MSMSfilename={this.state.MSMSfilename}
                    changeMSMSfilename={this.changeMSMSfilename.bind(this)}
                    topPeak={this.state.topPeak}
                    isotopePeak={this.state.isotopePeak}
                    changeTopPeak={this.changeTopPeak.bind(this)}
                    changeIsoPeak={this.changeIsoPeak.bind(this)}
                    ionValue={this.state.ionValue}
                    calculatedMass={this.state.calculatedMass}
                    massList={this.state.massList}
                    changeIonValue={this.changeIonValue.bind(this)}
                    changeIonValence={this.changeIonValence.bind(this)}
                    changeCalculatedMass={this.changeCalculatedMass.bind(this)}
                    changeMassList={this.changeMassList.bind(this)}
                    changeTabValue={this.changeTabValue.bind(this)}
                    tabValue={this.state.tabValue}
                    composition={this.state.composition}
                    dbData={this.state.dbData}
                    datasource={this.state.datasource}
                    changeComposition={this.changeComposition.bind(this)}
                    changeDBData={this.changeDBData.bind(this)}
                    changeDatasource={this.changeDatasource.bind(this)}
                    topIndex={this.state.topIndex}
                    isoIndex={this.state.isoIndex}
                    changeTopIndex={this.changeTopIndex.bind(this)}
                    changeIsoIndex={this.changeIsoIndex.bind(this)}
                    MSColor={this.state.MSColor}
                    changeMSColor={this.changeMSColor.bind(this)}
                    selectedDBRows={this.state.selectedDBRows}
                    changeSelectedDBRow={this.changeSelectedDBRow.bind(this)}
                    selectPulldownDBRow={this.state.selectPulldownDBRow}
                    changeSelectPulldownDBRow={this.changeSelectPulldownDBRow.bind(this)}
                    isSpecified={this.state.isSpecified}
                    changeIsSpecified={this.changeIsSpecified.bind(this)}
                    selectionModel={this.state.selectionModel}
                    changeSelectionModel={this.changeSelectionModel.bind(this)}
                    unSpecifiedSelectionModel={this.state.unSpecifiedSelectionModel}
                    changeUnSpecifiedSelectionModel={this.changeUnSpecifiedSelectionModel.bind(this)}
                    allDBRows={this.state.allDBRows}
                    addMatchedColumn={this.addMatchedColumn.bind(this)}
                    MSDiff={this.state.MSDiff}
                    GUDiff={this.state.GUDiff}
                    minusCharge={this.state.minusCharge}
                    ionType={this.state.ionType}
                    changeIonType={this.changeIonType.bind(this)}
                    changeIonTypeDetection={this.changeIonTypeDetection.bind(this)}
                    ionTypeDetection={this.state.ionTypeDetection}
                  />
                </Col>
              </Row>
            </React.Fragment>}
      </Container>
    );
  }
}

export default SampleAnalysis;
