import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

import {
  REGION,
  IDENTITY_POOL_ID,
  BUCKET_NAME,
  ENGLISH_PROTOCOL,
  JAPANESE_PROTOCOL,
  TOS,
  LICENSE,
} from './AWSConfig';
import { HomeNotices } from './HomeNotices';
import './Home.css'

const _getSignedUrl = async props => {
  const {
    setEnProtocolUrl,
    setJaProtocolUrl,
    setTosUrl,
    setLicenseUrl,
  } = props;

  const client = new S3Client({
    region: REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: REGION },
      identityPoolId: IDENTITY_POOL_ID,
    })
  });

  const commandEnProtocol = new GetObjectCommand({
    "Bucket": BUCKET_NAME,
    "Key": ENGLISH_PROTOCOL,
  });
  const commandJaProtocol = new GetObjectCommand({
    "Bucket": BUCKET_NAME,
    "Key": JAPANESE_PROTOCOL,
  });
  const commandTos = new GetObjectCommand({
    "Bucket": BUCKET_NAME,
    "Key": TOS,
  });
  const commandLicense = new GetObjectCommand({
    "Bucket": BUCKET_NAME,
    "Key": LICENSE,
  });

  const enProtocolUrl = await getSignedUrl(client, commandEnProtocol, {
    expiresIn: 3600,
  });
  const jaProtocolUrl = await getSignedUrl(client, commandJaProtocol, {
    expiresIn: 3600,
  });
  const tosUrl = await getSignedUrl(client, commandTos, {
    expiresIn: 3600,
  });
  const licenseUrl = await getSignedUrl(client, commandLicense, {
    expiresIn: 3600,
  });

  setEnProtocolUrl(enProtocolUrl);
  setJaProtocolUrl(jaProtocolUrl);
  setTosUrl(tosUrl);
  setLicenseUrl(licenseUrl);
}

const Home = props => {
  const {
    setEnProtocolUrl,
    setJaProtocolUrl,
    setTosUrl,
    setLicenseUrl,
    setManualBackButton,
  } = props;

  const navigate = useNavigate();

  return (
    <div className="App">
      <header className="App-header">
        <p className="App-title">
          SugarScan
        </p>
        <button
          className="LoginCustomButton CustomButton"
          onClick={() => {
            navigate('/login');
            _getSignedUrl({
              setEnProtocolUrl: setEnProtocolUrl,
              setJaProtocolUrl: setJaProtocolUrl,
              setTosUrl: setTosUrl,
              setLicenseUrl: setLicenseUrl,
            });
          }}
        >
          Sign in / Create Account
        </button>
      </header>

      {/* 機能制限：構造図描画、使用DB一覧 */}
      <Container fluid className="ManualContainer">
        <Row>
          <Col xs={8}></Col>
          <Col xs={4}>
            {/* <Link to="/DrawGlycan" className="draw-glycan">
              Draw Glycan
            </Link> */}
          </Col>
        </Row>
        <Row>
          <Col xs={4}></Col>
          <Col xs={4}>
            <HomeNotices />
          </Col>
          <Col xs={4}><span className="ref CustomHeader4">Ref.</span>
            <div className="RefContents">
              {/* <Link to="/Version" className="DB-version">
              <li>using DB version</li>
            </Link> */}
              <Link to="/Manual" className="software-manual HomeLink"
                onClick={() => setManualBackButton(true)}
              >
                <div className="CustomHeader5">Software Manual</div>
              </Link>
              <Link to="/Protocol" className="protocol-link HomeLink"
                onClick={() => {
                  _getSignedUrl({
                    setEnProtocolUrl: setEnProtocolUrl,
                    setJaProtocolUrl: setJaProtocolUrl,
                    setTosUrl: setTosUrl,
                    setLicenseUrl: setLicenseUrl,
                  });
                }}>
                <div className="CustomHeader5">Protocol</div>
              </Link>
              <Link to="/License" className="license-link HomeLink"
                onClick={() => {
                  _getSignedUrl({
                    setEnProtocolUrl: setEnProtocolUrl,
                    setJaProtocolUrl: setJaProtocolUrl,
                    setTosUrl: setTosUrl,
                    setLicenseUrl: setLicenseUrl,
                  });
                }}>
                <div className="CustomHeader5">License</div>
              </Link>
              <Link to="/Contact" className="contact-link HomeLink">
                <div className="CustomHeader5">Contact us</div>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
