import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import React from 'react';

export const Status = props => {
  let message = '';
  switch (props.status) {
    case 'error':
      message = GetErrorMessage(props.statusMessage);
      break;
    case 'success':
      // do nothing
      break;
    default:
      message = props.statusMessage;
  }

  return (
    <React.Fragment>
      {message !== '' ?
        <Alert severity={props.status} style={{ fontSize: 20 }}>
          <AlertTitle>{props.status.toUpperCase()}</AlertTitle>
          {message}
        </Alert> : null}
    </React.Fragment>
  );
}

const GetErrorMessage = errorRawMessage => {
  switch (true) {
    case /invalid literal/.test(errorRawMessage):
      return 'file format error'
    default:
      return errorRawMessage
  }
};
