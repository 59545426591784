import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {
  Authenticator,
  useAuthenticator,
  View,
  Text,
  TextField,
  SelectField,
  CheckboxField,
  Alert,
  Button,
  Heading,
  useTheme,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate, useLocation } from 'react-router';
import './Login.css'

export function Login(props) {
  const { tosUrl } = props;
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';

  const signUpAttributes = [
    'email',
    'username',
    'password',
    'confirm_password',
    'custom:first_name',
    'custom:middle_name',
    'custom:family_name',
    'custom:job',
    'custom:purpose',
    'custom:organization',
    'custom:experience',
    'custom:trigger',
  ];

  const formFields = {
    signIn: {
      username: {
        label: '1. Username',
      },
      password: {
        label: '2. Password',
      },
    },
    signUp: {
      username: {
        label: '1. Username *',
        isRequired: true,
        order: 1,
      },
      email: {
        label: '2. Email *',
        isRequired: true,
        order: 2,
      },
      password: {
        label: '3. Password *',
        isRequired: true,
        order: 3,
      },
      confirm_password: {
        label: '4. Confirm Password *',
        isRequired: true,
        order: 4,
      },
    },
  };

  useEffect(() => {
    if (route === 'authenticated') {
      if (from === '/') {
        navigate('/Analysis/Prepare');
      } else {
        navigate(from, { replace: true });
      }
    }
  }, [route, navigate, from]);

  return (
    <div className="AuthClass">
      <View className="auth-wrapper">
        <Authenticator
          signUpAttributes={signUpAttributes}
          formFields={formFields}
          components={{
            SignIn: {
              Header() {
                const { tokens } = useTheme();

                return (
                  <>
                    <div className="SigninHeader CustomHeader3">
                      Sign in to your account
                    </div>
                    <div className="LogintoHomeLink">
                      <Link to="/">Back to Home</Link>
                    </div>
                  </>
                );
              },
              Footer() {
                const { toResetPassword } = useAuthenticator();
          
                return (
                  <div className="ResetPasswordLink">
                    <button
                      onClick={toResetPassword}
                      className="ResetPasswordButton"
                    >
                      Reset Password
                    </button>
                  </div>
                );
              },
            },
            SignUp: {
              Header() {
                const { tokens } = useTheme();

                return (
                  <>
                    <div className="SignupHeader CustomHeader3">
                      Create a new account
                    </div>
                    <View textAlign="center">
                      <div className="LogintoHomeLink">
                        <Link to="/">Back to Home</Link>
                      </div>
                      <Alert className="InputUserDataAlert">
                        <View textAlign="left">
                          <span className="CustomHeader5">
                            Please be careful when entering your information:
                          </span>
                        </View>
                        <View textAlign="left" className="InputUserDataAlertContents">
                          <Text>(1) Please fill item marked with * symbol.</Text>
                          <Text>(2) When entering your password, make sure to follow the policy:</Text>
                            <ul>
                              <li>8 characters or more</li>
                              <li>one uppercase letter at least</li>
                              <li>one lowercase letter at least</li>
                              <li>one symbol at least</li>
                              <li>one number at least</li>
                            </ul>
                        </View>
                      </Alert>
                    </View>
                  </>
                );
              },
              Footer() {
                const { toSignIn } = useAuthenticator();

                return (
                  <div className="LogintoHomeLink">
                    <Link to="/">Back to Home</Link>
                  </div>
                );
              },
              FormFields() {
                const { validationErrors } = useAuthenticator();
                const [job, setJob] = useState('');
                const [experience, setExperience] = useState('');
                const [trigger, setTrigger] = useState('');

                return (
                  <>
                    <Authenticator.SignUp.FormFields />
                    <TextField
                      name="custom:first_name"
                      label="5. First Name"
                      placeholder="Enter your first name"
                    />
                    <TextField
                      name="custom:middle_name"
                      label="6. Middle name"
                      placeholder="Enter your middle name"
                    />
                    <TextField
                      name="custom:family_name"
                      label="7. Family name"
                      placeholder="Enter your family name"
                    />
                    <SelectField
                      name="custom:job"
                      label="8. Job/Status *"
                      isRequired={true}
                      value={job}
                      onChange={(e) => setJob(e.target.value)}
                    >
                      <option value="" hidden>Select</option>
                      <option value="Researcher">Researcher</option>
                      <option value="Student">Student</option>
                      <option value="Software Engineer">Software Engineer</option>
                      <option value="Medical Device/Biomedical Engineer">Medical Device/Biomedical Engineer</option>
                      <option value="Physician/Nurse">Physician/Nurse</option>
                      <option value="Elementary/Middle/High School Teacher">Elementary/Middle/High School Teacher</option>
                      <option value="General Office Clerk/Administrator">General Office Clerk/Administrator</option>
                      <option value="Sales/Business Development">Sales/Business Development</option>
                      <option value="Welfare/Caregiving">Welfare/Caregiving</option>
                      <option value="Service Industry">Service Industry</option>
                      <option value="others">others</option>
                    </SelectField>
                    <TextField
                      name="custom:organization"
                      label="9. Organization"
                      placeholder="Enter your Organization"
                    />
                    <TextField
                      name="custom:purpose"
                      label="10. Purpose of using this software"
                      placeholder="Enter your Purpose of using this software"
                    />
                    <SelectField
                      name="custom:experience"
                      label="11. Research Experience"
                      isRequired={true}
                      value={experience}
                      onChange={(e) => setExperience(e.target.value)}
                    >
                      <option value="" hidden>Select</option>
                      <option value="Less than 1 year">Less than 1 year</option>
                      <option value="Less than 5 year">Less than 5 year</option>
                      <option value="Less than 10 year">Less than 10 year</option>
                      <option value="Less than 10 year">10 years or more</option>
                      <option value="others">others</option>
                    </SelectField>
                    <SelectField
                      name="custom:trigger"
                      label="12. How did you come to know about this application?"
                      isRequired={true}
                      value={trigger}
                      onChange={(e) => setTrigger(e.target.value)}
                    >
                      <option value="" hidden>Select</option>
                      <option value="papers">papers</option>
                      <option value="websites">websites</option>
                      <option value="word of mouth">word of mouth</option>
                      <option value="others">others</option>
                    </SelectField>
                    <Text>13. Handling of Personal Information *</Text>
                    <a
                      href="https://www.mob.co.jp/privacy-management/"
                      target="_blank"
                      rel="noopener"
                      className="PersonalInfoLink"
                    >
                      <span className="CustomHeader5">Privacy Policy</span>
                    </a>
                    <Alert className="PersonalInfoAlert">
                      The personal information you provide will be used to process your application and to contact you.
                      Please note that we may ask you to provide information about our services and to cooperate in surveys.
                    </Alert>
                    <CheckboxField
                      errorMessage={validationErrors.privacyPolicy}
                      hasError={!!validationErrors.privacyPolicy}
                      name="privacyPolicy"
                      value="yes"
                      label="I agree"
                      className="AgreementCheckBox"
                    />
                    <Text>14. Terms of Service *</Text>
                    <a
                      href={tosUrl}
                      target="_blank"
                      rel="noopener"
                      className="TosLink"
                    >
                      <span className="CustomHeader5">Terms of Service</span>
                    </a>
                    <CheckboxField
                      errorMessage={validationErrors.termsOfService}
                      hasError={!!validationErrors.termsOfService}
                      name="termsOfService"
                      value="yes"
                      label="I agree"
                      className="AgreementCheckBox"
                    />
                  </>
                );
              },
            },
            ConfirmSignUp: {
              Header() {
                const { tokens } = useTheme();
                return (
                  <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                  >
                    Enter Information:
                  </Heading>
                );
              },
              Footer() {
                return <Text>Footer Information</Text>;
              },
            },
          }}
          services={{
            async validateCustomSignUp(formData) {
              if (!formData.privacyPolicy && !formData.termsOfService) {
                return {
                  privacyPolicy: 'You must agree to the Privacy Policy.',
                  termsOfService: 'You must agree to the Terms of Service.',
                };
              }
              else if (!formData.privacyPolicy) {
                return {
                  privacyPolicy: 'You must agree to the Privacy Policy.',
                };
              }
              else if (!formData.termsOfService) {
                return {
                  termsOfService: 'You must agree to the Terms of Service.',
                };
              }
            },
          }}
        />
      </View>
    </div>
  );
}
