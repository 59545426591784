import { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import Plot from 'react-plotly.js';
import { MSMSdbColumns } from './DBTableConstant';
import { SelectDBData } from './MSMSSelect';
import { MouseOverPopover } from './PopOver';
import './MSMSGraph.css';

const MIN_WIDTH = 1000;
const MIN_HEIGHT = 450;
const HEADER_HEIGHT = 48;
const ROW_HEIGHT = 40;

const CloseModal = props => {
  return (
    <div
      className="closeModal"
      onClick={() => props.onHide()}
    ></div>
  );
}

export const MSMSSpecified = props => {
  const {
    allPointData, width, height, selectedDBRows,
    top10MZ, top10Composition, top10CompositionMass, top10Diff, valence,
    selectPulldownDBRow, changeSelectPulldownDBRow,
    changeIsSpecified,
    unSpecifiedSelectionModel,
  } = props;
  const [selectionModel, setSelectionModel] = useState(unSpecifiedSelectionModel);
  const [dbData, setDBData] = useState('');

  const [isChange, setIsChange] = useState(false);

  const diffColumn = (index, pulldownIndex) => {
    if (top10Diff[pulldownIndex][index] === '-') {
      return <Col xs={3}>-</Col>;
    } else if (top10Diff[pulldownIndex][index] > 1) {
      return <Col xs={3} style={{ color: 'red' }}>{top10Diff[pulldownIndex][index].toFixed(2)}</Col>;
    } else {
      return <Col xs={3}>{top10Diff[pulldownIndex][index].toFixed(2)}</Col>;
    }
  }

  const calcMassColumn = (index, pulldownIndex) => {
    if (top10CompositionMass[pulldownIndex][index] === '-') {
      return <Col xs={3}>-</Col>;
    } else if (valence[pulldownIndex][index] === 1) {
      return <Col xs={3}>{top10CompositionMass[pulldownIndex][index].toFixed(2)}</Col>;
    } else {
      return (
        <Col xs={3}>
          <span>{top10CompositionMass[pulldownIndex][index].toFixed(2)}</span>
          <span>({valence[pulldownIndex][index]}H+)</span>
        </Col>
      );
    }
  }

  const getTop10MZ = top10MZ => {
    const selectedPulldownIndex = dbData !== '' ? getPulldownIndex(dbData) : 0;
    return (
      <>
        {
          top10MZ.map((mz, index) => (
            <Row>
              <Col xs={3}>{mz.toFixed(2)}</Col>
              <Col xs={3}>{top10Composition[selectedPulldownIndex][index]}</Col>
              <Col xs={3}>{calcMassColumn(index, selectedPulldownIndex)}</Col>
              <Col xs={3}>{diffColumn(index, selectedPulldownIndex)}</Col>
            </Row>
          ))
        }
      </>
    );
  }

  const getPulldownIndex = dbData => {
    const [dbName, gu, composition, compositionMS] = dbData.split('/');
    for (let i = 0; i < selectedDBRows.length; i++) {
      const dbRow = selectedDBRows[i];
      const compositionRemovedColor = getCompositionRemovedColor(dbRow.composition);
      if (
        dbRow.dbName.props.dbName === dbName &&
        dbRow.dbGU === gu &&
        compositionRemovedColor === composition &&
        dbRow.compositionMS === compositionMS
      ) {
        return i;
      }
    }
    return 0;
  };

  const getCompositionRemovedColor = composition => {
    if (typeof composition === 'string') {
      return composition;
    } else {
      return composition.props.children.map(coloredElem =>
        coloredElem.props.children[0].props.children + coloredElem.props.children[1].props.children
      ).join('');
    }
  }

  return (
    <>
      <Modal
        {...props}
        centered
        dialogClassName="MSModal"
        style={{ overflow: 'auto' }}
      >
        <div className="modalBody">
          <div className="MSMSmodalTitleRow">
            <div className="MSMSmodalTitle">MSMS graph and Top 10 Intensity - Specified</div>
            <div className="MSMSmodalSelectArea">
              <SelectDBData
                selectedDBRows={selectedDBRows}
                changeSelectPulldownDBRow={changeSelectPulldownDBRow}
                setIsChange={setIsChange}
                dbData={
                  dbData === '' ?
                  [
                    selectPulldownDBRow.dbName.props.dbName,
                    selectPulldownDBRow.dbGU,
                    getCompositionRemovedColor(selectPulldownDBRow.composition),
                    selectPulldownDBRow.compositionMS
                  ].join('/') : dbData
                }
                setDBData={setDBData}
                getCompositionRemovedColor={getCompositionRemovedColor}
              />
              <MouseOverPopover name="MSMSPullDownList" />
            </div>
            <div className="closeModalCol">{CloseModal(props)}</div>
          </div>
          <div style={{
            minWidth: '1000px',
            minHeight: '69px',
            margin: '0px 10px 10px',
            display: 'flex',
          }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                className='DataGrid'
                rows={[selectedDBRows[dbData !== '' ? getPulldownIndex(dbData) : 0]]}
                columns={MSMSdbColumns}
                headerHeight={HEADER_HEIGHT}
                rowHeight={ROW_HEIGHT}
                autoHeight={true}
                checkboxSelection={true}
                disableColumnMenu={true}
                hideFooter={true}
                selectionModel={selectionModel}
                getCellClassName={(params) => {
                  if (params.field === 'dbName' && params.value.props.dbName !== 'Not match') {
                    return 'dbNameMatch';
                  }
                  return '';
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  if (newSelectionModel.length === 0) {
                    changeIsSpecified(false);
                  }
                }}
              />
            </div>
          </div>
          <div className="MSPlotlyDiv">
            <Plot
              data={[
                allPointData
              ]}
              layout={{
                title: props.filename === 'Drag and Drop' ? 'No Files Uploaded.' : props.filename,
                margin: {
                  t: 40,
                  b: 60
                },
                width: Math.max(width * 0.8, MIN_WIDTH),
                height: Math.max(height * 0.6, MIN_HEIGHT),
                xaxis: {
                  title: 'm/z',
                },
                yaxis: {
                  title: 'Intensity',
                },
              }}
            />
          </div>
          <div className='modalFooter'>
            <div className="footerBottomRow">
              <Row className="footerTopRow">
                <Col xs={3} className="MSMSKey">m/z</Col>
                <Col xs={3} className="MSMSKey">Composition</Col>
                <Col xl={3} className="MSMSKey">Composition Mass</Col>
                <Col xl={3} className="MSMSKey">Diff</Col>
              </Row>
              {getTop10MZ(top10MZ)}
            </div>
          </div>
        </div>
      </Modal>
    </>

  );
}
